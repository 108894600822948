import React, { FC, memo, useEffect } from 'react';
import { SuggestedQuality } from '@/components/platform/Learn/pages/CourseTopic/typedefs/SuggestedQuality';
import { YouTubePlayer } from '@/components/platform/Learn/pages/CourseTopic/typedefs/YouTubePlayer';

interface Props {
  onReady: (event: { target: YouTubePlayer }) => void;
  [key: string]: any;
}

const playerMock: YouTubePlayer = {
  /* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
  playVideo() { },
  pauseVideo() { },
  stopVideo() { },
  getCurrentTime() { },
  cueVideoById(
    id: string,
    startSeconds?: number,
    suggestedQuality?: SuggestedQuality,
  ) { },
  loadVideoById(id: string) { },
  player: null,
};

export const YouTubeMock: FC<Props> = memo((props) => {
  const {
    onReady,
  } = props;

  useEffect(() => {
    onReady({ target: playerMock });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-qa="YouTubeMock"
      style={{
        width: '640px',
        height: '480px',
        backgroundColor: 'green',
      }}
    />
  );
});
