import { isMobile, isTablet } from '@/lib/helpers/isMobile';

enum DeviceTypes {
  Mobile = 'MOBILE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP'
}

export const getDeviceType = (): DeviceTypes => {
  if (isMobile()) {
    return DeviceTypes.Mobile;
  }

  if (isTablet()) {
    return DeviceTypes.Tablet;
  }

  return DeviceTypes.Desktop;
};
