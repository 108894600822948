import getConfig from 'next/config';
import { isBrowser } from '@/lib/helpers/isBrowser';

export const getWebSocketLink = (path = '', host?: string) => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const {
    API_SSL, API_PORT, API_HOST, API_HOST_PUBLIC, API_HOST_PUBLIC_SUB,
  } = publicRuntimeConfig;

  const location = isBrowser
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    ? window?.location
    : undefined;

  const publicHost = (
    host === API_HOST_PUBLIC_SUB
    || location?.host?.includes(API_HOST_PUBLIC_SUB)
  )
    ? API_HOST_PUBLIC_SUB
    : API_HOST_PUBLIC;

  const config = {
    ssl: API_SSL === 'true',
    port: API_PORT,
    hostname: host || (
      isBrowser
        ? publicHost
        : API_HOST
    ),
  };
  const {
    ssl, hostname, port,
  } = config;
  const protocol = `ws${(ssl && isBrowser)
    ? 's'
    : ''}`;
  const apiPort = (ssl && isBrowser)
    ? 443
    : port;

  let link = `${protocol}://${hostname}:${apiPort}`;

  if (path) {
    link += `/${path}`;
  }

  return link;
};
