import { ReactNode } from 'react';

export enum TreeNodeTypes {
  Folder = 'folder',
  File = 'file',
  TreeItem = 'treeItem',
}

export type BaseTreeNode = {
  path: string;
  isOpen?: boolean;
  isNotSaved?: boolean;
  isReadonly?: boolean;
  isActive?: boolean;
  isEntry?: boolean;
  isLoading?: boolean;
  isRenaming?: boolean;
};

export interface BaseFileData extends BaseTreeNode {
  id: number;
}

export type PassedNodeType<T> = BaseFileData & {
  [P in keyof T]: T[P]
};

export interface FolderFileCommonFields {
  pathParts: string[];
  value: string;
  url: string;
  depthLevel: number;
}

export type TreeFileNode<T> = PassedNodeType<T> & FolderFileCommonFields & {
  type: TreeNodeTypes.File;
};

export type TreeFolderNode<T> = BaseTreeNode & FolderFileCommonFields & {
  type: TreeNodeTypes.Folder;
  children: (TreeItemNode<T>)[];
  isOpen: boolean;
  isActive: boolean;
};

export type TreeItemNode<T> = TreeFolderNode<T> | TreeFileNode<T>;

export enum FileTypes {
  JS = 'js',
  CSS = 'css',
  HTML = 'html',
  PYTHON = 'py',
  JAVA = 'java',
  TS = 'ts',
  MD = 'md',
  SQL = 'sql',
  JSON = 'json',
  SCSS = 'scss',
  SASS = 'sass',
}

type FilesCallbackData<T> = {
  node: TreeFileNode<T>;
  updateValues?: TreeItemNode<T>;
};

type AddCallbackData<T> = {
  node: TreeFolderNode<T>;
  updateValues?: TreeItemNode<T>;
};
export type TreeFilesCallback<T> = (data: FilesCallbackData<T>[]) => void;
export type TreeAddCallback<T> = (data: AddCallbackData<T>[]) => void;

export interface UseFilesTreeState {
  renameInputValue: string;
  addInputValue: string;
}

export interface UseFilesTreeHandlers<T extends BaseFileData> {
  onSetIsFolderOpened: (path: string, state: boolean) => void;
  onSetFileRenaming: (node: TreeItemNode<T>, state: boolean) => void;
  updateRenameInputValue: (value: string) => void;
  updateAddInputValue: (value: string) => void;
}

export const isFile = <P>(
  node: TreeItemNode<P>,
): node is TreeFileNode<P> => (
    node.type === TreeNodeTypes.File
  );

export const isFolder = <P>(
  node: TreeItemNode<P>,
): node is TreeFolderNode<P> => (
    node.type === TreeNodeTypes.Folder
  );

export interface CustomFileAction<T extends BaseFileData> {
  name: string;
  renderIcon?: () => ReactNode;
  tooltipText?: (node: T) => string;
  isDisabled?: (node: T) => boolean;
  onClick: (node: T) => void;
}

export type CustomFileActions<
  T extends BaseFileData
> = CustomFileAction<T>[];
