import gql from 'graphql-tag';

export const sidebarState = gql`
  query sidebarState {
    sidebarState @client {
      sidebarOpen
    }
  }
`;

export const setSidebarState = gql`
  mutation toggleSidebar($open: Boolean!) {
    toggleSidebar(open: $open) @client
  }
`;
