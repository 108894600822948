import React, {
  FC,
  createContext,
  useContext,
  useMemo,
  memo,
} from 'react';
import i18Next from '@/middleware/i18n';
import {
  createFeaturesHandler,
  mapFeatures,
} from '@/controllers/features/features.helpers';
import {
  Feature,
  FeaturesData,
  FeaturesHandler,
  FeaturesQuery,
} from '@/controllers/features/features.typedefs';
import { useGetFeaturesQuery } from '@/controllers/features/generated/getFeatures.query.generated';

interface FeaturesContextType {
  featuresHandler: FeaturesHandler;
}

type FeaturesContextHook = () => FeaturesContextType;

const FeaturesContext = createContext<FeaturesContextType>({
  featuresHandler: createFeaturesHandler({
    featuresData: [] as unknown as FeaturesData,
  }),
});

export const FeaturesContextProvider: FC = memo(({
  children,
}) => {
  const { query: queryString } = i18Next.useRouter();
  const { data } = useGetFeaturesQuery();

  const featuresData = useMemo(() => {
    const allFeatures = data?.allFeatures || [];

    return mapFeatures(
      allFeatures as Feature[],
      queryString as FeaturesQuery,
    );
  }, [data?.allFeatures, queryString]);

  const featuresHandler = useMemo(
    () => createFeaturesHandler({ featuresData }),
    [featuresData],
  );

  return (
    <FeaturesContext.Provider value={{ featuresHandler }}>
      {children}
    </FeaturesContext.Provider>
  );
});

export const useFeaturesContext: FeaturesContextHook = () => (
  useContext(FeaturesContext)
);
