import React, { FC, memo } from 'react';
import { LogoCircleMap } from '@/components/common/Logo/constants';
import { Products } from '@/constants/products';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

type Props = {
  className?: string;
};

export const LogoCircle: FC<Props> = memo(({
  className,
}) => {
  const { productName } = useSubDomainContext();

  const Logo = LogoCircleMap[productName ?? Products.Mate];

  return <Logo className={className} />;
});
