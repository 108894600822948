export enum Products {
  Mate = 'mate',
  Knowely = 'knowely',
}

export enum ProductsBrandNames {
  Mate = 'Mate academy',
  Knowely = 'Knowely',
}

export const BrandNamesMap = {
  [Products.Mate]: ProductsBrandNames.Mate,
  [Products.Knowely]: ProductsBrandNames.Knowely,
};

export const KNOWELY_LANDING_REDIRECT_PATHS = [
  '/',
  '/sign-in',
  '/sign-up',
];
