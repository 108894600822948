const removeLanguageSubpath = ({
  urlParts,
  isLanguageSubpath,
}) => {
  if (isLanguageSubpath) {
    urlParts.splice(1, 1);
  }

  return urlParts;
};

const replaceLanguageSubpath = ({
  urlParts,
  language,
  isLanguageSubpath,
}) => {
  if (isLanguageSubpath) {
    urlParts.splice(1, 1, language);
  } else {
    urlParts.splice(1, 0, language);
  }

  return urlParts;
};

const i18nReplaceLanguageSubpath = ({
  url,
  language,
  supportedLanguages,
}) => {
  const [urlWithoutAnchor, anchor] = url.split('#');
  const [urlWithoutQuery, query] = urlWithoutAnchor.split('?');

  const urlWithoutProtocol = urlWithoutQuery.replace(/https?:\/\//, '');

  const urlParts = urlWithoutProtocol.split('/');

  const languageSubpath = urlParts[1];

  const isLanguageSubpath = supportedLanguages.includes(languageSubpath);

  const modifiedUrlParts = language
    ? replaceLanguageSubpath({ urlParts, language, isLanguageSubpath })
    : removeLanguageSubpath({ urlParts, isLanguageSubpath });

  let newUrl = modifiedUrlParts.join('/');

  if (query) {
    newUrl += `?${query}`;
  }

  if (anchor) {
    newUrl += `#${anchor}`;
  }

  const protocol = url.match(/https?:\/\//);

  if (protocol) {
    return protocol[0] + newUrl;
  }

  return newUrl;
};

module.exports = {
  i18nReplaceLanguageSubpath,
};
