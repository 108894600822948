import gql from 'graphql-tag';

export const vacancyEditorState = gql`
  query vacancyEditor {
    vacancyEditor @client {
      open
      vacancyId
    }
  }
`;

export const setVacancyEditorState = gql`
  mutation toggleVacancyEditor($open: Boolean!, $vacancyId: Int!) {
    toggleVacancyEditor(open: $open, vacancyId: $vacancyId) @client
  }
`;
