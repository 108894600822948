const userDeviceInfo = () => {
  const result = {};

  try {
    result.environment = process.browser
      ? 'browser'
      : 'server';
  } catch (e) {
    // do nothing
  }

  try {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    result.userAgent = window.navigator.userAgent;
  } catch (e) {
    // do nothing
  }

  return result;
};

module.exports = {
  userDeviceInfo,
};
