import { ROUTES } from '@/controllers/router/router.contants';
import { i18nReplaceLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { Router } from '@/middleware/i18n';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';

const routesToIgnoreQueryParamsChange = [
  ROUTES.user.learn,
];

export const shouldIgnoreRouteChange = (newRoute: string): boolean => {
  const withoutLocale = i18nReplaceLanguageSubpath({
    url: newRoute,
    language: '',
    supportedLanguages: Router.locales ?? [DEFAULT_LOCALE],
  });

  const isRouteToIgnore = routesToIgnoreQueryParamsChange
    .some((route) => withoutLocale.startsWith(route));

  if (!isRouteToIgnore) {
    return false;
  }

  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  const currentPathName = window.location.pathname;
  const newPathName = newRoute.split('?')[0];

  return currentPathName === newPathName;
};
