import { InMemoryCache, Resolver } from '@apollo/client';
import { ToggleEventsSidebarStateMutationVariables } from '@/controllers/events/events.hooks/useEventsSidebarState';
import { eventsSidebarState } from '@/controllers/platform/eventsSidebar/eventsSidebar.queries';

export const EventsSidebarResolvers = {
  Mutation: {
    toggleEventsSidebar: async (
      parent: Resolver,
      { open }: ToggleEventsSidebarStateMutationVariables,
      { cache }: { cache: InMemoryCache },
    ) => {
      cache.writeQuery({
        query: eventsSidebarState,
        data: {
          eventsSidebarState: {
            open,
            __typename: 'EventsSidebar',
          },
        },
      });

      return open;
    },
  },
};
