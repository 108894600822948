export const getPathName = (path: string) => {
  if (!path) {
    return '';
  }

  if (path.startsWith('/')) {
    return path;
  }

  return `/${path}`;
};
