import gql from 'graphql-tag';

export const eventsSidebarState = gql`
  query eventsSidebarState {
    eventsSidebarState @client {
      open
    }
  }
`;

export const toggleEventsSidebarState = gql`
  mutation toggleEventsSidebar($open: Boolean!) {
    toggleEventsSidebar(open: $open) @client
  }
`;
