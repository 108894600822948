import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  useChatMentionCreatedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMentionCreatedWS.subscription.generated';
import { MentionCacheHelper } from '@/components/platform/Chat/cache.helpers/mention.helper';

interface Params {
  skip?: boolean;
}

export const useChatMentionCreatedWS = ({ skip }: Params = {}) => {
  useChatMentionCreatedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({ client, data: { data } }) => {
      if (!data) {
        return;
      }

      const { chatId } = data.chatMentionCreatedWS;

      const cacheHelper = new MentionCacheHelper(client.cache);

      cacheHelper.incrementGlobalChatMentionsCount();

      cacheHelper.incrementChatUnreadMentionsCount(chatId);

      cacheHelper.incrementChatMentionsCounter(chatId);
    },
  });
};
