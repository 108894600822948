import { LEADERBOARD_USERS_LIST_UPDATED_KEY } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.constants';
import { isBrowser } from '@/lib/helpers/isBrowser';
import { GetLeaderboardUsersWithPaginationDocument } from '@/components/platform/Learn/graphql/generated/getLeaderboardUsersWithPagination.query.generated';
import { GetLeaderboardDataByUserIdDocument } from '@/components/platform/Learn/graphql/generated/getLeaderboardDataByUserId.query.generated';
import { useLeaderboardUserLeaderboardUpdatesSubscription } from '@/components/platform/Learn/graphql/generated/leaderboardUserLeaderboardUpdates.generated';

interface Options {
  authUserId: number;
}

export const useLeaderboardUpdatesSubscription = ({
  authUserId,
}: Options) => {
  useLeaderboardUserLeaderboardUpdatesSubscription({
    skip: !isBrowser,
    variables: {
      userId: authUserId,
    },
    onData: ({ client, data }) => {
      if (!data.data?.leaderboardUserLeaderbordUpdates) {
        return;
      }

      const {
        divisionParticipants,
        ...leaderboardData
      } = data.data?.leaderboardUserLeaderbordUpdates;

      client.writeQuery({
        query: GetLeaderboardDataByUserIdDocument,
        variables: {
          userId: authUserId,
        },
        data: {
          leaderboardUser: {
            ...leaderboardData,
          },
        },
      });

      if (!divisionParticipants) {
        return;
      }

      const {
        topPerformers,
        participants,
      } = divisionParticipants;

      client.writeQuery({
        query: GetLeaderboardUsersWithPaginationDocument,
        variables: {
          divisionId: leaderboardData.divisionId,
          leaderboardUserId: leaderboardData.id,
        },
        data: {
          leaderboardUsersWithPagination: {
            topPerformers,
            participants,
          },
        },
      });

      localStorage.setItem(
        LEADERBOARD_USERS_LIST_UPDATED_KEY,
        JSON.stringify(true),
      );
    },
  });
};
