import { AmazonLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/AmazonLogo';
import { EpamLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/EpamLogo';
import { GoogleLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/GoogleLogo';
import { MicrosoftLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/MicrosoftLogo';
import { PreplyLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/PreplyLogo';
import { SoftServeLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/SoftServeLogo';
import { StarLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/Star';
import { WixLogo } from '@/components/platform/SubscriptionProduct/components/LogoItemsList/companiesLogos/WixLogo';
import { SubscriptionType } from '@/controllers/graphql/generated';

export const SUBSCRIPTION_PRODUCT_DOMAINS = ['us'];

export const SUBSCRIPTION_PRODUCT_DOMAINS_LIMITS = {
  maxFreeAutoTechChecksByWeek: 3,
  maxFreeDailyTasksToSolve: 5,
};

export const SUBSCRIPTION_PRODUCT_TOP_COPMANIES_LOGOS = [
  {
    name: 'Google',
    Logo: GoogleLogo,
  },
  {
    name: 'Amazon',
    Logo: AmazonLogo,
  },
  {
    name: 'Microsoft',
    Logo: MicrosoftLogo,
  },
  {
    name: 'Wix',
    Logo: WixLogo,
  },
  {
    name: 'Preply',
    Logo: PreplyLogo,
  },
  {
    name: 'Star',
    Logo: StarLogo,
  },
  {
    name: 'Epam',
    Logo: EpamLogo,
  },
  {
    name: 'Softserve',
    Logo: SoftServeLogo,
  },
];

export const DISCOUNT_INSTEAD_TRIAL_SUBSCRIPTIONS = [
  SubscriptionType.SubscriptionProductAnnualWithDiscount,
];
