import React from 'react';
import {
  ToolTip,
  ToolTipWidthMode,
  HorizontalPositionMode,
  VerticalPositionMode,
} from '@/components/ui/ToolTip';
import { IconQuestionLight } from '@/components/ui/icons/IconQuestionLight';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import styles from './LeaderboardFaqButton.module.scss';

export const LeaderboardFaqButton: React.FC = () => {
  const { t } = useTranslation([I18N_CODES.learn]);

  return (
    <ToolTip
      text={t(`${I18N_CODES.learn}:leaderboard.faq_tooltip_text`)}
      widthMode={ToolTipWidthMode.Max}
      horizontalPosition={HorizontalPositionMode.LeftSide}
      isManualVerticalPositioning
      verticalPosition={VerticalPositionMode.Bottom}
      className={styles.FAQTooltip}
    >
      <a
        href={t(`${I18N_CODES.learn}:leaderboard-faq`)}
        rel="nofollow noopener noreferrer"
        target="_blank"
        data-qa="faq-button"
        className={styles.tooltipIcon}
      >
        <IconQuestionLight />
      </a>
    </ToolTip>
  );
};
