import { AnalyticsClient, AnalyticsEventNames, AnalyticsEvents } from '@/controllers/analytics/generated';
import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import analytics from '@/controllers/analytics/analytics.client/GoogleAnalytics';

export class Analytics implements AnalyticsClient {
  async sendEvent(
    eventName: AnalyticsEventNames,
    eventData: AnalyticsEvents,
  ): Promise<void> {
    sendAmplitudeData(eventName, eventData);

    analytics.sendEvent(eventName, eventData);
  }
}
