import React, { FC, memo } from 'react';
import { IconKnowelyLogoFull } from '@/components/ui/icons/IconKnowelyLogoFull';

type Props = {
  className?: string;
};

export const KnowelyLogoFull: FC<Props> = memo(({
  className,
}) => <IconKnowelyLogoFull className={className} />);
