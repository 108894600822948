import React from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { IconCalendar } from '@/components/ui/icons/IconCalendar';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import { LeaderboardType } from '@/controllers/graphql/generated';
import styles from './PrimaryLeaderboardHeader.module.scss';
import { LeaderboardFaqButton } from '../LeaderboardFaqButton';

interface Props {
  leaderboardType: LeaderboardType;
  hoursLeft: number;
}

export const PrimaryLeaderboardHeader: React.FC<Props> = ({
  leaderboardType, hoursLeft,
}) => {
  const { t } = useTranslation([I18N_CODES.learn]);

  const isLead = leaderboardType === LeaderboardType.Lead;

  const titleCode = isLead
    ? 'leaderboard.lead_leaderboard_title'
    : 'leaderboard.newbie_title';

  const descriptionCode = isLead
    ? 'leaderboard.lead_board_description'
    : 'leaderboard.newbie_board_description';

  const daysLeft = Math.floor(hoursLeft / 24);

  const timeBeforeDistribution = daysLeft > 0
    ? t(`${I18N_CODES.learn}:leaderboard.primary_header_days_left_in_board`, {
      count: daysLeft,
    })
    : t(`${I18N_CODES.learn}:leaderboard.primary_header_hours_left_in_board`, {
      count: hoursLeft,
    });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.counter}>
          <IconCalendar
            size={IconSize.Small}
          />
          <h4 className={styles.counterMessage}>
            {timeBeforeDistribution}
          </h4>
        </div>
        {!isLead && (
          <LeaderboardFaqButton />
        )}
      </div>

      <h3 className={cn(typography.platformH1, styles.title)} data-qa="leaderboard-title">
        {t(`${I18N_CODES.learn}:${titleCode}`)}
      </h3>

      <p className={cn(typography.platformTextSmall, styles.description)}>
        {t(`${I18N_CODES.learn}:${descriptionCode}`)}
      </p>
    </div>
  );
};
