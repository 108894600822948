import {
  ChatAccessType, ChatKind, ParticipantRole,
} from '@/controllers/graphql/generated';
import {
  ChatGroups,
  ChatInfoTab,
  ChatsQueryVariables,
  MessageType,
  MinimalChatForGroupManipulation,
} from '@/components/platform/Chat/chat.typedefs';
import { ChatEvents } from '@/controllers/chat/chat.events';
import { MINUTE } from '@/constants';
import { ChatMessageUploadFile } from '@/components/ui/ChatInput/chatInput.typedefs';
import { ROUTES } from '@/controllers/router/router.contants';
import { colorsDeprecated } from '@/components/ui/colors';
import { InputRadioMode } from '@/components/ui/FormElements/FormInputs/InputRadio';
import { CHAT_DATA_ONBOARDING_ATTRIBUTES } from '@/components/platform/Onboarding/onboarding.constants';
import { ExtractedMention } from '@/components/ui/ChatInput/InputField/InputField.utils/extractMentions';
import { exists } from '@/lib/helpers/functional';
import { AbstractMessageFragment } from '@/components/platform/Chat/graphql/generated/abstractMessage.fragment.generated';
import { FileBaseFragment } from '@/controllers/files/File/generated/FileBase.fragment.generated';
import { ChatMinimalFragment } from './graphql/generated/chatMinimal.fragment.generated';
import { ParticipantBaseFragment } from './graphql/generated/participantBase.fragment.generated';
import { ChatWithAuthParticipantMinimalFragment } from './graphql/generated/chatWithAuthParticipantMinimal.generated';
import { ThreadFragment } from './graphql/generated/thread.fragment.generated';

type ChatChecker = {
  (chat: Partial<ChatMinimalFragment>): boolean;
};

type ChatUnreadChecker = (chat: ChatUnreadPayload) => boolean;

interface ChatUnreadPayload {
  myParticipant?: {
    lastActionTime?: number | null;
  } | null;
  lastActionTime?: number | null;
}

export const isChatAdmin = (participant?: { role: ParticipantRole } | null) => (
  participant?.role === ParticipantRole.Admin
);

export const getChatUniqueKey = (chatId: number) => (
  `Chat::${chatId}`
);

export const getThreadUniqueKey = (threadId: number) => (
  `Thread::${threadId}`
);

export const getReactionStackId = ({
  messageId,
  reactionShortcode,
}: { messageId: number; reactionShortcode: string }): string => (
  `${messageId}__${reactionShortcode}`
);

export const findFirstUnreadMessageId = (
  messages: Array<Pick<AbstractMessageFragment, 'id' | 'createdAt' | 'isOwn'>>,
  lastActionTime: number | null,
) => {
  if (!lastActionTime) {
    return messages[0]?.id || null;
  }

  const firstUnreadMessage = messages.find(
    (message) => message.createdAt > lastActionTime && !message.isOwn,
  );

  return firstUnreadMessage?.id || null;
};

export const isChatUnread: ChatUnreadChecker = ({
  lastActionTime,
  myParticipant,
}) => (
  (lastActionTime || 0) > (myParticipant?.lastActionTime || 0)
);

export const isChatArchived: ChatChecker = ({
  myParticipant,
}) => (
  myParticipant?.archivedAt
);

export const chatsComparator = (
  firstChat: ChatWithAuthParticipantMinimalFragment,
  secondChat: ChatWithAuthParticipantMinimalFragment,
) => {
  const firstChatLastActionTime = Number(firstChat.lastActionTime);
  const secondChatLastActionTime = Number(secondChat.lastActionTime);

  const isFirstChatUnread = (
    firstChatLastActionTime > Number(firstChat.myParticipant?.lastActionTime)
  );

  const isSecondChatUnread = (
    secondChatLastActionTime > Number(secondChat.myParticipant?.lastActionTime)
  );

  if (isFirstChatUnread && !isSecondChatUnread) {
    return -1;
  }

  if (!isFirstChatUnread && isSecondChatUnread) {
    return 1;
  }

  return secondChatLastActionTime - firstChatLastActionTime;
};

export const disableDefaultMarkdownListBehavior = (text: string) => {
  switch (text.trim()) {
    case '+':
      return `➕`;
    case '-':
      return `➖`;
    default:
      return text;
  }
};

interface SandboxQuestionAnsweredParams {
  thread: ThreadFragment;
  participant: ParticipantBaseFragment;
  chat: { id: number; name: string; accessType: ChatAccessType };
}

export const sendSandboxQuestionAnsweredAnalyticsEvent = ({
  thread,
  chat,
  participant,
}: SandboxQuestionAnsweredParams) => {
  const { id: chatId, name: chatName, accessType } = chat;
  const wasAlreadyAnswered = thread.lastSentMessageTime > thread.createdAt;

  const isSandboxChat = accessType === ChatAccessType.Public;
  const isThreadAuthor = participant.id === thread.senderId;

  if (!isSandboxChat || isThreadAuthor) {
    return;
  }

  if (wasAlreadyAnswered) {
    return;
  }

  const questionTimestamp = thread.createdAt;
  const answerTimestamp = Date.now();

  const responseTimeInMinutes = (
    Math.ceil((answerTimestamp - questionTimestamp) / MINUTE)
  );

  ChatEvents.sendEvent(
    ChatEvents.events.sandboxChatQuestionAnswered,
    {
      chatId,
      chatName,
      senderId: participant.id,
      senderRole: participant.role,
      threadId: thread.id,
      questionTimestamp: new Date(questionTimestamp),
      answerTimestamp: new Date(answerTimestamp),
      responseTimeInMinutes,
    },
  );
};

export const getParsedUploadMediaFiles = (
  files?: ChatMessageUploadFile[],
): FileBaseFragment[] => (
  (files ?? [])
    .map(({ uploadFile }) => (uploadFile))
    .filter((uploadFile): uploadFile is FileBaseFragment => (
      Boolean(uploadFile)
    ))
);

export const getUnreadMessagesCount = (
  messages: AbstractMessageFragment[],
  lastReadTime?: number | null,
) => {
  if (!lastReadTime) {
    return messages.length;
  }

  const lastReadTimeDate = new Date(lastReadTime);

  return messages.filter((message) => (
    new Date(message.createdAt) > lastReadTimeDate
  )).length;
};

export const joinParticipantNames = (
  names: string[],
  allParticipantsCount: number,
): string => {
  if (!names.length) {
    return String(allParticipantsCount);
  }

  const moreParticipantsCount = allParticipantsCount - names.length;

  if (moreParticipantsCount > 0) {
    return `${names.join(', ')} and ${moreParticipantsCount} more`;
  }

  if (names.length === 1 && exists(names[0])) {
    return names[0];
  }

  if (names.length === 2) {
    return `${names[0]} and ${names[1]}`;
  }

  const lastParticipantName = names[names.length - 1];

  return `${names.slice(0, -1).join(', ')} and ${lastParticipantName}`;
};

export const getChatInfoRouteByChatIdAndTab = (
  chatId: string,
  tab: ChatInfoTab | null,
) => {
  const baseRoute = `${ROUTES.chats.index}/${chatId}`;
  const baseRouteWithSettings = `${baseRoute}${ROUTES.chats.info.index}/${tab}`;

  return tab
    ? baseRouteWithSettings
    : baseRoute;
};

export const getMessageUrl = (message: {
  id: number;
  chatId: number;
  threadId?: number | null;
}) => {
  const { chatId } = message;

  return ROUTES.chat(chatId).withParams({
    selectedMessageId: message.id,
    ...(!!message?.threadId && {
      threadId: message.threadId,
    }),
  });
};

export const isMessageSender = (
  message: AbstractMessageFragment,
  participant: ParticipantBaseFragment | null,
) => participant?.id === message.senderId;

export const canDeleteMessage = (
  message: AbstractMessageFragment,
  participant: ParticipantBaseFragment | null,
) => {
  if (isChatAdmin(participant)) {
    return true;
  }

  switch (message.__typename) {
    case MessageType.OpenQuestionAnswer:
      return false;
    default:
      return isMessageSender(message, participant);
  }
};

export const isMessageThread = (
  messageTypename?: string,
) => (
  messageTypename === MessageType.Thread
  || messageTypename === MessageType.OpenQuestion
  || messageTypename === MessageType.Poll
);

export const getQuizColor = (
  isCorrect: boolean,
  hasCorrect: boolean,
): [string, InputRadioMode] => {
  if (!hasCorrect || !isCorrect) {
    return [colorsDeprecated.red, InputRadioMode.Red];
  }

  return [colorsDeprecated.green, InputRadioMode.Green];
};

export const resolveChatGroupFromChat = (
  chat: MinimalChatForGroupManipulation,
): ChatGroups => {
  if (chat.myParticipant?.isFavourite) {
    return ChatGroups.Favorite;
  }

  if (chat.kind === ChatKind.Direct) {
    return ChatGroups.Direct;
  }

  return ChatGroups.All;
};

export const resolveChatGroupFromQueryVariables = (
  variables: ChatsQueryVariables,
): ChatGroups => {
  const filters = variables.filters || {};

  if (
    filters.isFavourite
    && (
      !filters.kinds
      || filters.kinds.length === 0
    )
  ) {
    return ChatGroups.Favorite;
  }

  if (
    !filters.isFavourite
    && filters.kinds
    && filters.kinds.length === 1
    && filters.kinds[0] === ChatKind.Direct
  ) {
    return ChatGroups.Direct;
  }

  return ChatGroups.All;
};

export const toggleChatMessageHover = (eventType: string): void => {
  const [message] = document.querySelectorAll(
    `[data-onboarding="${CHAT_DATA_ONBOARDING_ATTRIBUTES.MESSAGE_WRAPPER}"]`,
  );

  message?.dispatchEvent(new Event(eventType));
};

interface SplitMentionsBySelectionParams {
  inputValue: string;
  mentions: ExtractedMention[];
  selectionStart: number;
  selectionEnd: number;
}

export const splitMentionsBySelection = ({
  inputValue,
  mentions,
  selectionStart,
  selectionEnd,
}: SplitMentionsBySelectionParams) => {
  let lastMentionEndIndex = 0;
  const prefixMentions: ExtractedMention[] = [];
  const suffixMentions: ExtractedMention[] = [];

  mentions.forEach((mention) => {
    const mentionStartIndex = inputValue.indexOf(
      mention.markup,
      lastMentionEndIndex,
    );

    if (mentionStartIndex === -1) {
      return;
    }

    lastMentionEndIndex = mentionStartIndex + mention.markup.length;

    if (lastMentionEndIndex < selectionStart) {
      prefixMentions.push(mention);
    }

    if (mentionStartIndex > selectionEnd) {
      suffixMentions.push(mention);
    }
  });

  return {
    prefixMentions,
    suffixMentions,
  };
};

export const getFileExtension = (fileMime?: string | null): string => {
  if (!fileMime) {
    return '';
  }

  return fileMime.split('/').pop()?.toUpperCase() || '';
};
