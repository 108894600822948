import gql from 'graphql-tag';

export const SidebarSchema = gql`
  type SidebarState {
    sidebarOpen: Boolean
  }

  extend type Query {
    sidebarState: SidebarState
  }

  extend type Mutation {
    toggleSidebar(value: Boolean!): [Boolean]
  }
`;
