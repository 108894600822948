import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconMedalGold: FCIcon = (props) => (
  <BaseIcon
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="clip0_53086_7000">
      <path d="M14.1665 6.22222L8.72206 0H0.166504L11.0554 13.2222L20.0162 9.52778L14.1665 6.22222Z" fill="#E25544" />
      <path d="M19.6109 0L14.1665 6.22222L18.3587 11.9093L19.3131 10.7512L28.1665 0H19.6109Z" fill="#E77062" />
      <path d="M18.2146 12.4647C18.2768 12.2959 18.3165 12.1162 18.3165 11.9257C18.3165 11.067 17.6196 10.3701 16.761 10.3701H11.5755C10.7161 10.3701 10.02 11.067 10.02 11.9257C10.02 12.1162 10.0588 12.2959 10.1218 12.4647C7.58785 13.8849 5.87207 16.5923 5.87207 19.7034C5.87129 24.2853 9.58596 28 14.1678 28C18.7497 28 22.4644 24.2853 22.4644 19.7034C22.4644 16.5923 20.7486 13.8849 18.2146 12.4647Z" fill="#FDC22E" />
      <path d="M15.2585 14.4666H13.9199L11.7966 16.1248C11.753 16.1683 11.7095 16.285 11.7095 16.3426V17.2588C11.7095 17.4034 11.8261 17.5341 11.9716 17.5341H13.3677V24.0783C13.3677 24.2246 13.4844 24.3544 13.6438 24.3544H15.2585C15.4031 24.3544 15.5198 24.2238 15.5198 24.0783V14.7419C15.5198 14.5972 15.4039 14.4666 15.2585 14.4666Z" fill="#9E5200" />
    </g>
    <defs>
      <clipPath id="clip0_53086_7000">
        <rect width="28" height="28" fill="white" transform="translate(0.166504)" />
      </clipPath>
    </defs>
  </BaseIcon>
);
