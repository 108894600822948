import gql from 'graphql-tag';

export const VacancyEditorSchema = gql`
  extend type Mutation {
    toggleVacancyEditor(open: Boolean!, vacancyId: Int!): [Boolean]
  }

  extend type Query {
    vacancyEditor: VacancyEditor!
  }

  type VacancyEditor {
    open: Boolean!
    vacancyId: Int!
  }
`;
