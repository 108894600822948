import gql from 'graphql-tag';

export const UserVacancySidebarSchema = gql`
  extend type Mutation {
    toggleUserVacancySidebar(open: Boolean!): [Boolean]
  }

  extend type Query {
    userVacancySidebarState: UserVacancySidebar!
  }

  type UserVacancySidebar {
    open: Boolean!
  }
`;
