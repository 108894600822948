import { AnyFunction } from '@/typedefs/common';

export function throttleAndDebounce(
  func: AnyFunction,
  delay: number,
): AnyFunction {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let lastInvocationTime = 0;
  let lastArgs: any[];
  let context: any;
  let pending = false;

  function invokeFunction() {
    lastInvocationTime = Date.now();
    func.apply(context, lastArgs);
    timeoutId = null;
    if (pending) {
      invoke();
      pending = false;
    }
  }

  function invoke() {
    const now = Date.now();
    const timeSinceLastInvocation = now - lastInvocationTime;

    if (timeSinceLastInvocation >= delay) {
      invokeFunction();
    } else if (!timeoutId) {
      timeoutId = setTimeout(invokeFunction, delay - timeSinceLastInvocation);
    }
  }

  return function throttledAndDebounced(
    this: any,
    ...args
  ) {
    lastArgs = args;
    context = this;

    if (!lastInvocationTime) {
      invokeFunction();
    } else {
      pending = true;
      invoke();
    }
  };
}
