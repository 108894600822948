import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import { productNameVar } from '@/controllers/subDomain/withSubDomainApp';

const analytics = {
  sendEvent(event: string, data: Record<string, any>) {
    const product = productNameVar();

    return GoogleAnalyticsClient.getInstance(product)
      .sendEvent(event, data);
  },
};

export default analytics;
