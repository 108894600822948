import { PaymentStatus } from '@/controllers/graphql/generated';

enum NonPaymentStatusBadgeType {
  Skipped = 'skipped',
  Refunded = 'refunded',
}

export enum BadgeType {
  New = PaymentStatus.New,
  Accepted = PaymentStatus.Accepted,
  OnReview = PaymentStatus.OnReview,
  Under_3dSecure = PaymentStatus.Under_3dSecure,
  Declined = PaymentStatus.Declined,
  Failed = PaymentStatus.Failed,
  Skipped = NonPaymentStatusBadgeType.Skipped,
  Refunded = NonPaymentStatusBadgeType.Refunded,
}

export const PAYMENT_STATUS_TO_BADGE_TYPE: Record<
  PaymentStatus, BadgeType
> = {
  [PaymentStatus.New]: BadgeType.New,
  [PaymentStatus.Accepted]: BadgeType.Accepted,
  [PaymentStatus.OnReview]: BadgeType.OnReview,
  [PaymentStatus.Under_3dSecure]: BadgeType.Under_3dSecure,
  [PaymentStatus.Declined]: BadgeType.Declined,
  [PaymentStatus.Failed]: BadgeType.Failed,
};
