import { useCallback, useEffect } from 'react';
import { ColorTheme } from '@/components/common/ThemeContext/typedefs';
import { MEDIA_PREFERS_DARK_SCHEME } from '@/components/common/ThemeContext/constants';
import { useListenMediaChange } from '@/hooks/useListenMediaChange';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getColorTheme } from './helpers';

type Options = {
  condition: boolean;
  setNewColorTheme: (theme: ColorTheme) => void;
};

export const useTrackSystemColorTheme = (
  { condition, setNewColorTheme }: Options,
) => {
  const { productName } = useSubDomainContext();

  const handleColorSchemeChange = useCallback((isDarkMode: boolean) => {
    if (isDarkMode) {
      const colorTheme = getColorTheme(productName, ColorTheme.Dark);

      setNewColorTheme(colorTheme);
    } else {
      const colorTheme = getColorTheme(productName, ColorTheme.Light);

      setNewColorTheme(colorTheme);
    }
  }, [setNewColorTheme, productName]);

  useEffect(() => {
    if (!condition) {
      return;
    }

    const prefersDarkModeMediaQuery = window.matchMedia(
      MEDIA_PREFERS_DARK_SCHEME,
    );

    if (prefersDarkModeMediaQuery.matches) {
      // set system theme initially
      const colorTheme = getColorTheme(productName, ColorTheme.Dark);

      setNewColorTheme(colorTheme);
    }
  }, [condition, setNewColorTheme, productName]);

  useListenMediaChange({
    media: MEDIA_PREFERS_DARK_SCHEME,
    condition,
    callback: handleColorSchemeChange,
  });
};
