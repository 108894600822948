import { FieldFunctionOptions } from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import {
  FieldPolicy,
  FieldReadFunction,
} from '@apollo/client/cache/inmemory/policies';
import {
  UserVacanciesQueryVariables,
} from '@/components/platform/Vacancies/graphql/generated/userVacancies.query.generated';

export const userVacanciesQueryTypePolicies: {
  [fieldName: string]: FieldPolicy<any> | FieldReadFunction<any>;
} = {
  userVacancies: {
    keyArgs: false,

    merge(
      existing: SafeReadonly<any> | undefined = [],
      incoming: SafeReadonly<any> | undefined = [],
      { args }: FieldFunctionOptions<UserVacanciesQueryVariables>,
    ) {
      if (!args?.pagination) {
        return incoming?.length
          ? incoming
          : existing;
      }

      return [...existing, ...incoming];
    },
  },
};
