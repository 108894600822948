import { DAY, HOUR } from '@/constants';

export const getMsBeetweenTwoDates = (
  startDate: Date,
  endDate: Date,
): number => (
  endDate.valueOf() - startDate.valueOf()
);

export const getDaysBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
): number => {
  const msBetween = getMsBeetweenTwoDates(startDate, endDate);

  return Math.floor(Math.abs(msBetween) / DAY);
};

export const getHoursBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
) => {
  const msBetween = getMsBeetweenTwoDates(startDate, endDate);

  return Math.floor(Math.abs(msBetween) / HOUR);
};
