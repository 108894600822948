import React, { FC, memo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { LogoFullMap } from '@/components/common/Logo/constants';
import { Products } from '@/constants/products';

type Props = {
  className?: string;
};

export const LogoFull: FC<Props> = memo(({
  className,
}) => {
  const { productName } = useSubDomainContext();

  const Logo = LogoFullMap[productName ?? Products.Mate];

  return <Logo className={className} />;
});
