import { CourseUserStatus } from '@/controllers/graphql/generated';
import { newApplication, singleApplication } from '@/controllers/router/router.utils/applications';
import { ROUTES } from '@/controllers/router/router.contants';
import { FeatureWrapper } from '@/typedefs/common';
import { SELECTED_ALL_COURSES_LEARN_TAB } from '@/controllers/learn/learn.constants';
import {
  FtCourseWithMinimalCourseApplicationInfoFragment,
} from '@/components/platform/PlatformConsultation/graphql/generated/ftCourseWithMinimalCourseApplicationInfo.fragment.generated';
import { CoursesWithTestInfoFragment } from '@/components/platform/Learn/graphql/generated/coursesWithTestInfo.fragment.generated';

const courseUserSortedStatusList: CourseUserStatus[] = [
  CourseUserStatus.New,
  CourseUserStatus.NotInterested,
  CourseUserStatus.Rejected,
  CourseUserStatus.Introduction,
  CourseUserStatus.LearningResources,
  CourseUserStatus.DoingTestTask,
  CourseUserStatus.Interview,
  CourseUserStatus.SigningAgreement,
  CourseUserStatus.BecameStudent,
];

export const isValidSlug = (
  courses: CoursesWithTestInfoFragment[],
  slug?: string,
) => (
  slug === SELECTED_ALL_COURSES_LEARN_TAB
  || courses.some(
    (course) => course.slug === slug,
  )
);

export const getSortedCoursesByCourseUserStatus = (
  courses: CoursesWithTestInfoFragment[],
) => [...courses].sort((prevCourse, nextCourse) => {
  const prevIndicator = courseUserSortedStatusList.indexOf(
    prevCourse.courseUser?.status as CourseUserStatus,
  );
  const nextIndicator = courseUserSortedStatusList.indexOf(
    nextCourse.courseUser?.status as CourseUserStatus,
  );

  return nextIndicator - prevIndicator;
});

export const getApplicationLink = (
  options: {
    course?: FtCourseWithMinimalCourseApplicationInfoFragment;
    courseSlug?: string;
    source: string;
  },
) => {
  const {
    course,
    courseSlug,
    source,
  } = options;

  let applicationHref = newApplication.href;

  if (course
    && course.courseUser
    && course.courseUser?.status === CourseUserStatus.New) {
    applicationHref = singleApplication(
      course.courseUser.id,
    ).href;
  }

  const searchParams = new URLSearchParams();

  if (courseSlug && courseSlug !== SELECTED_ALL_COURSES_LEARN_TAB) {
    searchParams.append(
      'courseSlug',
      courseSlug,
    );
  }

  searchParams.append('source', source);

  return `${applicationHref}?${searchParams.toString()}`;
};

export const getApplicationRoute = () => (
  ROUTES.user.learn + ROUTES.learn.application
);

export const ComponentWrapper: FeatureWrapper = (
  {
    children,
    wrapper,
    shouldWrapChildren,
  },
) => (
  shouldWrapChildren
    ? wrapper(children)
    : children
);

export const getCourseSlugForRedirect = (
  courseSlugFromURL: string,
  curseSlugFromLS: string,
  courses: CoursesWithTestInfoFragment[],
): string | null => {
  const isValidCourseFromURL = isValidSlug(courses, courseSlugFromURL);

  if (isValidCourseFromURL) {
    return null;
  }

  const isValidCourseFromLS = isValidSlug(courses, curseSlugFromLS);

  if (isValidCourseFromLS) {
    return curseSlugFromLS;
  }

  return SELECTED_ALL_COURSES_LEARN_TAB;
};
