const { i18nReplaceLanguageSubpath } = require('./i18n.replaceLanguageSubpath');

const i18nResolveHref = (options) => {
  const { url, language, supportedLanguages } = options;

  if (typeof url === 'string') {
    return i18nReplaceLanguageSubpath({
      url: String(url),
      language,
      supportedLanguages,
    });
  }

  return {
    ...url,
    pathname: url.pathname
      ? i18nReplaceLanguageSubpath({
        url: url.pathname,
        language,
        supportedLanguages,
      })
      : undefined,
  };
};

module.exports = {
  i18nResolveHref,
};
