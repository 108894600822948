import React, { forwardRef } from 'react';
import { Link } from '@/middleware/i18n';
import { AnchorElementProps, ButtonORAnchorCommonAttributes } from '@/components/ui/Button/typedefs';

type Props = ButtonORAnchorCommonAttributes
  & AnchorElementProps
  & {
    isInternalLink?: boolean;
    dataQa?: string;
  };

type RefType = HTMLAnchorElement;

export const AnchorElement = forwardRef<RefType, Props>((props, ref) => {
  const {
    children,
    className,
    href,
    isInternalLink,
    dataQa,
    ...rest
  } = props;

  const shouldRenderInternalLink = (
    isInternalLink ?? href?.startsWith('/')
  );

  return shouldRenderInternalLink && href
    ? (
      <Link
        href={href}
        passHref
      >
        <a
          data-qa={dataQa}
          ref={ref}
          className={className}
          {...rest}
        >
          {children}
        </a>
      </Link>
    )
    : (
      <a
        data-qa={dataQa}
        ref={ref}
        href={href}
        className={className}
        {...rest}
      >
        {children}
      </a>
    );
});
