import { LeaderboardUserMetaFragment } from '@/components/platform/Learn/graphql/generated/LeaderboardMeta.fragment.generated';
import { FCIcon } from '@/components/ui/icons/typedefs';
import {
  LeaderboardLeagueType,
  LeaderboardType,
  LeagueTypesWithEntryThreshold,
  File,
  LeaderboardDivision,
} from '@/controllers/graphql/generated';
import { IntRange } from '@/lib/helpers/utility-types';

export enum PromotionStatus {
  Promotion = 'promotion',
  Demotion = 'demotion',
  Suspension = 'suspension',
}

export interface LeaderboardParticipantUser {
  id: number;
  name: string;
  avatar: File | null;
  streakDays: number;
}

export interface DivisionUser {
  id: number;
  xp: number;
  user: LeaderboardParticipantUser;
  promotionStatus: PromotionStatus | null;
  position: number;
}

export interface DivisionTopPerformer extends DivisionUser {
  Medal: FCIcon;
}

export type IconsIndexes = IntRange<0, 2>;

export type MedalIconsByIndex = Record<IconsIndexes, FCIcon>;

export interface LeaderboardUserData {
  leaderboardData: LeaderboardData | null;
  loading: boolean;
}

export interface LeaderboardData {
  leaderboardType: LeaderboardType;
  leaguesThresholds: LeagueTypesWithEntryThreshold[] | null;
  userLeagueType: LeaderboardLeagueType;
  division: Pick<LeaderboardDivision, 'id' | 'createdAt'>;
  monthOfStart: string;
  hoursLeftInBoard?: number;
  groupName: string | null;
  leaderboardUserId: number;
  meta: LeaderboardUserMetaFragment;
  todayXP?: number;
}
