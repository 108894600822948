const getConfig = require('next/config');
const { i18nExtractLanguageSubpath } = require('./i18n.extractLanguageSubpath');
const { DEFAULT_LOCALE } = require('../i18n.config');
const { i18nParseLanguageSubpath } = require('./i18n.parseLanguageSubpath');

const i18nExtractSubDomainFromPath = ({
  url,
  supportedLanguages = [],
}) => {
  const { publicRuntimeConfig } = getConfig() || {};

  const languages = (
    publicRuntimeConfig?.DYNAMIC_I18N_CONFIG?.languages
    ?? supportedLanguages
  );

  const locale = i18nExtractLanguageSubpath({
    url,
    supportedLanguages: languages,
  }) || DEFAULT_LOCALE;

  return i18nParseLanguageSubpath(locale).domain;
};

module.exports = {
  i18nExtractSubDomainFromPath,
};
