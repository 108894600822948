import { AnyObject } from '@/typedefs/common';

export const createQueryString = (params: AnyObject = {}) => {
  const queryParams = new URLSearchParams(params);

  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      queryParams.delete(key);
    }
  });

  if (queryParams.size > 0) {
    return `?${queryParams.toString()}`;
  }

  return '';
};
