import React, { FC, memo } from 'react';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { IconLogoFull } from '@/components/ui/icons/IconLogoFull';
import { IconLogoFullAnniversary } from '@/components/ui/icons/IconLogoFullAnniversary';

type Props = {
  className?: string;
};

export const MateLogoFull: FC<Props> = memo(({
  className,
}) => {
  const features = useFeatures();

  const isAnniversaryLogoEnabled = features.isEnabled(
    Features.MateLogoAnniversary,
  );

  return isAnniversaryLogoEnabled
    ? (
      <IconLogoFullAnniversary className={className} />
    )
    : (
      <IconLogoFull className={className} />
    );
});
