const { i18nCreateLanguageSubpath } = require('./i18n.createLanguageSubpath');
const { i18nParseLanguageSubpath } = require('./i18n.parseLanguageSubpath');
const { i18nReplaceLanguageSubpath } = require('./i18n.replaceLanguageSubpath');
const { i18nExtractLanguageSubpath } = require('./i18n.extractLanguageSubpath');
const { i18nResolveHref } = require('./i18n.resolveHref');
const { i18nExtractSubDomainFromPath } = require('./i18n.extractSubDomainFromPath');

module.exports = {
  i18nCreateLanguageSubpath,
  i18nParseLanguageSubpath,
  i18nReplaceLanguageSubpath,
  i18nExtractLanguageSubpath,
  i18nResolveHref,
  i18nExtractSubDomainFromPath,
};
