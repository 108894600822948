import { ApolloClient } from '@apollo/client';
import {
  LEADERBOARD_MEDAL_ICONS_BY_INDEX,
  NEWBIE_DISTRIBUTION_HOUR_UTC,
  NEWBIE_DISTRIBUTION_MINUTE_UTC,
  NEW_LEADERBOARD_RUN_DAY_UTC,
  NEW_LEADERBOARD_RUN_HOUR_UTC,
  NEW_LEADERBOARD_RUN_MINUTE_UTC,
  TOP_PERFORMERS_COUNT,
} from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.constants';
import {
  DivisionTopPerformer,
  DivisionUser,
  IconsIndexes,
  PromotionStatus,
} from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.typedefs';
import { DAY } from '@/constants';
import { getHoursBetweenTwoDates } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/helpers/getDaysBetweenTwoDates';
import { GetLeaderboardDataByUserIdDocument } from '@/components/platform/Learn/graphql/generated/getLeaderboardDataByUserId.query.generated';
import { GetLeaderboardUsersWithPaginationDocument } from '@/components/platform/Learn/graphql/generated/getLeaderboardUsersWithPagination.query.generated';
import { LeaderboardUserDataWithPaginatedParticipantsFragment } from '@/components/platform/Learn/graphql/generated/LeaderboardUserDataWithPaginatedParticipants.fragment.generated';
import { LeaderboardUserXpActivity } from '@/controllers/graphql/generated';
import { LeaderboardUserXpHistoryBaseFragment } from '@/components/platform/Learn/graphql/generated/leaderboardUserXpHistoryBase.fragment.generated';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';

export const getTopPerformers = (
  divisionUsers: DivisionUser[],
): DivisionTopPerformer[] => (
  divisionUsers.slice(0, TOP_PERFORMERS_COUNT)
    .map((user, i) => {
      const Medal = LEADERBOARD_MEDAL_ICONS_BY_INDEX[i as IconsIndexes];

      return {
        ...user,
        Medal,
      };
    })
);

export const getUserPositionByIndex = (
  index: number,
): number => (
  index + TOP_PERFORMERS_COUNT + 1
);

export const getPromotionStatus = (
  userXp: number,
  demotionThreshold: number,
  promotionThreshold: number | null,
): PromotionStatus => {
  if (userXp < demotionThreshold) {
    return PromotionStatus.Demotion;
  }

  if (promotionThreshold && userXp > promotionThreshold) {
    return PromotionStatus.Promotion;
  }

  return PromotionStatus.Suspension;
};

export const getLeaderboardDistributionTime = (
  userStartedAtTimeStamp: number,
  lifeTimeDays: number,
) => {
  const userEndDateTimeStamp = userStartedAtTimeStamp + (
    lifeTimeDays * DAY
  );

  const userEndDate = new Date(userEndDateTimeStamp);

  userEndDate.setUTCHours(
    NEWBIE_DISTRIBUTION_HOUR_UTC,
    NEWBIE_DISTRIBUTION_MINUTE_UTC,
  );

  return getHoursBetweenTwoDates(new Date(), userEndDate);
};

export const getNextRunDate = (): Date => {
  const currentDateTime = new Date();

  currentDateTime.setUTCDate(NEW_LEADERBOARD_RUN_DAY_UTC);
  currentDateTime.setUTCMonth(currentDateTime.getUTCMonth() + 1);

  currentDateTime.setUTCHours(
    NEW_LEADERBOARD_RUN_HOUR_UTC,
    NEW_LEADERBOARD_RUN_MINUTE_UTC,
    0,
    0,
  );

  return currentDateTime;
};

export const getCountdownText = (
  getTranslate: (countdownPart: string) => string,
  {
    days, hours, minutes, seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  },
) => {
  if (days > 0) {
    return `${days}${getTranslate('days')} ${hours}${getTranslate('hours')}`;
  }

  if (hours > 0) {
    return `${hours}${getTranslate('hours')} ${minutes}${getTranslate('minutes')}`;
  }

  if (minutes > 0) {
    return `${minutes}${getTranslate('minutes')} ${seconds}${getTranslate('seconds')}`;
  }

  if (seconds > 0) {
    return `${seconds}${getTranslate('seconds')}`;
  }

  return getTranslate('now');
};

const updateThreadCache = (
  client: ApolloClient<any>,
  xpHistoryData: LeaderboardUserXpHistoryBaseFragment,
) => {
  const cachedThreadId = client.cache.identify({
    __typename: MessageType.Thread,
    id: xpHistoryData.threadId,
  });

  if (!cachedThreadId) {
    return;
  }

  client.cache.modify({
    id: cachedThreadId,
    fields: {
      receivedXp: () => xpHistoryData.xp,
    },
  });
};

export const updateActivityEntityCache = (
  client: ApolloClient<any>,
  xpHistory: LeaderboardUserXpHistoryBaseFragment,
) => {
  const { activityType } = xpHistory;

  switch (activityType) {
    case LeaderboardUserXpActivity.ChatQuestionAnswered:
      updateThreadCache(
        client,
        xpHistory,
      );

      break;

    default: // TODO: use data from XP history to get task recieved XP https://app.clickup.com/t/24383048/RE-3357
      break;
  }
};

export const updateLeaderboardCache = (
  client: ApolloClient<any>,
  leaderboardUser: LeaderboardUserDataWithPaginatedParticipantsFragment,
  authUserId: number,
) => {
  const {
    divisionParticipants,
    ...leaderboardData
  } = leaderboardUser;

  client.writeQuery({
    query: GetLeaderboardDataByUserIdDocument,
    variables: {
      userId: authUserId,
    },
    data: {
      leaderboardUser: {
        ...leaderboardData,
      },
    },
  });

  if (!divisionParticipants) {
    return;
  }

  const {
    topPerformers,
    participants,
  } = divisionParticipants;

  client.writeQuery({
    query: GetLeaderboardUsersWithPaginationDocument,
    variables: {
      divisionId: leaderboardData.divisionId,
      leaderboardUserId: leaderboardData.id,
    },
    data: {
      leaderboardUsersWithPagination: {
        topPerformers,
        participants,
      },
    },
  });
};
