import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconWarningTriangle: FCIcon = (props) => (
  <BaseIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.53553 33.4991C2.14956 34.1658 2.63062 35.0002 3.40095 35.0002H36.5987C37.3691 35.0002 37.8501 34.1658 37.4641 33.4991L20.8653 4.82832C20.4801 4.16304 19.5196 4.16304 19.1344 4.82832L2.53553 33.4991ZM21.6665 30.0002H18.3332V26.6668H21.6665V30.0002ZM21.6665 23.3335H18.3332V16.6668H21.6665V23.3335Z"
      fill="currentColor"
    />
  </BaseIcon>
);
