import gql from 'graphql-tag';

export const userVacancySidebarState = gql`
  query userVacancySidebarState {
    userVacancySidebarState @client {
      open
    }
  }
`;

export const toggleUserVacancySidebarState = gql`
  mutation toggleUserVacancySidebar($open: Boolean!) {
    toggleUserVacancySidebar(open: $open) @client
  }
`;
