export enum ColorTheme {
  Light = 'light',
  Dark = 'dark',
  KnowelyLight = 'knowely-light',
  KnowelyDark = 'knowely-dark',
}

export enum ColorThemePreference {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}
