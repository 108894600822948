import { isBrowser } from '@/lib/helpers/isBrowser';
import { decrementSavedMessagesCount, removeFromSavedMessagesList } from '@/components/platform/Chat/store.helpers';
import {
  useChatMessageUnsavedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageUnsavedWS.subscription.generated';

interface Params {
  skip?: boolean;
}

export const useChatMessageUnsavedWS = ({ skip }: Params = {}) => {
  useChatMessageUnsavedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client,
      data: { data },
    }) => {
      const { cache } = client;

      const messageId = data?.chatMessageUnsavedWS.messageId;

      if (!messageId) {
        return;
      }

      removeFromSavedMessagesList(cache, { id: messageId });
      decrementSavedMessagesCount(cache);
    },
  });
};
