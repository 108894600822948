import { tabByURLMapper } from '@/components/platform/GroupDashboard/constants';
import { GroupDashboardTab } from '@/components/platform/GroupDashboard/typedefs';

export const getTabURL = (tab: GroupDashboardTab): string | null => {
  const foundEntry = Object.entries(tabByURLMapper).find(([, currentTab]) => (
    currentTab === tab
  ));

  return foundEntry
    ? foundEntry[0]
    : null;
};
