import React from 'react';
import { IconMedalBronze } from '@/components/ui/icons/IconMedalBronze';
import { IconMedalGold } from '@/components/ui/icons/IconMedalGold';
import { IconMedalSilver } from '@/components/ui/icons/IconMedalSilver';
import { MedalIconsByIndex } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.typedefs';
import { LeaderboardLeagueType, LeaderboardType, StudentStatus } from '@/controllers/graphql/generated';
import { CupMode, IconCup } from '@/components/ui/icons/IconCup';
import { IconDiamond } from '@/components/ui/icons/IconDiamond';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import { MainLeaderboardHeader } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/MainLeaderboardHeader';
import { ConstantArray } from '@/lib/helpers/utility-types';
import { PrimaryLeaderboardHeader } from './components/PrimaryLeaderboardHeader';
import { FullTimeLeaderboardHeader } from './components/FullTimeLeaderboardHeader';

export const LEADERBOARD_MEDAL_ICONS_BY_INDEX: MedalIconsByIndex = {
  2: IconMedalBronze,
  1: IconMedalSilver,
  0: IconMedalGold,
};

export const TOP_PERFORMERS_COUNT = 3;
export const UPPER_SIBLINGS_COUNT = 2;
export const LEAD_DIVISION_LIFE_TIME = 7;
export const NEWBIE_USER_LIFE_TIME = 13;

export const MONTHS_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;

export const LEAGUES_STEPS_CONTENT = {
  [LeaderboardLeagueType.Bronze]: {
    icon: <IconCup cupMode={CupMode.Bronze} size={IconSize.Small} />,
    titleCode: 'leaderboard.main_board_league_bronze',
  },
  [LeaderboardLeagueType.Silver]: {
    icon: <IconCup cupMode={CupMode.Silver} size={IconSize.Small} />,
    titleCode: 'leaderboard.main_board_league_silver',
  },
  [LeaderboardLeagueType.Gold]: {
    icon: <IconCup cupMode={CupMode.Gold} size={IconSize.Small} />,
    titleCode: 'leaderboard.main_board_league_gold',
  },
  [LeaderboardLeagueType.Platinum]: {
    titleCode: 'leaderboard.main_board_league_platinum',
    icon: <IconDiamond size={IconSize.Small} />,
  },
};

export const validStudentsStatusesForFullTimeLeaderboard = [
  StudentStatus.PreCourse,
  StudentStatus.Studying,
  StudentStatus.Employment,
];

export const LeaderboardHeadersMap = {
  [LeaderboardType.Lead]: PrimaryLeaderboardHeader,
  [LeaderboardType.Newbie]: PrimaryLeaderboardHeader,
  [LeaderboardType.Main]: MainLeaderboardHeader,
  [LeaderboardType.FullTime]: FullTimeLeaderboardHeader,
};

export const mainLeagueTypes: ConstantArray<4, LeaderboardLeagueType> = [
  LeaderboardLeagueType.Bronze,
  LeaderboardLeagueType.Silver,
  LeaderboardLeagueType.Gold,
  LeaderboardLeagueType.Platinum,
];

export const INFINITE_SCROLL_PAGE_LIMIT = 5;

export const LEADERBOARD_USERS_LIST_UPDATED_KEY = 'leaderboard_users_list_updated';

export const NEWBIE_DISTRIBUTION_HOUR_UTC = 21;
export const NEWBIE_DISTRIBUTION_MINUTE_UTC = 30;
export const NEW_LEADERBOARD_RUN_DAY_UTC = 1;
export const NEW_LEADERBOARD_RUN_HOUR_UTC = 0;
export const NEW_LEADERBOARD_RUN_MINUTE_UTC = 10;
