// TODO: delete this file
export const MESSAGE_TYPES = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  system: 'system',
  achievement: 'achievement',
  streak: 'streak',
} as const;

// example. now unused
export const MESSAGES = {
  general: {
    unexpectedError: {
      type: MESSAGE_TYPES.error,
      code: 'messages.general.unexpected_error',
    },
    networkError: {
      type: MESSAGE_TYPES.error,
      code: 'messages.general.network_error',
    },
  },
  profile: {
    avatarChanged: {
      type: MESSAGE_TYPES.success,
      code: 'messages.profile.success_avatar_changed',
    },
    profileChanged: {
      type: MESSAGE_TYPES.success,
      code: 'messages.profile.success_profile_changed',
    },
    passwordChanged: {
      type: MESSAGE_TYPES.success,
      code: 'messages.profile.success_password_changed',
    },
    tokenInvalid: {
      type: MESSAGE_TYPES.error,
      code: 'messages.profile.reset_password_token_invalid',
    },
    resetLinkInvalid: {
      type: MESSAGE_TYPES.error,
      code: 'messages.profile.reset_password_link_invalid',
    },
  },
  applications: {
    noAvailableCourses: {
      type: MESSAGE_TYPES.warning,
      code: 'messages.applications.no-available-courses',
    },
  },
  settings: {
    languageChanged: {
      type: MESSAGE_TYPES.success,
      code: 'messages.settings.language_changed',
    },
  },
  codeEditor: {
    checkTaskError: {
      type: MESSAGE_TYPES.error,
      code: 'messages.code_editor.error_during_check_test_task',
    },
    saveTaskSuccess: {
      type: MESSAGE_TYPES.success,
      code: 'messages.code_editor.test_task_saved_successfully',
    },
    noTimeLeftError: {
      type: MESSAGE_TYPES.error,
      code: 'messages.code_editor.no-time-left-error',
    },
  },
  vacancies: {
    addNewVacancieSuccess: {
      type: MESSAGE_TYPES.success,
      code: 'messages.new-vacancy.new_vacancy_added',
    },
    addNewVacancieError: {
      type: MESSAGE_TYPES.error,
      code: 'messages.new-vacancy.error_during_adding_new_vacancy',
    },
  },
  quizTask: {
    checkQuizError: {
      type: MESSAGE_TYPES.error,
      code: 'messages.quiz.error_during_check_quiz_task',
    },
  },
};

export const ALERTS = {
  skillSurvey: {
    englishLevel: {
      type: MESSAGE_TYPES.warning,
      code: 'alerts.skill_survey.english_level',
    },
    fullTimeStudy: {
      type: MESSAGE_TYPES.warning,
      code: 'alerts.skill_survey.full_time_study',
    },
    workCity: {
      type: MESSAGE_TYPES.warning,
      code: 'alerts.skill_survey.work_city',
    },
  },
  preparation: {
    preparationPeriod: {
      type: MESSAGE_TYPES.info,
      code: 'alerts.preparation.preparation_period',
    },
  },
};
