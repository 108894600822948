import {
  GroupDashboardTab,
  GroupDashboardTabUrl,
} from '@/components/platform/GroupDashboard/typedefs';
import { Features } from '@/controllers/features/features.constants';
import {
  TechCheckScoreType,
} from '@/components/platform/GroupDashboard/pages/TechCheck/techCheckDashboard.typedefs';
import {
  DashboardAttentionIconType,
} from '@/components/platform/GroupDashboard/layers/View/DashboardAttentionIcon/dashboardAttentionIcon.typedefs';
import { StudentStatus } from '@/controllers/graphql/generated';
import { WEEK } from '@/constants';

export enum GroupDashboardErrorCode {
  GroupNotFound = 'not_found_group_description',
  StudentsNotFound = 'not_found_students_description',
  WrongGroupName = 'wrong_group_name_description',
  NoAccess = 'students_group_no_access_description',
}

export enum GroupDashboardColorTheme {
  Yellow = 'yellow',
  Purple = 'purple',
  Orange = 'orange',
  LightRed = 'light-red',
  Blue = 'blue',
  Green = 'green',
  Pink = 'pink',
  Default = 'black',
  Gray = 'gray',
}

type ColorClassMapType = {
  [K in GroupDashboardColorTheme]: `color-${K}`
};

export const ColorClassesMap: ColorClassMapType = {
  [GroupDashboardColorTheme.Yellow]: `color-${GroupDashboardColorTheme.Yellow}`,
  [GroupDashboardColorTheme.Purple]: `color-${GroupDashboardColorTheme.Purple}`,
  [GroupDashboardColorTheme.Orange]: `color-${GroupDashboardColorTheme.Orange}`,
  [GroupDashboardColorTheme.LightRed]: `color-${GroupDashboardColorTheme.LightRed}`,
  [GroupDashboardColorTheme.Blue]: `color-${GroupDashboardColorTheme.Blue}`,
  [GroupDashboardColorTheme.Green]: `color-${GroupDashboardColorTheme.Green}`,
  [GroupDashboardColorTheme.Pink]: `color-${GroupDashboardColorTheme.Pink}`,
  [GroupDashboardColorTheme.Default]: `color-${GroupDashboardColorTheme.Default}`,
  [GroupDashboardColorTheme.Gray]: `color-${GroupDashboardColorTheme.Gray}`,
};

export enum DeadlineTranslateCodes {
  SetDeadline = 'deadline.set_deadline',
  DeadlineNotSet = 'deadline.deadline_not_set',
  NoDeadline = 'deadline.no_deadline',
  Today = 'deadline.today',
  Yesterday = 'deadline.yesterday',
}

export const tabByURLMapper: Record<GroupDashboardTabUrl, GroupDashboardTab> = {
  progress: GroupDashboardTab.Homework,
  'morning-tasks': GroupDashboardTab.MorningTasks,
  attendance: GroupDashboardTab.Attendance,
  english: GroupDashboardTab.English,
  'tech-check': GroupDashboardTab.TechCheck,
  'auto-tech-check': GroupDashboardTab.AutoTechCheck,
  applying: GroupDashboardTab.Applying,
  summary: GroupDashboardTab.Summary,
  'flex-tech-check': GroupDashboardTab.FlexTechCheck,
};

export const featureByURLMapper: Record<
  GroupDashboardTabUrl,
  Features
> = {
  [GroupDashboardTabUrl.Homework]: Features.StudentsHomeworkDashboard,
  [GroupDashboardTabUrl.MorningTasks]: Features.StudentsMorningTasksDashboard,
  [GroupDashboardTabUrl.Attendance]: Features.StudentsAttendanceDashboard,
  [GroupDashboardTabUrl.English]: Features.StudentsEnglishDashboard,
  [GroupDashboardTabUrl.TechCheck]: Features.StudentsTechCheckDashboard,
  [GroupDashboardTabUrl.FlexTechCheck]: Features.StudentsFlexTechCheckDashboard,
  [GroupDashboardTabUrl.AutoTechCheck]: Features.StudentAutoTechCheckDashboard,
  [GroupDashboardTabUrl.Applying]: Features.StudentsApplyingDashboard,
  [GroupDashboardTabUrl.Summary]: Features.StudentsSummaryDashboard,
};

export const featureByTabMapper: Record<
  GroupDashboardTab,
  Features
> = {
  [GroupDashboardTab.Homework]: Features.StudentsHomeworkDashboard,
  [GroupDashboardTab.MorningTasks]: Features.StudentsMorningTasksDashboard,
  [GroupDashboardTab.Attendance]: Features.StudentsAttendanceDashboard,
  [GroupDashboardTab.English]: Features.StudentsEnglishDashboard,
  [GroupDashboardTab.Typing]: Features.StudentsTypingDashboard,
  [GroupDashboardTab.TechCheck]: Features.StudentsTechCheckDashboard,
  [GroupDashboardTab.FlexTechCheck]: Features.StudentsFlexTechCheckDashboard,
  [GroupDashboardTab.AutoTechCheck]: Features.StudentAutoTechCheckDashboard,
  [GroupDashboardTab.Applying]: Features.StudentsApplyingDashboard,
  [GroupDashboardTab.Summary]: Features.StudentsSummaryDashboard,
};

export const groupDashboardDataQaValues = {
  dashboard: 'group_dashboard',
  mainTable: 'group_main_table',
  studentsTable: 'group_students_table',
  header: 'dashboard_header',
  groupSelect: 'dashboard_group_select',
  studentsFilter: 'group_students_filter',
  tabs: 'group_dashboard_tabs',
  widgets: 'group_dashboard_widgets',
  studentsTableBody: 'group_students_table_body',
  studentsTableRow: 'group_students_table_row',
  studentsTableCell: 'group_students_table_cell',
  studentName: 'group_student_name',
  mainTableSection: 'group_main_table_section',
  mainTableRow: 'main_table_row',
  mainTableCell: 'main_table_cell',
  sectionSummaryColumn: 'main_table_section_summary_column',
  sectionButton: 'group_section_button',
  homeworkAdminTable: 'homework_dashboard_table-admin',
  taskSidebar: 'task_sidebar',
  mainTableHeader: 'main_table_header',
  controlButton: 'control_button',
  loadMoreButton: 'load_more_button',
  mainTableData: 'main_table_data',
  englishPlannedIcon: 'english_planned_icon',
  englishAttendedIcon: 'english_attended_icon',
  englishExpectedToBookIcon: 'english_expect_to_book_icon',
  englishMayBookIcon: 'english_may_book_icon',
  taskProgressDiagram: 'task_progress_diagram',
  morningTask: {
    doneStatistic: 'morning_task_done_statistic',
  },
  attendance: {
    attendanceStatistic: 'attendance_statistic',
    attendedQnACountStatistics: 'attended_qna_count_statistics',
    typingSpeedStatistics: 'typing_speed_statistics',
  },
  techCheck: {
    getMainTableScoreIcon: (scoreType: TechCheckScoreType) => `tech_check_main_table_score_icon-${scoreType}`,
  },
  applying: {
    cvSent: 'cv_sent_statistic',
    interviewsPassed: 'interviews_passed_statistic',
    tasksCompleted: 'tasks_completed_statistic',
  },
  homework: {
    studentCellPeerReviews: 'student-cell_peer_reviews_count',
    studentCellPunctuality: 'student-cell_punctuality',
    studentCellPending: 'student-cell_pending',
    studentCellUnstarted: 'student-cell_unstarted',
  },
  english: {
    studentEnglishLevel: 'student_english_level',
    attendedEnglishLessons: 'attended_english_lessons',
  },
  autoTechCheck: {
    averageAutoTechCheckScore: 'auto_tech_check_score',
  },
};

export const DASHBOARD_PAGE_ID = 'dashboard-page';

export const ADMIN_FT_DASHBOARD_TABS = [
  GroupDashboardTab.Homework,
  GroupDashboardTab.MorningTasks,
  GroupDashboardTab.Attendance,
  GroupDashboardTab.English,
  GroupDashboardTab.Typing,
  GroupDashboardTab.TechCheck,
  GroupDashboardTab.AutoTechCheck,
  GroupDashboardTab.Applying,
  GroupDashboardTab.Summary,
];

export const FLEX_DASHBOARD_TABS = [
  GroupDashboardTab.Homework,
  GroupDashboardTab.Applying,
  GroupDashboardTab.FlexTechCheck,
];

export const FX_DASHBOARD_AVAILABLE_STUDENTS_STATUSES = {
  [GroupDashboardTab.Homework]: [
    StudentStatus.CourseCompleted,
    StudentStatus.PreEmployment,
    StudentStatus.Employment,
  ],
  [GroupDashboardTab.FlexTechCheck]: [
    StudentStatus.CourseCompleted,
    StudentStatus.PreEmployment,
    StudentStatus.Employment,
  ],
  [GroupDashboardTab.Applying]: [
    StudentStatus.Employment,
  ],
};

export const STUDENTS_FT_DASHBOARD_TABS = [
  GroupDashboardTab.Homework,
  GroupDashboardTab.MorningTasks,
  GroupDashboardTab.Attendance,
  GroupDashboardTab.English,
  GroupDashboardTab.Typing,
  GroupDashboardTab.TechCheck,
  GroupDashboardTab.AutoTechCheck,
  GroupDashboardTab.Applying,
];

export const COLOR_THEME_ICON_TYPE_MAP = {
  [DashboardAttentionIconType.None]: null,
  [DashboardAttentionIconType.Warning]: GroupDashboardColorTheme.Yellow,
  [DashboardAttentionIconType.Error]: GroupDashboardColorTheme.LightRed,
  [DashboardAttentionIconType.Success]: null,
};

export const DASHBOARD_AVAILABLE_TO_START_GROUP_TIME = WEEK * 2;
