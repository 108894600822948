import { ApolloCache, ApolloClient } from '@apollo/client';
import { INITIAL_QUERY_VARIABLES } from '@/components/platform/Chat/chat.constants';
import { ChatGroupsDocument, ChatGroupsQuery } from '@/components/platform/Chat/graphql/generated/chatGroups.query.generated';
import { ChatWithAuthParticipantMinimalFragment } from '@/components/platform/Chat/graphql/generated/chatWithAuthParticipantMinimal.generated';
import { HasUnreadChatMessagesDocument, HasUnreadChatMessagesQuery } from '@/components/platform/Chat/graphql/generated/hasUnreadChatMessages.query.generated';

export class UnreadMessagesCacheHelper {
  private store: ApolloCache<any>;

  private client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
    this.store = client.cache;
  }

  public setHasUnreadMessagesFlag(hasMoreMessages: boolean): void {
    this.store.modify({
      fields: {
        hasUnreadChatMessages: () => hasMoreMessages,
      },
    });
  }

  public async hasMoreUnreadMessages(): Promise<boolean> {
    if (this.hasMoreLocalUnreadMessages()) {
      return true;
    }

    return this.hasMoreUnreadMessagesOnServer();
  }

  private hasMoreLocalUnreadMessages(): boolean {
    const existingChats = this.store.readQuery<ChatGroupsQuery>({
      query: ChatGroupsDocument,
      variables: INITIAL_QUERY_VARIABLES,
    });

    const groupHasUnreadMessages = (
      chats: ChatWithAuthParticipantMinimalFragment[],
    ) => (
      chats.some((chat) => Number(chat.unreadMessagesCount) > 0)
    );

    return (
      groupHasUnreadMessages(existingChats?.allChats || [])
        || groupHasUnreadMessages(existingChats?.favouriteChats || [])
        || groupHasUnreadMessages(existingChats?.directChats || [])
    );
  }

  private async hasMoreUnreadMessagesOnServer(): Promise<boolean> {
    const response = await this.client.query<HasUnreadChatMessagesQuery>({
      query: HasUnreadChatMessagesDocument,
      fetchPolicy: 'network-only',
    });

    return response.data.hasUnreadChatMessages;
  }
}
