const currentUrl = () => {
  const result = {};

  try {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    result.url = window.location.href;
  } catch (e) {
    // do nothing
  }

  return result;
};

module.exports = {
  currentUrl,
};
