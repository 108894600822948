import { ApolloCache, ApolloClient } from '@apollo/client';
import { ThreadMessageTypes } from '@/components/platform/Chat/chat.typedefs';
import {
  ChatMessagesDocument,
  ChatMessagesQuery,
} from '@/components/platform/Chat/graphql/generated/chatMessages.query.generated';
import {
  SubscribedThreadsDocument,
  SubscribedThreadsQuery,
} from '@/components/platform/Chat/graphql/generated/subscribedThreads.query.generated';
import { SUBSCRIBED_THREADS_LIMIT } from '@/components/platform/Chat/chat.constants';
import {
  LatestThreadsDocument,
  LatestThreadsQuery,
} from '@/components/platform/Chat/graphql/generated/latestThreads.query.generated';

export interface MinimalDeletedMessage {
  id: number;
  chatId: number;
}

export const addMessageToQuery = (
  store: ApolloCache<any>,
  receivedMessage: ThreadMessageTypes,
) => {
  const chatMessagesQuery = store.readQuery<ChatMessagesQuery>({
    query: ChatMessagesDocument,
    variables: {
      chatId: receivedMessage.chatId,
    },
  });

  if (!chatMessagesQuery?.chatMessages) {
    return;
  }

  const { messages, hasMoreNewerMessages } = chatMessagesQuery.chatMessages;

  if (hasMoreNewerMessages) {
    return;
  }

  const alreadyExists = messages.some(
    (message) => message.id === receivedMessage.id,
  );

  if (alreadyExists) {
    return;
  }

  store.writeQuery<ChatMessagesQuery>({
    query: ChatMessagesDocument,
    variables: {
      chatId: receivedMessage.chatId,
    },
    data: {
      ...chatMessagesQuery,
      chatMessages: {
        ...chatMessagesQuery.chatMessages,
        messages: [...messages, receivedMessage],
      },
    },
  });
};

export const jumpToLatestChatMessages = async (
  apolloClient: ApolloClient<any>,
  chatId: number,
) => {
  const response = await apolloClient.query<LatestThreadsQuery>({
    query: LatestThreadsDocument,
    variables: {
      chatId,
    },
  });

  const latestMessages = response.data.latestThreads;

  apolloClient.cache.writeQuery<ChatMessagesQuery>({
    query: ChatMessagesDocument,
    variables: {
      chatId,
    },
    data: {
      chatMessages: {
        __typename: 'ChatMessagesQueryResult',
        messages: latestMessages,
        hasMoreNewerMessages: false,
        hasMoreOlderMessages: true,
      },
    },
  });
};

export const jumpToUnreadChatMessages = async (
  apolloClient: ApolloClient<any>,
  chatId: number,
) => {
  await apolloClient.query<ChatMessagesQuery>({
    query: ChatMessagesDocument,
    variables: {
      chatId,
    },
    fetchPolicy: 'network-only',
  });
};

const deleteFromChatMessages = (
  store: ApolloCache<any>,
  thread: MinimalDeletedMessage,
) => {
  const queryResult = store.readQuery<ChatMessagesQuery>({
    query: ChatMessagesDocument,
    variables: {
      chatId: thread.chatId,
    },
  });

  if (!queryResult?.chatMessages) {
    return;
  }

  const filteredThreads = queryResult.chatMessages.messages.filter(
    (threadFromCache) => threadFromCache.id !== thread.id,
  );

  store.writeQuery<ChatMessagesQuery>({
    query: ChatMessagesDocument,
    variables: {
      chatId: thread.chatId,
    },
    data: {
      ...queryResult,
      chatMessages: {
        ...queryResult.chatMessages,
        messages: filteredThreads,
      },
    },
  });
};

const deleteFromSubscribedThreads = (
  store: ApolloCache<any>,
  thread: MinimalDeletedMessage,
  filters: { unreadOnly?: boolean },
) => {
  const { unreadOnly } = filters;

  const threadsCache = store.readQuery<SubscribedThreadsQuery>({
    query: SubscribedThreadsDocument,
    variables: {
      limit: SUBSCRIBED_THREADS_LIMIT,
      unreadOnly,
    },
  });

  if (!threadsCache?.subscribedThreads) {
    return;
  }

  const filteredThreads = threadsCache.subscribedThreads.filter(
    (threadFromCache) => threadFromCache.id !== thread.id,
  );

  store.writeQuery<SubscribedThreadsQuery>({
    query: SubscribedThreadsDocument,
    variables: {
      limit: SUBSCRIBED_THREADS_LIMIT,
      unreadOnly,
    },
    data: {
      subscribedThreads: filteredThreads,
    },
  });
};

export const deleteThreadFromQuery = <T>(
  store: ApolloCache<T>,
  thread: MinimalDeletedMessage,
) => {
  deleteFromChatMessages(store, thread);
  deleteFromSubscribedThreads(store, thread, { unreadOnly: true });
  deleteFromSubscribedThreads(store, thread, { unreadOnly: false });
};
