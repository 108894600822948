import {
  HorizontalPositionMode, TextHorizontalMode,
  ToolTipContainerWidthMode,
  ToolTipWidthMode,
  VerticalPositionMode,
} from '@/components/ui/ToolTip/tooltip.typedefs';
import styles from './ToolTip.module.scss';

export const horizontalPositionModeClasses: Record<
  HorizontalPositionMode, string
> = {
  [HorizontalPositionMode.LeftSide]: styles.leftSideMode,
  [HorizontalPositionMode.Center]: styles.centerMode,
  [HorizontalPositionMode.RightSide]: styles.rightSideMode,
};

export const verticalPositionModeClasses: Record<
  VerticalPositionMode, string
> = {
  [VerticalPositionMode.Top]: styles.topMode,
  [VerticalPositionMode.Bottom]: styles.bottomMode,
};

export const widthModeClassNames: Record<ToolTipWidthMode, string> = {
  [ToolTipWidthMode.Min]: styles.minWidthMode,
  [ToolTipWidthMode.Max]: styles.maxWidthMode,
  [ToolTipWidthMode.Full]: styles.fullWidthMode,
};

export const wrapperWidthModeClassNames: Record<
  ToolTipContainerWidthMode, string
> = {
  [ToolTipContainerWidthMode.Regular]: '',
  [ToolTipContainerWidthMode.MaxContent]: styles.maxWidthMode,
  [ToolTipContainerWidthMode.FullContent]: styles.fullWidthMode,
};

export const textHorizontalClassnames: Record<TextHorizontalMode, string> = {
  [TextHorizontalMode.Left]: 'text-left',
  [TextHorizontalMode.Center]: 'text-center',
};
