import { makeVar } from '@apollo/client';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';

export enum StorageType {
  LocalStorage = 'LOCAL_STORAGE',
  SessionStorage = 'SESSION_STORAGE',
}

const storageFallback = makeVar<Record<string, string>>(
  {},
);

// this is a fallback for the case when window is not defined
// or when the browser does not support localStorage/sessionStorage
// or user has disabled browser storage
const inMemoryStorage: Storage = {
  getItem(key: string) {
    return storageFallback()[key] ?? null;
  },
  setItem(key: string, value: string) {
    storageFallback({
      ...storageFallback(),
      [key]: value,
    });
  },
  removeItem(key: string) {
    const newStorage = { ...storageFallback() };

    delete newStorage[key];

    storageFallback(newStorage);
  },
  clear() {
    storageFallback({});
  },
  get length() {
    return Object.keys(storageFallback()).length;
  },
  key(index: number) {
    return Object.keys(storageFallback())[index] ?? null;
  },
};

const getStorageByType = (storageType: StorageType): Storage => {
  try {
    return storageType === StorageType.SessionStorage
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      ? window.sessionStorage
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      : window.localStorage;
  } catch {
    return inMemoryStorage;
  }
};

interface InitStorageOptions<T> {
  key: string;
  initialValue: T;
}
const initBrowserStorage = <T>(
  options: InitStorageOptions<T> & { type: StorageType },
): {
  readFromStorage: () => T;
  writeToStorage: (value: T) => void;
  removeFromStorage: () => void;
} => {
  const { key, initialValue, type } = options;

  const storage = getStorageByType(type);

  const readFromStorage = (): T => {
    try {
      const item = storage.getItem(key);

      return item && item !== String(undefined)
        ? JSON.parse(item) as T
        : initialValue;
    } catch (error) {
      errorHandler.captureException(error, {
        logMessage: `[${type}]: can't read value from storage`,
        logger: logger.child('initBrowserStorage.readFromStorage'),
        fields: {
          key,
          initialValue,
        },
      });

      return initialValue;
    }
  };

  const writeToStorage = (value: T) => {
    try {
      if (storage) {
        storage.setItem(key, JSON.stringify(value));
      }
    } catch (error: any) {
      errorHandler.captureException(error, {
        logMessage: `[${type}]: cant write value to storage`,
        logger: logger.child('initBrowserStorage.writeToStorage'),
        fields: {
          key,
          value,
        },
      });
    }
  };

  const removeFromStorage = () => {
    try {
      if (storage) {
        storage.removeItem(key);
      }
    } catch (error: any) {
      errorHandler.captureException(error, {
        logMessage: `[${type}]: cant remove value from storage`,
        logger: logger.child('initBrowserStorage.removeFromStorage'),
        fields: {
          key,
        },
      });
    }
  };

  return {
    readFromStorage,
    writeToStorage,
    removeFromStorage,
  };
};

export const initLocalStorage = <T>(
  options: InitStorageOptions<T>,
) => initBrowserStorage({ ...options, type: StorageType.LocalStorage });

export const initSessionStorage = <T>(
  options: InitStorageOptions<T>,
) => initBrowserStorage({ ...options, type: StorageType.SessionStorage });
