import { useEffect } from 'react';
import { emptyFunction } from '@/lib/helpers/functional';

type UseListenMediaChangeArgs = {
  media: string;
  condition: boolean;
  callback: (matches: boolean) => void;
};

export const useListenMediaChange = ({
  media,
  condition,
  callback,
}: UseListenMediaChangeArgs) => {
  useEffect(() => {
    if (!condition) {
      return emptyFunction;
    }

    const mediaQueryList = window.matchMedia(media);

    const changeHandler = (event: MediaQueryListEvent) => {
      callback(event.matches);
    };

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', changeHandler);
    } else {
      // Polyfill for Safari and older browsers
      mediaQueryList.addListener(changeHandler);
    }

    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', changeHandler);
      } else {
        // Polyfill for Safari and older browsers
        mediaQueryList.removeListener(changeHandler);
      }
    };
  }, [media, condition, callback]);
};
