import { PopUpAlignment, PopUpPositioner } from '@/components/ui/PopUp/PopUp.helpers';
import {
  MessageSubjectType,
} from '@/components/platform/Chat/components/Input/MessageSubjectSelector/MessageSubjectSelector.typedefs';

/* keep in sync with scss styles */
export const MODAL_WIDTH = 344;
export const MODAL_HEIGHT = 352;
const MODAL_INDENT = 4;

export const SUBJECT_EMPTY = {
  type: MessageSubjectType.EMPTY,
} as const;

export const SUBJECT_OTHER = {
  type: MessageSubjectType.OTHER,
} as const;

export const modalPositioner = new PopUpPositioner({
  popupHeight: MODAL_HEIGHT,
  popupWidth: MODAL_WIDTH,
  popupIndent: MODAL_INDENT,
  preferredHorizontalAlignment: PopUpAlignment.Left,
  preferredVerticalAlignment: PopUpAlignment.Top,
});
