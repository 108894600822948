import { ROUTES } from '@/controllers/router/router.contants';

export const defaultRedirectUrl = ROUTES.user.learn;
export const sanitizeRedirectUrl = (url: string): string => {
  try {
    // Decode the URL to handle any encoded characters
    const decodedUrl = decodeURIComponent(url);

    // Remove control characters and 'javascript:' occurrences to prevent XSS
    const sanitizedUrl = decodedUrl
      // eslint-disable-next-line no-control-regex
      .replace(/[\u0000-\u001F\u007F-\u009F]/gi, '')
      .trim();

    // eslint-disable-next-line no-script-url
    if (sanitizedUrl.includes('javascript:')) {
      return defaultRedirectUrl;
    }

    return sanitizedUrl;
  } catch (e) {
    return defaultRedirectUrl;
  }
};
