import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { initLocalStorage, initSessionStorage, StorageType } from '@/lib/browserStorage';

export type SetFunction<T> = (value: T | ((value: T) => T)) => void;

function useStorage<T>(
  key: string,
  initialValue: T,
  onInit: (value: T) => any = () => null,
  storageType: StorageType = StorageType.LocalStorage,
): [T, SetFunction<T>] {
  const {
    readFromStorage,
    writeToStorage,
  } = useMemo(
    () => (
      storageType === StorageType.SessionStorage
        ? initSessionStorage({
          key,
          initialValue,
        })
        : initLocalStorage({
          key,
          initialValue,
        })
    ),
    [key, initialValue, storageType],
  );

  const [storedValue, setStoredValue] = useState(readFromStorage());

  const setValue: SetFunction<T> = useCallback((value) => {
    if (typeof value === 'function') {
      setStoredValue((prevValue: T) => {
        const newValue = (value as (value: T) => T)(prevValue);

        writeToStorage(newValue);

        return newValue;
      });
    } else {
      setStoredValue(value);
      writeToStorage(value);
    }
  }, [writeToStorage]);

  useEffect(() => {
    const value = readFromStorage();

    setValue(value);

    onInit(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [storedValue, setValue];
}

interface StorageHook {
  <T>(
    key: string,
    initialValue: T,
    onInit?: (value: T) => any,
  ): [T, SetFunction<T>];
}

export const useLocalStorage: StorageHook = (
  key,
  initialValue,
  onInit = () => null,
) => useStorage(key, initialValue, onInit, StorageType.LocalStorage);

export const useSessionStorage: StorageHook = (
  key,
  initialValue,
  onInit = () => null,
) => useStorage(key, initialValue, onInit, StorageType.SessionStorage);

export const storageController = {
  useLocalStorage,
  useSessionStorage,
};
