export interface LinkedTask {
  id: number;
  name: string;
}

export enum MessageSubjectType {
  TASK = 'TASK',
  OTHER = 'OTHER',
  EMPTY = 'EMPTY',
}

interface MessageSubjectWithSelectedTask {
  type: MessageSubjectType.TASK;
  task: LinkedTask;
}

interface MessageSubjectWithOtherPlaceholder {
  type: MessageSubjectType.OTHER;
}

interface EmptyMessageSubject {
  type: MessageSubjectType.EMPTY;
}

export type MessageSubjectState = (
  MessageSubjectWithSelectedTask
    | MessageSubjectWithOtherPlaceholder
    | EmptyMessageSubject
);
