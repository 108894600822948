import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from './IconLogoSquare.module.scss';

export const IconLogoSquare: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={cn(className, styles.logoIcon)}
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="mate-logo-square-fill">
      <path d="M34.1999 30.7143C34.1999 32.5 35.3983 33.619 37.0522 33.619C38.7061 33.619 39.9046 32.5 39.9046 30.7143C39.9046 28.9286 38.7061 27.8095 37.0522 27.8095C35.3983 27.8095 34.1999 28.9286 34.1999 30.7143Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44 0C50.6274 0 56 5.37258 56 12V44C56 50.6274 50.6274 56 44 56H12C5.37258 56 0 50.6274 0 44V12C0 5.37258 5.37258 0 12 0H44ZM25.4435 20H29.2786V36.6667H25.4435V26.9762L21.105 34.0476H20.6736L16.3351 26.9762V36.6667H12.5V20H16.3351L20.8893 27.4048L25.4435 20ZM39.9046 25.881V24.7619H43.5V36.6667H39.9046V35.5476C39.1136 36.4524 37.9391 37 36.3331 37C33.1931 37 30.6044 34.2619 30.6044 30.7143C30.6044 27.1667 33.1931 24.4286 36.3331 24.4286C37.9391 24.4286 39.1136 24.9762 39.9046 25.881Z" />
    </g>

    <g id="mate-logo-square-text">
      <path d="M29.2786 20H25.4435L20.8893 27.4048L16.3351 20H12.5V36.6667H16.3351V26.9762L20.6736 34.0476H21.105L25.4435 26.9762V36.6667H29.2786V20Z" />
      <path d="M39.9046 24.7619V25.881C39.1136 24.9762 37.9391 24.4286 36.3331 24.4286C33.1931 24.4286 30.6044 27.1667 30.6044 30.7143C30.6044 34.2619 33.1931 37 36.3331 37C37.9391 37 39.1136 36.4524 39.9046 35.5476V36.6667H43.5V24.7619H39.9046ZM37.0522 33.619C35.3983 33.619 34.1999 32.5 34.1999 30.7143C34.1999 28.9286 35.3983 27.8095 37.0522 27.8095C38.7061 27.8095 39.9046 28.9286 39.9046 30.7143C39.9046 32.5 38.7061 33.619 37.0522 33.619Z" />
    </g>
  </BaseIcon>
);
