import { LogLevels } from '@mate-academy/logger';
import { isBrowser } from '@/lib/helpers/isBrowser';
import { updateParticipantLastActionTime } from '@/components/platform/Chat/store.helpers';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';
import { MentionCacheHelper } from '@/components/platform/Chat/cache.helpers/mention.helper';
import {
  useChatMessageReadWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageReadWS.subscription.generated';
import { UnreadMessagesCacheHelper } from '@/components/platform/Chat/cache.helpers/unreadMessages.helper';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';
import { isValueInObject } from '@/lib/helpers/functional';
import { EMPTY_OBJECT } from '@/constants/common';

interface Options {
  skip?: boolean;
}

export const useChatMessageReadWS = (options: Options = EMPTY_OBJECT) => {
  useChatMessageReadWsSubscription({
    skip: !isBrowser || options.skip,
    onData: ({
      client,
      data: { data },
    }) => {
      const payload = data?.chatMessageReadWS;

      if (!payload || isValueInObject(payload)(undefined)) {
        errorHandler.captureMessage(
          'undefined payload occurred in useChatMessageReadWS subscription',
          {
            logger: logger.child(useChatMessageReadWS.name),
            logLevel: LogLevels.Warning,
          },
        );

        return;
      }

      const {
        message,
        participantId,
        hasTotalUnreadMessages,
        unreadChatMentionsCount,
        unreadTotalMentionsCount,
      } = payload;

      switch (message.__typename) {
        case MessageType.Thread:
        case MessageType.OpenQuestion:
        case MessageType.Poll:
        case MessageType.SystemMessage: {
          const { chatId, createdAt } = message;

          updateParticipantLastActionTime({
            store: client.cache,
            participantId,
            lastActionTime: createdAt,
            chatId,
          });

          const mentionCacheHelper = new MentionCacheHelper(client.cache);
          const unreadsCacheHelper = new UnreadMessagesCacheHelper(client);

          mentionCacheHelper.setGlobalChatMentionsCount(
            unreadTotalMentionsCount,
          );

          unreadsCacheHelper.setHasUnreadMessagesFlag(hasTotalUnreadMessages);

          mentionCacheHelper.setChatUnreadMentionsCount(
            chatId,
            unreadChatMentionsCount,
          );

          mentionCacheHelper.setChatMentionsCounter(
            chatId,
            unreadChatMentionsCount,
          );

          break;
        }

        default:
          break;
      }
    },
  });
};
