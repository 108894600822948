import React, { FC, memo } from 'react';
import { IconKnowelyLogoCircle } from '@/components/ui/icons/IconKnowelyLogoCircle';

type Props = {
  className?: string;
};

export const KnowelyLogoCircle: FC<Props> = memo(({
  className,
}) => <IconKnowelyLogoCircle className={className} />);
