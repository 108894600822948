import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  DAY, HOUR, MINUTE, SECOND,
} from '@/constants';
import { getCountdownText, getNextRunDate } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.helpers';
import styles from './MainLeaderboardCountdown.module.scss';

export const MainLeaderboardCountdown: React.FC = () => {
  const { t } = useTranslation(I18N_CODES.learn);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateCountdown = useCallback(() => {
    const targetDate = getNextRunDate().getTime();

    const now = new Date().getTime();
    const diff = targetDate - now;

    const days = Math.floor(diff / DAY);
    const hours = Math.floor((diff % DAY) / HOUR);
    const minutes = Math.floor((diff % HOUR) / MINUTE);
    const seconds = Math.floor((diff % MINUTE) / SECOND);

    setCountdown({
      days, hours, minutes, seconds,
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(calculateCountdown, SECOND);

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountdownTranslate = useCallback((countdownPart: string): string => (
    t(`${I18N_CODES.learn}:leaderboard.countdown_${countdownPart}`)
  ), [t]);

  const countdownText = getCountdownText(
    getCountdownTranslate,
    countdown,
  );

  return (
    <>
      <p className={styles.title}>
        {t(`${I18N_CODES.learn}:leaderboard.countdown_finish_at`)}
      </p>

      <p className={styles.countdown}>{countdownText}</p>
    </>
  );
};
