import React, { useEffect, useRef } from 'react';
import { components, OptionProps, OptionTypeBase } from 'react-select';

const NativeOption = components.Option;

export const AutoScrollOption = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean
>(props: OptionProps<OptionType, IsMulti>) => {
  const innerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (props.isSelected && innerRef.current) {
      innerRef.current.scrollIntoView({
        block: 'start',
      });
    }
  }, [props.isSelected]);

  return (
    <NativeOption {...props} innerRef={innerRef} />
  );
};
