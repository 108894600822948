import { FC, useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { ChatMessagesDocument } from '@/components/platform/Chat/graphql/generated/chatMessages.query.generated';
import { UnreadThreadRepliesCountDocument } from '@/components/platform/Chat/graphql/generated/unreadThreadRepliesCount.query.generated';
import { SubscribedThreadsDocument } from '@/components/platform/Chat/graphql/generated/subscribedThreads.query.generated';
import { UnreadChatMessagesCountDocument } from '@/components/platform/Chat/graphql/generated/unreadChatMessagesCount.query.generated';
import { ChatsDocument } from '@/components/platform/Chat/graphql/generated/chats.query.generated';
import { SearchUserChatsDocument } from '@/components/platform/Chat/graphql/generated/searchUserChats.query.generated';
import { UserMentionsDocument } from '@/components/platform/Chat/graphql/generated/userMentions.query.generated';
import { ChatsGroupDocument } from '@/components/platform/Chat/graphql/generated/chatsGroup.query.generated';
import { PinnedMessagesDocument } from '@/components/platform/Chat/graphql/generated/chatPinnedMessages.query.generated';

export const RefetchActiveQueriesOnOnline: FC = () => {
  const client = useApolloClient();

  const refetchQueries = useCallback(() => {
    client.refetchQueries({
      include: [
        ChatMessagesDocument,
        UnreadThreadRepliesCountDocument,
        SubscribedThreadsDocument,
        UnreadChatMessagesCountDocument,
        ChatsDocument,
        ChatsGroupDocument,
        SearchUserChatsDocument,
        UserMentionsDocument,
        PinnedMessagesDocument,
      ],
    });

    client.cache.gc();
  }, [client]);

  useEffect(() => {
    window.addEventListener('online', refetchQueries);

    return () => {
      window.removeEventListener('online', refetchQueries);
    };
  }, [refetchQueries]);

  return null;
};
