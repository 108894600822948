import { Provider } from '@mate-academy/react-di';
import YouTube from 'react-youtube';
import {
  CitySelector,
} from '@/components/ui/FormElements/FormInputs/GoogleCitySelector/GoogleCitySelector';
import {
  CountrySelector,
} from '@/components/ui/FormElements/FormInputs/GoogleCountrySelector/GoogleCountrySelector';
import {
  LocationSelector,
} from '@/components/ui/FormElements/FormInputs/GoogleLocationSelector/GoogleLocationSelector';

export const productionProviders: Provider[] = [
  YouTube,
  CitySelector,
  CountrySelector,
  LocationSelector,
];
