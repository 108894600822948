import { ConfirmModalTypePolicies } from '@/controllers/confirm/confirm.typePolicies';
import {
  userVacanciesQueryTypePolicies,
} from '@/controllers/apollo/apollo.typePolicies/queries/userVacanciesQuery.typePolicies';
import { FlashMessagesTypePolicies } from '@/controllers/flashMessage/flashMessages.typePolicies';
import { ChatListUIStateTypePolicies } from '@/controllers/chat/chats.typePolicies';

export const QueryTypePolicies = {
  Query: {
    fields: {
      ...userVacanciesQueryTypePolicies,
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      ...(typeof window === 'undefined'
        ? {}
        : ConfirmModalTypePolicies),
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      ...(typeof window === 'undefined'
        ? {}
        : FlashMessagesTypePolicies),
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      ...(typeof window === 'undefined'
        ? {}
        : ChatListUIStateTypePolicies),
    },
  },
};
