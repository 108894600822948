import NProgress from 'nprogress';
import { Router } from '@/middleware/i18n';
import { shouldIgnoreRouteChange } from '@/core/progressBar/filters';

export function initProgressBar() {
  NProgress.configure({
    minimum: 0.3,
    speed: 500,
    trickleSpeed: 100,
    showSpinner: false,
  });

  Router.events.on('routeChangeStart', (newRoute) => {
    const shouldBeIgnored = shouldIgnoreRouteChange(newRoute);

    if (shouldBeIgnored) {
      return;
    }

    NProgress.start();
  });
  Router.events.on('routeChangeComplete', () => NProgress.done());
  Router.events.on('routeChangeError', () => NProgress.done());
}
