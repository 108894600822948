import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { IconLogoSquareAnniversary } from '@/components/ui/icons/IconLogoSquareAnniversary';
import { IconLogoSquare } from '@/components/ui/icons/IconLogoSquare';
import styles from './MateLogo.module.scss';

type Props = {
  className?: string;
  withHoverEffect?: boolean;
};

export const MateLogoSquare: FC<Props> = memo(({
  className,
  withHoverEffect = false,
}) => {
  const features = useFeatures();

  const isAnniversaryLogoEnabled = features.isEnabled(
    Features.MateLogoAnniversary,
  );

  return isAnniversaryLogoEnabled
    ? (
      <IconLogoSquareAnniversary
        className={cn(className, {
          [styles.withHoverEffect]: withHoverEffect,
        })}
      />
    )
    : (
      <IconLogoSquare
        className={cn(className, {
          [styles.withHoverEffect]: withHoverEffect,
        })}
      />
    );
});
