import { resetUnreadThreadReplies, updateThreadLastActionTime } from '@/components/platform/Chat/store.helpers';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';
import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  useThreadReplyReadSubscription,
} from '@/components/platform/Chat/graphql/generated/threadReplyRead.subscription.generated';

export const useThreadReplyRead = () => {
  useThreadReplyReadSubscription({
    skip: !isBrowser,
    onData: ({
      client: { cache: store },
      data: { data },
    }) => {
      const message = data?.threadReplyRead.message;
      const unreadThreadRepliesCount = (
        data?.threadReplyRead?.unreadThreadRepliesCount || 0
      );

      if (!message) {
        return;
      }

      switch (message.__typename) {
        case MessageType.OpenQuestionAnswer: {
          updateThreadLastActionTime(
            store,
            MessageType.OpenQuestion,
            message.threadId,
            message.createdAt,
          );
          resetUnreadThreadReplies(store, unreadThreadRepliesCount);

          break;
        }

        case MessageType.Message: {
          updateThreadLastActionTime(
            store,
            MessageType.Thread,
            message.threadId,
            message.createdAt,
          );
          resetUnreadThreadReplies(store, unreadThreadRepliesCount);

          break;
        }

        default:
          break;
      }
    },
  });
};
