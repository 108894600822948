import React, { FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { SvgProps } from '@/components/ui/icons/typedefs';
import styles from './BaseIcon.module.scss';

export enum IconSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const BaseIcon: FC<SvgProps> = memo((props) => {
  const {
    className,
    children,
    size,
    dataQa,
    ...rest
  } = props;

  return (
    <svg
      data-qa={dataQa}
      {...rest}
      className={cn([className, 'icon'], {
        [styles.small]: IconSize.Small === size,
        [styles.medium]: IconSize.Medium === size,
        [styles.large]: IconSize.Large === size,
      })}
    >
      {children}
    </svg>
  );
});
