/* eslint-disable @mate-academy/frontend/restrict-window-usage */
import { logger } from '@/core/Logger';
import { exists } from '@/lib/helpers/functional';

enum Placement {
  Left,
  Right,
  Top,
  Bottom,
}

enum Alignment {
  Left,
  Right,
  Top,
  Bottom,
  VerticalCenter,
  HorizontalCenter,
}

enum PositioningContext {
  Window,
  Parent
}

type HorizontalAlignment = Alignment.Left
  | Alignment.Right
  | Alignment.HorizontalCenter;
type VerticalAlignment = Alignment.Top
  | Alignment.Bottom
  | Alignment.VerticalCenter;

export {
  Alignment as PopUpAlignment,
  Placement as PopUpPlacement,
  PositioningContext as PopUpPositioningContext,
};

export type {
  HorizontalAlignment as PopUpHorizontalAlignment,
  VerticalAlignment as PopUpVerticalAlignment,
};

type VerticalPosition = { top: number };
type HorizontalPosition = { left: number };
type Position = VerticalPosition & HorizontalPosition;

export type {
  Position as PopUpPosition,
};

// tricky switch-case types manipulation
// https://stackoverflow.com/questions/68474835/how-to-use-a-switch-statement-to-narrow-down-a-type-using-conditional-types
type PositionFromPlacement<T extends Placement> = T extends
  (Placement.Bottom | Placement.Top) ? VerticalPosition : HorizontalPosition;
type PositionFromAlignment<T extends Alignment> = T extends
  VerticalAlignment ? VerticalPosition : HorizontalPosition;

export interface PopUpPositionerSettings {
  popupHeight: number;
  popupWidth: number;
  popupIndent?: number;
  preferredHorizontalAlignment?: HorizontalAlignment;
  preferredVerticalAlignment?: VerticalAlignment;
  preferredPlacement?: Placement;
  positioningContext?: PositioningContext;
}

export class PopUpPositioner {
  public static readonly DEFAULT_POSITION: Position = { top: 0, left: 0 };

  private readonly popupHeight: number;

  private readonly popupWidth: number;

  private readonly popupIndent: number = 0;

  private readonly preferredHorizontalAlignment: HorizontalAlignment;

  private readonly preferredVerticalAlignment: VerticalAlignment;

  private readonly preferredPlacement: Placement;

  private readonly positioningContext: PositioningContext;

  constructor(
    settings: PopUpPositionerSettings,
  ) {
    this.popupHeight = settings.popupHeight;
    this.popupWidth = settings.popupWidth;
    this.popupIndent = settings.popupIndent || 0;
    this.preferredHorizontalAlignment = (
      settings.preferredHorizontalAlignment || Alignment.Left
    );
    this.preferredVerticalAlignment = (
      settings.preferredVerticalAlignment || Alignment.Top
    );
    this.preferredPlacement = settings.preferredPlacement || Placement.Bottom;
    this.positioningContext = (
      settings.positioningContext || PositioningContext.Window
    );
  }

  public getPosition(parentRect?: DOMRect): Position {
    if (!parentRect || !window || !window.innerWidth || !window.innerHeight) {
      return PopUpPositioner.DEFAULT_POSITION;
    }

    const horizontalAlignment = this.getHorizontalAlignment(parentRect);
    const verticalAlignment = this.getVerticalAlignment(parentRect);

    if (
      exists(this.preferredPlacement)
        && this.canOpen(parentRect, this.preferredPlacement)
    ) {
      return this.getPreferredPlacementPosition(
        parentRect,
        horizontalAlignment,
        verticalAlignment,
      );
    }

    if (this.canOpen(parentRect, Placement.Bottom)) {
      return this.getBottomPlacementPosition(parentRect, horizontalAlignment);
    }

    if (this.canOpen(parentRect, Placement.Top)) {
      return this.getTopPlacementPosition(parentRect, horizontalAlignment);
    }

    if (this.canOpen(parentRect, Placement.Left)) {
      return this.getLeftPlacementPosition(parentRect, verticalAlignment);
    }

    if (this.canOpen(parentRect, Placement.Right)) {
      return this.getRightPlacementPosition(parentRect, verticalAlignment);
    }

    return this.getCentralPlacementPosition();
  }

  private getPreferredPlacementPosition(
    parentRect: DOMRect,
    horizontalAlignment: HorizontalAlignment,
    verticalAlignment: VerticalAlignment,
  ) {
    switch (this.preferredPlacement) {
      case Placement.Bottom:
        return this.getBottomPlacementPosition(
          parentRect,
          horizontalAlignment,
        );
      case Placement.Top:
        return this.getTopPlacementPosition(parentRect, horizontalAlignment);
      case Placement.Left:
        return this.getLeftPlacementPosition(parentRect, verticalAlignment);
      case Placement.Right:
        return this.getRightPlacementPosition(parentRect, verticalAlignment);
      default:
        return this.getCentralPlacementPosition();
    }
  }

  private getBottomPlacementPosition(
    parentRect: DOMRect,
    horizontalAlignment: HorizontalAlignment,
  ) {
    return {
      ...this.getPositionByPlacement(parentRect, Placement.Bottom),
      ...this.getPositionByAlignment(parentRect, horizontalAlignment),
    };
  }

  private getTopPlacementPosition(
    parentRect: DOMRect,
    horizontalAlignment: HorizontalAlignment,
  ) {
    return {
      ...this.getPositionByPlacement(parentRect, Placement.Top),
      ...this.getPositionByAlignment(parentRect, horizontalAlignment),
    };
  }

  private getLeftPlacementPosition(
    parentRect: DOMRect,
    verticalPosition: VerticalAlignment,
  ) {
    return {
      ...this.getPositionByAlignment(parentRect, verticalPosition),
      ...this.getPositionByPlacement(parentRect, Placement.Left),
    };
  }

  private getRightPlacementPosition(
    parentRect: DOMRect,
    verticalPosition: VerticalAlignment,
  ) {
    return {
      ...this.getPositionByAlignment(parentRect, verticalPosition),
      ...this.getPositionByPlacement(parentRect, Placement.Right),
    };
  }

  private getCentralPlacementPosition() {
    return {
      ...this.getCentralVerticalPosition(),
      ...this.getCentralHorizontalPosition(),
    };
  }

  private canOpen(parentRect: DOMRect, placement: Placement): boolean {
    const popupHeightWithIndent = this.popupHeight + this.popupIndent;
    const popupWidthWithIndent = this.popupWidth + this.popupIndent;

    switch (placement) {
      case Placement.Top:
        return parentRect.top > popupHeightWithIndent;
      case Placement.Bottom:
        return parentRect.bottom + popupHeightWithIndent < window.innerHeight;
      case Placement.Left:
        return parentRect.left > popupWidthWithIndent;
      case Placement.Right:
        return parentRect.right + popupWidthWithIndent < window.innerWidth;
      default:
        return false;
    }
  }

  private getPositionByPlacement<
    Pl extends Placement,
    Pos extends PositionFromPlacement<Pl>
  >(parentRect: DOMRect, placement: Pl): Pos {
    switch (this.positioningContext) {
      case PositioningContext.Parent:
        return this.getParentRelatedPositionByPlacement(parentRect, placement);
      case PositioningContext.Window:
      default:
        return this.getWindowRelatedPositionByPlacement(parentRect, placement);
    }
  }

  private getWindowRelatedPositionByPlacement<
    Pl extends Placement,
    Pos extends PositionFromPlacement<Pl>
  >(parentRect: DOMRect, placement: Pl): Pos {
    const popupHeightWithIndent = this.popupHeight + this.popupIndent;
    const popupWidthWithIndent = this.popupWidth + this.popupIndent;

    switch (placement) {
      case Placement.Top:
        return { top: parentRect.top - popupHeightWithIndent } as Pos;
      case Placement.Bottom:
        return { top: parentRect.bottom + this.popupIndent } as Pos;
      case Placement.Left:
        return { left: parentRect.left - popupWidthWithIndent } as Pos;
      case Placement.Right:
        return { left: parentRect.right + this.popupIndent } as Pos;
      default:
        logger.warning(
          `Invalid pop-up placement in getPositionByPlacement method. Received placement value: ${placement}.`,
        );

        return { top: 0 } as Pos;
    }
  }

  private getParentRelatedPositionByPlacement<
    Pl extends Placement,
    Pos extends PositionFromPlacement<Pl>
  >(parentRect: DOMRect, placement: Pl): Pos {
    const popupHeightWithIndent = this.popupHeight + this.popupIndent;
    const popupWidthWithIndent = this.popupWidth + this.popupIndent;

    switch (placement) {
      case Placement.Top:
        return { top: -popupHeightWithIndent } as Pos;
      case Placement.Bottom:
        return { top: parentRect.height + this.popupIndent } as Pos;
      case Placement.Left:
        return { left: -popupWidthWithIndent } as Pos;
      case Placement.Right:
        return { left: parentRect.width + this.popupIndent } as Pos;
      default:
        logger.warning(
          `Invalid pop-up placement in getPositionByPlacement method. Received placement value: ${placement}.`,
        );

        return { top: 0 } as Pos;
    }
  }

  private getPositionByAlignment <
    Al extends Alignment,
    Pos extends PositionFromAlignment<Al>
  >(parentRect: DOMRect, alignment: Al): Pos {
    switch (this.positioningContext) {
      case PositioningContext.Parent:
        return this.getParentRelatedPositionByAlignment(parentRect, alignment);
      case PositioningContext.Window:
      default:
        return this.getWindowRelatedPositionByAlignment(parentRect, alignment);
    }
  }

  private getWindowRelatedPositionByAlignment<
    Al extends Alignment,
    Pos extends PositionFromAlignment<Al>
  >(parentRect: DOMRect, alignment: Al): Pos {
    switch (alignment) {
      case Alignment.Left:
        return { left: parentRect.left } as Pos;
      case Alignment.Right:
        return { left: parentRect.right - this.popupWidth } as Pos;
      case Alignment.Top:
        return { top: parentRect.top } as Pos;
      case Alignment.Bottom:
        return { top: parentRect.bottom - this.popupHeight } as Pos;
      case Alignment.HorizontalCenter:
        return this.getCentralHorizontalPosition() as Pos;
      case Alignment.VerticalCenter:
        return this.getCentralVerticalPosition() as Pos;
      default:
        logger.warning(
          `Invalid pop-up alignment in getPositionByAlignment method. Received alignment value: ${alignment}.`,
        );

        return { top: 0 } as Pos;
    }
  }

  private getParentRelatedPositionByAlignment<
    Al extends Alignment,
    Pos extends PositionFromAlignment<Al>
  >(parentRect: DOMRect, alignment: Al): Pos {
    switch (alignment) {
      case Alignment.Left:
        return { left: 0 } as Pos;
      case Alignment.Right:
        return { left: parentRect.width - this.popupWidth } as Pos;
      case Alignment.Top:
        return { top: 0 } as Pos;
      case Alignment.Bottom:
        return { top: parentRect.height - this.popupHeight } as Pos;
      case Alignment.HorizontalCenter:
        return this.getCentralHorizontalPosition() as Pos;
      case Alignment.VerticalCenter:
        return this.getCentralVerticalPosition() as Pos;
      default:
        logger.warning(
          `Invalid pop-up alignment in getPositionByAlignment method. Received alignment value: ${alignment}.`,
        );

        return { top: 0 } as Pos;
    }
  }

  private getHorizontalAlignment(
    parentRect: DOMRect,
  ): HorizontalAlignment {
    const canAlignLeft = this.canAlign(parentRect, Alignment.Left);
    const canAlignRight = this.canAlign(parentRect, Alignment.Right);
    const canAlignCenter = this.canAlign(
      parentRect,
      Alignment.HorizontalCenter,
    );

    if (canAlignLeft && canAlignRight) {
      return this.preferredHorizontalAlignment;
    }

    if (canAlignLeft) {
      return Alignment.Left;
    }

    if (canAlignRight) {
      return Alignment.Right;
    }

    if (canAlignCenter) {
      return Alignment.HorizontalCenter;
    }

    logger.warning(
      `Cannot align pop-up horizontally. Window width: ${window.innerWidth}. Pop-up width: ${this.popupWidth}.`,
    );

    return Alignment.HorizontalCenter;
  }

  private getVerticalAlignment(
    parentRect: DOMRect,
  ): VerticalAlignment {
    const canAlignTop = this.canAlign(parentRect, Alignment.Top);
    const canAlignBottom = this.canAlign(parentRect, Alignment.Bottom);
    const canAlignCenter = this.canAlign(parentRect, Alignment.VerticalCenter);

    if (canAlignBottom && canAlignTop) {
      return this.preferredVerticalAlignment;
    }

    if (canAlignTop) {
      return Alignment.Top;
    }

    if (canAlignBottom) {
      return Alignment.Bottom;
    }

    if (canAlignCenter) {
      return Alignment.VerticalCenter;
    }

    logger.warning(
      `Cannot align pop-up vertically. Window height: ${window.innerHeight}. Pop-up height: ${this.popupHeight}.`,
    );

    return Alignment.VerticalCenter;
  }

  private canAlign(parentRect: DOMRect, alignment: Alignment): boolean {
    switch (alignment) {
      case Alignment.Left:
        return parentRect.left + this.popupWidth < window.innerWidth;
      case Alignment.Right:
        return parentRect.right - this.popupWidth > 0;
      case Alignment.Top:
        return parentRect.top + this.popupHeight < window.innerHeight;
      case Alignment.Bottom:
        return parentRect.bottom - this.popupHeight > 0;
      case Alignment.HorizontalCenter:
        return window.innerWidth > this.popupWidth;
      case Alignment.VerticalCenter:
        return window.innerHeight > this.popupHeight;
      default:
        return false;
    }
  }

  private getCentralVerticalPosition(): VerticalPosition {
    return { top: (window.innerHeight - this.popupHeight) / 2 };
  }

  private getCentralHorizontalPosition(): HorizontalPosition {
    return { left: (window.innerWidth - this.popupWidth) / 2 };
  }
}
