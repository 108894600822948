import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconQuestionLight: FCIcon = (props) => (
  <BaseIcon
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24.0003 37.3337C16.6363 37.3337 10.667 31.3643 10.667 24.0003C10.667 16.6363 16.6363 10.667 24.0003 10.667C31.3643 10.667 37.3337 16.6363 37.3337 24.0003C37.3337 31.3643 31.3643 37.3337 24.0003 37.3337ZM24.0003 34.667C26.8293 34.667 29.5424 33.5432 31.5428 31.5428C33.5432 29.5424 34.667 26.8293 34.667 24.0003C34.667 21.1713 33.5432 18.4582 31.5428 16.4579C29.5424 14.4575 26.8293 13.3337 24.0003 13.3337C21.1713 13.3337 18.4582 14.4575 16.4579 16.4579C14.4575 18.4582 13.3337 21.1713 13.3337 24.0003C13.3337 26.8293 14.4575 29.5424 16.4579 31.5428C18.4582 33.5432 21.1713 34.667 24.0003 34.667ZM22.667 28.0003L25.3337 28.0003V30.667L22.667 30.667V28.0003ZM25.3337 25.807V26.667L22.667 26.667V24.667C22.667 24.3134 22.8075 23.9742 23.0575 23.7242C23.3076 23.4741 23.6467 23.3337 24.0003 23.3337C24.3791 23.3336 24.7501 23.2261 25.0701 23.0234C25.3901 22.8208 25.646 22.5315 25.808 22.1891C25.97 21.8467 26.0315 21.4654 25.9852 21.0894C25.939 20.7135 25.7869 20.3584 25.5468 20.0655C25.3066 19.7726 24.9882 19.554 24.6286 19.435C24.269 19.3159 23.883 19.3015 23.5156 19.3933C23.1481 19.4851 22.8142 19.6793 22.5528 19.9534C22.2914 20.2275 22.1132 20.5702 22.039 20.9417L19.423 20.4177C19.5852 19.6071 19.9597 18.8542 20.5082 18.2358C21.0568 17.6174 21.7597 17.1558 22.5451 16.8981C23.3305 16.6404 24.1703 16.5959 24.9786 16.7691C25.7868 16.9424 26.5346 17.3271 27.1454 17.8841C27.7562 18.441 28.2082 19.1502 28.4551 19.9391C28.7021 20.728 28.7351 21.5683 28.5508 22.3741C28.3665 23.1799 27.9716 23.9223 27.4064 24.5255C26.8411 25.1286 26.1258 25.5709 25.3337 25.807Z" fill="currentColor" />
  </BaseIcon>
);
