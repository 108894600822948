import { isBrowser } from '@/lib/helpers/isBrowser';
import { ReactionCacheHelper } from '@/components/platform/Chat/cache.helpers/reaction.helper';
import {
  useReactionDeletedFromStackSubscription,
} from '@/components/platform/Chat/graphql/generated/reactionDeletedFromStack.subscription.generated';

export const useReactionDeletedFromStack = (authUserId?: number) => {
  useReactionDeletedFromStackSubscription({
    skip: !isBrowser || !authUserId,
    onData: ({ client, data }) => {
      const payload = data.data?.reactionDeletedFromStack;

      if (!payload) {
        return;
      }

      const cacheHelper = new ReactionCacheHelper(client.cache);

      const isPayloadFromAuthUser = payload.senderUserId === authUserId;
      const hasReaction = cacheHelper.hasAuthUserReacted(payload.id);

      if (!hasReaction && isPayloadFromAuthUser) {
        return;
      }

      cacheHelper.decrementStackReactionsCount(payload.id, {
        isAuthUser: payload.senderUserId === authUserId,
      });
    },
  });
};
