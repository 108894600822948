import React from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from '../PrimaryLeaderboardHeader/PrimaryLeaderboardHeader.module.scss';

interface Props {
  groupName: string;
}

export const FullTimeLeaderboardHeader: React.FC<Props> = ({ groupName }) => {
  const { t } = useTranslation([I18N_CODES.learn]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.flex}>
        <h3 className={cn(typography.platformH1, styles.title)} data-qa="leaderboard-table">
          {t(`${I18N_CODES.learn}:leaderboard.user_not_found_block_title`)}

        </h3>
        <h3 className={cn(typography.platformH1, styles.title)} data-qa="group-name">
          {` ${groupName}` }
        </h3>
      </div>
      <p className={cn(typography.platformTextSmall, styles.description)}>
        {t(`${I18N_CODES.learn}:leaderboard.lead_board_description`)}
      </p>
    </div>
  );
};
