import React, { FC, memo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { LogoSquareMap } from '@/components/common/Logo/constants';
import { Products } from '@/constants/products';

type Props = {
  className?: string;
  withHoverEffect?: boolean;
};

export const LogoSquare: FC<Props> = memo(({
  className,
  withHoverEffect = false,
}) => {
  const { productName } = useSubDomainContext();

  const Logo = LogoSquareMap[productName ?? Products.Mate];

  return (
    <Logo
      className={className}
      withHoverEffect={withHoverEffect}
    />
  );
});
