import React from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { LeaderboardLeagueType } from '@/controllers/graphql/generated';
import { ToolTip, ToolTipWidthMode, VerticalPositionMode } from '@/components/ui/ToolTip';
import { Selectors } from '@/lib/selectors';
import { MainLeaderboardCountdown } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/MainLeaderboardCountdown';
import { LEAGUES_STEPS_CONTENT } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.constants';
import { LeaderboardFaqButton } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/LeaderboardFaqButton';
import styles from './MainLeaderboardHeader.module.scss';

interface Props {
  monthOfStart: string;
  userLeagueType: LeaderboardLeagueType;
}

export const MainLeaderboardHeader: React.FC<Props> = ({
  monthOfStart,
  userLeagueType,
}) => {
  const { t } = useTranslation([I18N_CODES.learn]);

  const leagueTextCode = userLeagueType === LeaderboardLeagueType.Platinum
    ? 'leaderboard.top_league_tooltip_text'
    : 'leaderboard.league_tooltip_text';

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h3 className={cn(typography.platformH1, styles.title)}>
          {t(`${I18N_CODES.learn}:leaderboard.main_board_${monthOfStart}`)}
        </h3>
        <div className={styles.countdownWrapper}>
          <MainLeaderboardCountdown />

          <LeaderboardFaqButton />
        </div>
      </div>

      <div className={styles.stepsWrapper}>
        {Object.entries(LEAGUES_STEPS_CONTENT).map(
          ([leagueType, leagueData]) => {
            const {
              icon,
              titleCode,
            } = leagueData;

            const isUserLeague = leagueType === userLeagueType;

            return (
              <div
                key={leagueType}
                className={cn(styles.leagueStep, {
                  [Selectors.Active]: isUserLeague,
                })}
              >
                <ToolTip
                  text={t(`${I18N_CODES.learn}:${leagueTextCode}`)}
                  isVisibleOnHover={isUserLeague}
                  widthMode={ToolTipWidthMode.Max}
                  verticalPosition={VerticalPositionMode.Bottom}
                  className={styles.toolTipWrapper}
                >
                  {icon}

                  {isUserLeague && (
                    <h4 className={cn(
                      typography.platformTextSmall,
                      styles.leagueTitle,
                    )}
                    >
                      {t(`${I18N_CODES.learn}:${titleCode}`)}
                    </h4>
                  )}
                </ToolTip>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};
