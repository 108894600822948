import { makeVar } from '@apollo/client';
import { ChatGroups } from '@/components/platform/Chat/chat.typedefs';
import {
  ALL_CHATS_INITIAL_LIMIT,
  DIRECT_CHATS_INITIAL_LIMIT,
  FAVOURITE_CHATS_INITIAL_LIMIT,
} from '@/components/platform/Chat/chat.constants';

export type HasMoreState = Record<ChatGroups, boolean>;
export type CursorsState = Record<ChatGroups, number>;

export interface ChatsListUIState {
  isInitialized: boolean;
  hasMore: HasMoreState;
  cursors: CursorsState;
}

export const CHATS_LIST_UI_STATE_INITIAL_VALUE: ChatsListUIState = {
  isInitialized: false,
  hasMore: {
    [ChatGroups.Favorite]: false,
    [ChatGroups.All]: false,
    [ChatGroups.Direct]: false,
  },
  cursors: {
    [ChatGroups.Favorite]: FAVOURITE_CHATS_INITIAL_LIMIT,
    [ChatGroups.All]: ALL_CHATS_INITIAL_LIMIT,
    [ChatGroups.Direct]: DIRECT_CHATS_INITIAL_LIMIT,
  },
};

export const chatListUIStateVar = makeVar<ChatsListUIState>(
  CHATS_LIST_UI_STATE_INITIAL_VALUE,
);
