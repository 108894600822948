export const possibleTypes = {
  UserProfile: ['PublicUserProfile', 'PrivateUserProfile'],
  AbstractMessage: ['Message', 'Thread', 'OpenQuestion', 'OpenQuestionAnswer', 'Poll', 'PollAnswer', 'SystemMessage'],
  AbstractMessageWS: ['MessageWS', 'ThreadWS', 'OpenQuestionWS', 'OpenQuestionAnswerWS', 'PollWS', 'PollAnswerWS', 'SystemMessageWS'],
  AbstractGuidelineMessage: ['GuidelinePlainText', 'GuidelineThread', 'GuidelineOpenQuestion', 'OpenQuestionAnswer', 'GuidelinePoll'],
  AbstractChat: ['GroupChat', 'PrivateChat'],
  EventAbstract: ['EnglishEvent', 'ScheduleEvent', 'TechCheckEvent', 'WebinarEvent', 'InterviewEvent', 'TechCheckSessionEvent'],
  EventSkeleton: ['CourseEventSkeleton', 'EnglishLessonEventSkeleton', 'TechCheckEventSkeleton'],
  TestTaskAttempt: ['QuizAttempt', 'AttachAttempt', 'CodeEditorAttempt', 'GitHubAttempt', 'CheckQuizResult', 'AddAttachmentTaskLinkResult', 'CodeEditorCheckResult'],
  Details: ['WebinarDetails', 'EventDetails', 'GroupEventDetails'],
  MateEvent: ['EnglishEvent', 'WebinarEvent', 'TechCheckEvent', 'ScheduleEvent'],
  ArchivedEvent: ['EnglishEvent', 'WebinarEvent', 'TechCheckEvent'],
  RatedItem: ['TestTask', 'CourseTopic', 'LearnVideo'],
  Profile: ['CandidateProfile', 'RecruiterProfile'],
  AbstractNotification: ['BasicNotification', 'TaskReviewNotification', 'RatingNotification'],
  CourseTopicLearnItem: ['CourseTopicTaskLearnItem', 'CourseTopicVideoLearnItem', 'CourseTopicTheoryLearnItem'],
};
