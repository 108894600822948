const i18nExtractLanguageSubpath = ({
  url,
  supportedLanguages,
}) => {
  const [urlWithoutAnchor] = url.split('#');
  const [urlWithoutQuery] = urlWithoutAnchor.split('?');

  const urlWithoutProtocol = urlWithoutQuery.replace(/https?:\/\//, '');
  const urlParts = urlWithoutProtocol.split('/');
  const languageSubpath = urlParts[1];
  const isLanguageSubpath = supportedLanguages.includes(languageSubpath);

  if (isLanguageSubpath) {
    return languageSubpath;
  }

  return null;
};

module.exports = {
  i18nExtractLanguageSubpath,
};
