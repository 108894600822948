import cookie from 'cookie';

export const shouldShowGBConsoleMessages = () => {
  let shouldLogConsoleMessage = false;

  try {
    const cookieValue = cookie
      .parse(document.cookie)
      .should_log_gb_messages;

    if (cookieValue === 'true') {
      shouldLogConsoleMessage = true;
    }
  } catch {
    // do nothing
  }

  return shouldLogConsoleMessage;
};
