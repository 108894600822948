import gql from 'graphql-tag';

export const EventsSidebarSchema = gql`
  extend type Mutation {
    toggleEventsSidebar(open: Boolean!): [Boolean]
  }

  extend type Query {
    eventsSidebarState: EventsSidebar!
  }

  type EventsSidebar {
    open: Boolean!
  }
`;
