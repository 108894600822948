import { FieldState, FieldValidator } from 'final-form';

export const noopListener = (
  event: {
    preventDefault: () => void;
  },
): any => {
  event.preventDefault();
};

export const composeValidators = <FieldValue>(
  ...validators: Array<FieldValidator<FieldValue>>
) => (
    value: FieldValue,
    // Needed to match the original validator's type
    // eslint-disable-next-line @typescript-eslint/ban-types
    allValues: object,
    meta?: FieldState<FieldValue>,
  ) => validators.reduce(
    (
      error: string | null,
      validate,
    ) => error || validate(value, allValues, meta),
    null,
  );
