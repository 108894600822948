import React, { FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { SvgProps } from '@/components/ui/icons/typedefs';

export enum CupMode {
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
}

export const CUP_COLORS_BY_TYPE = {
  [CupMode.Bronze]: {
    handleColor: '#AB6C4A',
    cupColor: '#EB9362',
  },
  [CupMode.Silver]: {
    handleColor: '#B9B9B9',
    cupColor: '#D7D7D7',
  },
  [CupMode.Gold]: {
    handleColor: '#FFAC33',
    cupColor: '#FFCC4D',
  },
};

interface CupIconProps {
  cupMode: CupMode;
}

export const IconCup: FC<SvgProps & CupIconProps> = (props) => {
  const {
    cupMode,
    ...iconProps
  } = props;

  const {
    handleColor,
    cupColor,
  } = CUP_COLORS_BY_TYPE[cupMode || CupMode.Bronze];

  return (
    <BaseIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <g>
        <path d="M2.2766 2.22225H4.94326C5.43393 2.22225 5.77749 2.17603 5.77749 2.66669V1.7778C5.77749 1.28714 5.43393 0.888916 4.94326 0.888916H1.38771C0.49882 0.888916 -0.204736 1.83336 0.0543751 3.11114C0.0543751 3.11114 0.850375 7.2778 0.906375 7.53692C1.05438 8.22225 1.85037 8.88892 2.70237 8.88892H5.79482C6.28593 8.88892 5.77749 8.04625 5.77749 7.55558V6.66669C5.77749 7.15736 5.43393 7.55558 4.94326 7.55558H3.16549C2.67482 7.55558 2.35082 7.09247 2.2766 6.66669C2.20238 6.24092 1.5726 3.40758 1.5726 3.40758C1.4246 2.55558 1.78549 2.22225 2.2766 2.22225ZM13.7486 2.22225H11.0819C10.5913 2.22225 9.77749 2.17603 9.77749 2.66669V1.7778C9.77749 1.28714 10.5913 0.888916 11.0819 0.888916H14.6375C15.5264 0.888916 16.2304 1.83336 15.9708 3.11114C15.9708 3.11114 15.245 7.29736 15.1837 7.55558C15.0264 8.22225 14.2762 8.88892 13.4059 8.88892H10.2299C9.73926 8.88892 9.77749 8.04625 9.77749 7.55558V6.66669C9.77749 7.15736 10.5913 7.55558 11.0819 7.55558H12.8597C13.3504 7.55558 13.6748 7.09247 13.7486 6.66669C13.8224 6.24092 14.4526 3.40758 14.4526 3.40758C14.6002 2.55558 14.2397 2.22225 13.7486 2.22225ZM9.25838 9.77781C9.25838 6.68536 8.05437 9.77781 8.05437 9.77781C8.05437 9.77781 6.72104 6.68536 6.72104 9.77781C6.72104 12.8702 5.25838 14.2222 5.25838 14.2222H10.7215C10.721 14.2222 9.25838 12.8702 9.25838 9.77781Z" fill={handleColor} />
        <path d="M12.9438 2.92318C12.9438 5.9343 9.9349 11.0085 8.0549 11.0085C6.17446 11.0085 3.16602 5.9343 3.16602 2.92318C3.16602 0.614295 3.61046 0.11474 4.49935 0.11474C5.11002 0.11474 7.17713 0.10274 8.0549 0.10274L11.166 0.102295C12.4625 0.101851 12.9438 0.406295 12.9438 2.92318Z" fill={cupColor} />
        <path d="M12 14.6666C12 15.1573 12.1009 15.5555 11.6102 15.5555H4.49911C4.008 15.5555 4 15.1573 4 14.6666V14.2221C4 13.7315 4.51733 13.3333 4.98044 13.3333H11.1658C11.6289 13.3333 12 13.7315 12 14.2221V14.6666Z" fill="#C1694F" />
        <path d="M12.8891 15.389C12.8891 15.7267 12.9624 16.0001 12.3327 16.0001H3.77711C3.20288 16.0001 3.11133 15.7267 3.11133 15.389V15.2779C3.11133 14.9401 3.43933 14.6667 3.77711 14.6667H12.3327C12.67 14.6667 12.8891 14.9401 12.8891 15.2779V15.389Z" fill="#C1694F" />
      </g>
      <path d="M28.0007 20.5134L32.1207 23L31.0273 18.3134L34.6673 15.16L29.874 14.7534L28.0007 10.3334L26.1273 14.7534L21.334 15.16L24.974 18.3134L23.8807 23L28.0007 20.5134Z" fill="#FFFFFF" />
      <defs>
        <clipPath id="clip0_53225_5383">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};
