import React, { FC } from 'react';
import { DEFAULT_SUB_DOMAIN } from '@/middleware/i18n/i18n.config';
import { SubDomains } from '@/constants/subdomains';
import { Products } from '@/constants/products';

export interface SubDomainContextProps {
  subDomain: SubDomains | string;
  productName?: Products;
}

export const SubDomainContext = React.createContext<{
  subDomain: SubDomains | string;
  productName?: Products;
}>({
  subDomain: DEFAULT_SUB_DOMAIN,
  productName: Products.Mate,
});

export const SubDomainProvider: FC<SubDomainContextProps> = ({
  children,
  ...value
}) => (
  <SubDomainContext.Provider value={value}>
    {children}
  </SubDomainContext.Provider>
);
