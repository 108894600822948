export const MINUTES_IN_HOUR = 60;
export const DAYS_IN_MONTH = 30;

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * MINUTES_IN_HOUR;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * DAYS_IN_MONTH;
export const EXAM_HOURS_DEADLINE = 0;
export const EXAM_MINUTES_DEADLINE = 5;
export const EXAM_MINUTES_TO_SEND_TASK = 2;
export const EXAM_TIME_TO_SEND_TASK = EXAM_MINUTES_TO_SEND_TASK * MINUTE;
export const TEST_ASSIGNMENT_INFORM_STUDENT = 5 * MINUTE;
export const SOLUTION_WAIT_HOURS = 24;

export const DAY_HOUR_BREAKPOINTS = {
  start: new Date(2020, 0, 0, 0, 0),
  end: new Date(2020, 0, 0, 24, 0),
  lateEvening: new Date(2020, 0, 0, 22, 0),
};
