import { Products } from '@/constants/products';
import { ColorTheme } from './typedefs';

export const LIGHT_COLOR_THEMES_BY_PRODUCT = {
  [Products.Knowely]: ColorTheme.KnowelyLight,
  [Products.Mate]: ColorTheme.Light,
};

export const DARK_COLOR_THEMES_BY_PRODUCT = {
  [Products.Knowely]: ColorTheme.KnowelyDark,
  [Products.Mate]: ColorTheme.Dark,
};

export function getColorTheme(
  product: Products = Products.Mate,
  theme: ColorTheme,
) {
  return theme === ColorTheme.Light
    ? LIGHT_COLOR_THEMES_BY_PRODUCT[product]
    : DARK_COLOR_THEMES_BY_PRODUCT[product];
}
