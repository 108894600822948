import { LIFECYCLE, Lifecycle, STATUS } from 'react-joyride';

type Status = typeof STATUS[keyof typeof STATUS];

export const CHAT_ONBOARDING_FINISHED_STATUSES: Status[] = [
  STATUS.FINISHED,
  STATUS.SKIPPED,
];

export const FINISHED_ONBOARDING_LIFYCYCLE: Lifecycle = LIFECYCLE.COMPLETE;

export const CHAT_DATA_ONBOARDING_ATTRIBUTES = {
  PERSONAL_CHAT: 'personal-chat',
  QNA_CHAT: 'qna-chat',
  THREAD_REPLY_BUTTON: 'reply-to-thread-button',
  MESSAGE_WRAPPER: 'chat-message-wrapper',
};

export const USER_SOLUTION_DATA_ONBOARDING_ATTRIBUTES = {
  TEST_TASK_ACTIVITY_BANNER: 'test-task-activity-banner',
  USER_SOLUTION_BUTTON: 'user-solution-button',
};

export const LMS_SEARCH_BUTTON_ONBOARDING_ATTRIBUTE = 'lms-search';
