import { SidebarResolvers } from '@/controllers/apollo/apollo.modules/sidebar/sidebar.resolvers';
import { VacancyEditorResolvers } from '@/controllers/apollo/apollo.modules/vacancyEditor/vacancyEditor.resolvers';
import { EventsSidebarResolvers } from '@/controllers/apollo/apollo.modules/eventsSidebar/eventsSidebar.resolvers';
import { UserVacancySidebarResolvers } from '@/controllers/apollo/apollo.modules/userVacancySidebar/userVacancySidebar.resolvers';

export const resolvers = [
  SidebarResolvers,
  VacancyEditorResolvers,
  EventsSidebarResolvers,
  UserVacancySidebarResolvers,
];
