import { ApolloCache } from '@apollo/client';
import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  SubscribedThreadUpdatedSubscription,
  useSubscribedThreadUpdatedSubscription,
} from '@/components/platform/Chat/graphql/generated/subscribedThreadUpdated.subscription.generated';
import {
  SubscribedThreadsDocument,
  SubscribedThreadsQuery,
} from '@/components/platform/Chat/graphql/generated/subscribedThreads.query.generated';
import { SUBSCRIBED_THREADS_LIMIT } from '@/components/platform/Chat/chat.constants';
import { threadUpdatesVar, unreadUpdatesVar } from '@/controllers/subscribedThreadUpdates/subcribedThreadUpdates.cache';
import { incrementUnreadThreadRepliesQuery } from '@/components/platform/Chat/store.helpers';

interface UpdateCacheOptions<Query> {
  cache: ApolloCache<Query>;
  receivedUpdate: SubscribedThreadUpdatedSubscription['subscribedThreadUpdated'];
  unreadOnly: boolean;
}

export const useSubscribedThreadUpdateReceived = () => {
  useSubscribedThreadUpdatedSubscription({
    skip: !isBrowser,
    onData: ({ client, data }) => {
      const { cache } = client;
      const receivedUpdate = data.data?.subscribedThreadUpdated;

      if (!receivedUpdate || receivedUpdate.__typename !== 'Message') {
        return;
      }

      if (!receivedUpdate.isOwn) {
        incrementUnreadThreadRepliesQuery(cache);
      }

      updateCache({ cache, receivedUpdate, unreadOnly: true });
      updateCache({ cache, receivedUpdate, unreadOnly: false });
    },
  });
};

function updateCache<Query>({
  cache,
  receivedUpdate,
  unreadOnly,
}: UpdateCacheOptions<Query>) {
  if (!receivedUpdate || receivedUpdate.__typename !== 'Message') {
    return;
  }

  const subscribedThreadsQuery = cache.readQuery<SubscribedThreadsQuery>({
    query: SubscribedThreadsDocument,
    variables: {
      limit: SUBSCRIBED_THREADS_LIMIT,
      unreadOnly,
    },
  });

  if (!subscribedThreadsQuery) {
    return;
  }

  const threads = subscribedThreadsQuery.subscribedThreads;

  const alreadyPresent = threads.some(
    (thread) => thread.id === receivedUpdate.threadId,
  );

  if (alreadyPresent) {
    // User already has latest data, no need to do anything

    return;
  }

  const { unreadUpdates } = unreadUpdatesVar();
  const { threadUpdates } = threadUpdatesVar();

  if (unreadOnly) {
    unreadUpdatesVar({ unreadUpdates: [...unreadUpdates, receivedUpdate] });
  } else {
    threadUpdatesVar({ threadUpdates: [...threadUpdates, receivedUpdate] });
  }
}
