export function getCoordinatesRelatedToPage(htmlElement: HTMLElement | null) {
  if (!htmlElement) {
    return { top: 0, left: 0 };
  }

  const box = htmlElement.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = docEl.scrollTop || body.scrollTop;
  const scrollLeft = docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}
