export enum ToolTipWidthMode {
  Max = 'maxWidthMode',
  Min = 'minWidthMode',
  Full = 'fullWidthMode',
}

export enum ToolTipContainerWidthMode {
  MaxContent = 'maxContentWidth',
  FullContent = 'fullWidthMode',
  Regular = 'regularContentWidth'
}

export enum HorizontalPositionMode {
  LeftSide = 'leftSideMode',
  RightSide = 'rightSideMode',
  Center = 'centerMode',
}

export enum VerticalPositionMode {
  Top = 'topMode',
  Bottom = 'bottomMode',
}

export enum TextHorizontalMode {
  Center = 'Center',
  Left = 'Left'
}

export enum TooltipOrientation {
  Center = 'centerOrientation',
  Aside = 'asideOrientation',
}

export interface ToolTipProps {
  text: string | JSX.Element;
  className?: string;
  horizontalPosition?: HorizontalPositionMode;
  verticalPosition?: VerticalPositionMode;
  orientation?: TooltipOrientation;
  widthMode?: ToolTipWidthMode;
  messageClassName?: string;
  topScreenOffset?: number;
  textHorizontalPosition?: TextHorizontalMode;
  isPersistant?: boolean;
  isVisible?: boolean;
  onClick?: () => void;
  isManualVerticalPositioning?: boolean;
  containerWidthMode?: ToolTipContainerWidthMode;
  isVisibleOnHover?: boolean;
  renderWithPortal?: boolean;
  shouldIncreaseHoverArea?: boolean;
  applyDefaultStylesForCustomElement?: boolean;
  shouldCloseOnInteraction?: boolean;
  dataQa?: string;
}

export type TooltipWithPortalProps = Pick<
  ToolTipProps,
  'text'
  | 'widthMode'
  | 'verticalPosition'
  | 'horizontalPosition'
  | 'orientation'
  | 'shouldIncreaseHoverArea'
  | 'className'
  | 'applyDefaultStylesForCustomElement'
  | 'shouldCloseOnInteraction'
  | 'dataQa'
  | 'messageClassName'
>;

export interface ChildDimensions {
  width: number;
  height: number;
}
