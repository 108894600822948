import React, { FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import styles from './ButtonBody.module.scss';

interface Props {
  hasOnlyIcon: boolean;
  LeftIcon?: FCIcon;
  RightIcon?: FCIcon;
  text?: string;
  renderLeftIcon?: () => void;
  renderRigthIcon?: () => void;
}

export const ButtonBody: FC<Props> = memo((props) => {
  const {
    LeftIcon,
    RightIcon,
    text,
    hasOnlyIcon,
    renderLeftIcon,
    renderRigthIcon,
  } = props;

  return (
    <>
      {!renderLeftIcon && LeftIcon && (
        <LeftIcon
          className={cn(styles.icon, { [styles.iconLeft]: !hasOnlyIcon })}
        />
      )}

      {renderLeftIcon && renderLeftIcon()}

      {text && (
        <span
          itemProp="name"
          className={styles.buttonText}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}

      {renderRigthIcon && renderRigthIcon()}

      {!renderRigthIcon && RightIcon && (
        <RightIcon
          className={cn(styles.icon, { [styles.iconRight]: !hasOnlyIcon })}
        />
      )}
    </>
  );
});
