import React, {
  DetailedHTMLProps, FC, InputHTMLAttributes, useCallback, useRef, useState,
} from 'react';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import {
  Libraries,
} from '@react-google-maps/api/src/utils/make-load-script-url';
import getConfig from 'next/config';
import { Async } from 'react-select/async';
import { UseFieldConfig } from 'react-final-form';
import { useDI } from '@mate-academy/react-di';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib/classNames';
import {
  withFinalFormController,
} from '@/controllers/forms/forms.hocs/withFinalFormController';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import formStyles
  from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import {
  SelectAsyncUi,
} from '@/components/ui/FormElements/FormInputs/Select/SelectAsync';

export enum GooglePlaceType {
  Locality = 'locality',
  Country = 'country',
}

export interface GoogleLocationResult {
  placeName: string;
  placeId: string;
  placeType: GooglePlaceType;
}

export interface GoogleLocationSelectProps extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> {
  handleChange: (place: GoogleLocationResult) => void;
  inputId: string;
  isMulti?: boolean;
  isDisabled?: boolean;
}

const libraries: Libraries = ['places'];

const { publicRuntimeConfig = {} } = getConfig() || {};
const {
  GOOGLE_PLACES_API_KEY,
} = publicRuntimeConfig;

export const LocationSelectorUi: FC<GoogleLocationSelectProps> = (
  {
    className,
    handleChange,
    inputId,
    isMulti,
    ...rest
  },
) => {
  const inputRef = useRef<Async<SelectOptionInterface> | null>(null);

  const [
    autoCompleteInstance,
    setInstance,
  ] = useState<google.maps.places.Autocomplete | null>(null);

  const onPlaceChanged = useCallback(() => {
    const place = autoCompleteInstance?.getPlace();

    if (place && place.place_id && place.name) {
      const placeType = place.types?.includes(GooglePlaceType.Locality)
        ? GooglePlaceType.Locality
        : GooglePlaceType.Country;

      const preparedPlace: GoogleLocationResult = {
        placeName: place.name,
        placeId: place.place_id,
        placeType,
      };

      handleChange(preparedPlace);

      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [autoCompleteInstance, handleChange]);

  // ATTENTION: On CI we test mocked component before commit test your changes by running local e2e tests
  // INCIDENT: https://app.clickup.com/24383048/v/dc/q83j8-96295/q83j8-916135

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_PLACES_API_KEY}
      libraries={libraries}
      language='en'
    >
      <Autocomplete
        onLoad={(instance) => {
          setInstance(instance);
        }}
        onPlaceChanged={onPlaceChanged}
        types={['locality', 'country']}
        fields={['name', 'place_id', 'address_components', 'types']}
      >
        <SelectAsyncUi
          {...rest}
          isMulti={isMulti}
          className={cn(formStyles.googleCitySelect, className)}
          noOptionsMessage={() => null}
          components={{
            DropdownIndicator: () => null,
          }}
          ref={inputRef}
          inputId={inputId}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export const LocationSelector = withFinalFormController<
  SelectOptionInterface
>()<GoogleLocationSelectProps>(
  LocationSelectorUi,
);

type Props = GoogleLocationSelectProps & {
  name: string;
  id?: string;
  config?: UseFieldConfig<SelectOptionInterface>;
};

export const GoogleSelectorWithDI: FC<Props> = React.memo((props) => {
  const Select = useDI(LocationSelector);

  return (
    <Select
      {...props}
    />
  );
});

export const GoogleLocationSelector: FC<Props> = React.memo((props) => (
  <NoSSR>
    <GoogleSelectorWithDI {...props} />
  </NoSSR>
));
