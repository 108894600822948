import { FC, useEffect } from 'react';

export const ScreenHeightCalculator: FC = () => {
  useEffect(() => {
    let vh = window.innerHeight * 0.01;

    document?.body?.style.setProperty('--vh', `${vh}px`);

    const onResize = () => {
      vh = window.innerHeight * 0.01;
      document?.body?.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return null;
};
