import React, { memo } from 'react';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { AuthUserPlatformWideSubscriptions } from '@/components/common/PlatformWideSubscriptions/AuthUserPlatformWideSubscriptions';

export const PlatformWideSubscriptions = memo(() => {
  const [authUser] = useAuthUser({ ssr: false });

  if (!authUser) {
    return null;
  }

  return <AuthUserPlatformWideSubscriptions authUser={authUser} />;
});
