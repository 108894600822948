import { useContext, useState } from 'react';
import { SubDomainContext } from '@/controllers/subDomain/subDomain.context';
import { useTranslation } from '@/middleware/i18n';
import { i18nParseLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { isSubscriptionProductDomain } from '@/controllers/subDomain/subDomain.utils/isSubscriptionProductDomain';
import { isKnowelyProduct } from '@/controllers/subDomain/subDomain.utils/isKnowelyProduct';
import { BrandNamesMap, Products } from '@/constants/products';

export const useSubDomainContext = () => {
  const contextData = useContext(SubDomainContext);

  const [productName] = useState(contextData.productName);
  const brandName = BrandNamesMap[productName ?? Products.Mate];

  const { i18n } = useTranslation();

  return {
    ...contextData,
    productName,
    language: i18nParseLanguageSubpath(i18n.language).language,
    isSubscriptionProductDomain: isSubscriptionProductDomain(
      contextData.subDomain,
    ),
    isKnowelyProduct: isKnowelyProduct(productName),
    brandName,
  };
};
