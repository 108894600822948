import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import getConfig from 'next/config';
import {
  changeLanguage,
  i18n,
  Router,
  useRouter,
  useTranslation,
} from '@/middleware/i18n';
import { LanguageName } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { DEFAULT_LOCALE, FALLBACK_LOCALE } from '@/middleware/i18n/i18n.config';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { ContentPreloader } from '@/components/common/ContentPreloader';
import { I18N_CODES } from '@/lib/constants/general';
import { i18nCreateLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import getClientLink from '@/lib/helpers/getClientLink';
import { i18nShouldRedirectAnonymous } from '@/middleware/i18n/i18n.utils/i18n.isHiddenDomain';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { analyticsSDK } from '@/controllers/analytics';
import { AuthUserDocument, useAuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { useUpdateUserDomainAndLanguageMutation } from '@/controllers/user/graphql/generated/updateUserDomainAndLanguage.mutation.generated';
import styles from './DomainRedirectHandler.module.scss';

const { publicRuntimeConfig = {} } = getConfig() || {};

export const DomainRedirectHandler = () => {
  const flashMessage = useFlashMessage();
  const features = useFeatures();

  const {
    data: authUserData,
    called: authUserQueryCalled,
    loading: authUserLoading,
  } = useAuthUserQuery({
    ssr: false,
  });

  const { subDomain, language, isKnowelyProduct } = useSubDomainContext();
  const { locales } = useRouter();

  const { t } = useTranslation([I18N_CODES.common]);

  const domainDefaultLanguage = useMemo(
    () => publicRuntimeConfig
      .DYNAMIC_I18N_CONFIG
      ?.domainLocaleMap
      ?.[subDomain]?.[0]
      || FALLBACK_LOCALE,
    [subDomain],
  );

  const [
    updateDomainAndLanguage,
    { loading },
  ] = useUpdateUserDomainAndLanguageMutation({
    async onCompleted() {
      analyticsSDK.landing.sendUserDomainChangedEvent({
        oldDomain: String(Router.query.from_domain),
        newDomain: subDomain,
        source: 'domain_auto_redirect',
      });

      await flashMessage.showMessage({
        type: MESSAGE_TYPES.success,
        heading: t(`${I18N_CODES.common}:region_changed_title`),
        text: t(`${I18N_CODES.common}:region_changed_description`, {
          region: subDomain,
        }),
      });
    },
    refetchQueries: [
      { query: AuthUserDocument },
    ],
    awaitRefetchQueries: true,
  });

  const updateLanguage = useCallback((options: {
    userLanguage: string;
    domainLanguage: string;
  }) => {
    const supportedLanguages = locales ?? [DEFAULT_LOCALE];

    const locale = i18nCreateLanguageSubpath({
      language: options.userLanguage,
      domain: subDomain,
    });

    if (supportedLanguages.includes(locale)) {
      changeLanguage({
        domain: subDomain,
        language: options.userLanguage,
      });
    } else {
      updateDomainAndLanguage({
        variables: {
          domainSlug: subDomain,
          languageName: options.domainLanguage as LanguageName,
        },
      });
    }
  }, [locales, subDomain, updateDomainAndLanguage]);

  const redirectToDomainUrl = useCallback(() => {
    if (typeof window === 'undefined') {
      return; // do nothing on server side
    }

    const authUser = authUserData?.authUser;

    const path = `${window.location.pathname}${window.location.search}`;

    const redirectUrl = getClientLink(path, {
      domain: authUser?.domain?.slug,
      language,
    });

    window.location.replace(redirectUrl);
  }, [authUserData?.authUser, language]);

  useEffect(() => {
    const shouldForceSubDomain = 'force_sub_domain' in Router.query;

    const authUser = authUserData?.authUser;

    // https://www.i18next.com/overview/api#changelanguage
    // For easy testing—setting lng to 'cimode' will cause the t function to always return the key.
    if ('cimode' in Router.query) {
      i18n.changeLanguage('cimode');

      return;
    }

    if (
      shouldForceSubDomain
      && authUser
      && authUser.domain?.slug !== subDomain
    ) {
      updateDomainAndLanguage({
        variables: {
          domainSlug: subDomain,
          languageName: domainDefaultLanguage,
        },
      });

      return;
    }

    const isUserLoaded = authUserQueryCalled && !authUserLoading;
    const isAnonymous = isUserLoaded && !authUser;
    const shouldRedirectAnonymous = (
      features.isEnabled(features.RedirectAnonymousFromHiddenDomains)
      && i18nShouldRedirectAnonymous(subDomain)
      && isAnonymous
    );

    if (shouldRedirectAnonymous) {
      redirectToDomainUrl();

      return;
    }

    if (
      authUser?.domain?.slug
      && subDomain
      && authUser.domain?.slug !== subDomain
      && !shouldForceSubDomain
      && !isKnowelyProduct
    ) {
      redirectToDomainUrl();

      return;
    }

    if (
      authUser?.language?.name
      && authUser.language.name !== language
      && !isKnowelyProduct
    ) {
      updateLanguage({
        userLanguage: authUser.language.name,
        domainLanguage: language,
      });
    }
  }, [
    features,
    authUserData?.authUser,
    authUserQueryCalled,
    authUserLoading,
    domainDefaultLanguage,
    subDomain,
    updateDomainAndLanguage,
    updateLanguage,
    language,
    redirectToDomainUrl,
    isKnowelyProduct,
  ]);

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <ContentPreloader text={t(`${I18N_CODES.common}:updating_region`)} />
      </div>
    );
  }

  return null;
};
