import { LogLevels } from '@mate-academy/logger';
import { HOUR, MINUTE, SECOND } from '@/constants';
import { logger } from '@/core/Logger';
import { errorHandler } from '@/core/ErrorHandler';
import { leadZero } from './leadZero';

export enum TimeFormats {
  hh = 'hh',
  hhmm = 'hh:mm',
  hhmmss = 'hh:mm:ss',
  mmss = 'mm:ss',
}

export const formatDuration = (
  durationInMs: number,
  format: TimeFormats,
) => {
  const displayTwoDigit = (integer: number) => (
    leadZero(integer, 2)
  );
  const seconds = displayTwoDigit(Math.floor((durationInMs / SECOND) % 60));
  const minutes = displayTwoDigit(Math.floor((durationInMs / MINUTE) % 60));
  const hours = displayTwoDigit(Math.floor(durationInMs / HOUR));

  switch (format) {
    case TimeFormats.hh:
      return hours;
    case TimeFormats.hhmm:
      return `${hours}:${minutes}`;
    case TimeFormats.mmss:
      return `${minutes}:${seconds}`;
    case TimeFormats.hhmmss:
      return `${hours}:${minutes}:${seconds}`;
    default: {
      errorHandler.captureMessage(
        `Time format ${format} is not supported`,
        {
          logger: logger.child(formatDuration.name),
          logLevel: LogLevels.Error,
        },
      );

      return `${hours}:${minutes}:${seconds}`;
    }
  }
};
