import React, { FC, useCallback } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Select } from '@/components/ui/FormElements/FormInputs/Select';
import {
  GoogleLocationResult,
} from '@/components/ui/FormElements/FormInputs/GoogleLocationSelector/GoogleLocationSelector';
import {
  googlePlaceMockData,
} from '@/components/ui/FormElements/FormInputs/GoogleSelectorMock/GoogleSelectorMockData';
import styles from './GoogleSelectorMock.module.scss';

export const GoogleLocationSelectorMock: FC<any> = React.memo((props) => {
  const changeHandler = useCallback((option, previous) => {
    const isValueExist = Boolean(option?.value);
    const isHandleChangeExist = Boolean(props?.handleChange);
    const isValueChanged = option?.value !== previous?.value;

    if (!isValueExist || !isHandleChangeExist || !isValueChanged) {
      return;
    }

    const selectedLocation = googlePlaceMockData.find((location) => (
      option.value === location.value
    ));

    if (!selectedLocation) {
      throw Error(`Location ${option.label}: ${option.value} wasn't added to mock data`);
    }

    const place: GoogleLocationResult = {
      placeName: selectedLocation.placeName,
      placeId: selectedLocation.placeId,
      placeType: selectedLocation.placeType,
    };

    props.handleChange(place);
  }, [props]);

  return (
    <>
      <Select
        {...props}
        options={googlePlaceMockData}
        className={styles.optionsPositionTop}
      />
      <OnChange name={props.name}>
        {changeHandler}
      </OnChange>
    </>
  );
});
