import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from './IconLogoSquare.module.scss';

export const IconLogoSquareAnniversary: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={cn(className, styles.logoIcon)}
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="mate-logo-square-fill">
      <path d="M24.168 22.0164C24.168 23.2751 25.0088 24.069 26.1554 24.069C27.302 24.069 28.1428 23.2751 28.1428 22.0164C28.1428 20.7578 27.302 19.9639 26.1554 19.9639C25.0088 19.9639 24.168 20.7578 24.168 22.0164Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.0093 0.300003C35.6338 0.300003 39.3889 4.09529 39.3889 8.78131V31.4078C39.3889 36.0938 35.6434 39.8891 31.0093 39.8891H8.67964C4.0551 39.8891 0.300049 36.0938 0.300049 31.4078V8.78131C0.300049 4.09529 4.04554 0.300003 8.67964 0.300003H31.0093ZM18.0625 14.4452H20.7378V26.228H18.0625V19.3733L15.0336 24.3788H14.7374L11.7085 19.383V26.2377H9.03316V14.4452H11.7085L14.8903 19.6831L18.0625 14.4452ZM28.1524 18.5987V17.8048H30.6653V26.228H28.1524V25.4341C27.5982 26.0731 26.7765 26.4604 25.6586 26.4604C23.4705 26.4604 21.6551 24.524 21.6551 22.0164C21.6551 19.5088 23.461 17.5724 25.6586 17.5724C26.786 17.5724 27.5982 17.9597 28.1524 18.5987Z" />
    </g>

    <g id="mate-logo-square-text">
      <path d="M20.7379 14.4452H18.0626L14.8808 19.6831L11.699 14.4452H9.02368V26.228H11.699V19.3733L14.7279 24.3691H15.0241L18.053 19.3733V26.228H20.7284L20.7379 14.4452Z" />
      <path d="M28.1523 17.8048V18.5987C27.5981 17.9597 26.7764 17.5724 25.6585 17.5724C23.4704 17.5724 21.655 19.5088 21.655 22.0164C21.655 24.524 23.4609 26.4604 25.6585 26.4604C26.7764 26.4604 27.5981 26.0731 28.1523 25.4341V26.228H30.6652V17.8048H28.1523ZM26.1649 24.069C25.0088 24.069 24.1775 23.2751 24.1775 22.0164C24.1775 20.7578 25.0183 19.9639 26.1649 19.9639C27.3115 19.9639 28.1523 20.7578 28.1523 22.0164C28.1523 23.2751 27.321 24.069 26.1649 24.069Z" />
    </g>

    <g id='mate-logo-square-title'>
      <path d="M27.0727 42.7937C27.0631 42.968 27.0631 43.1519 27.0727 43.3262C27.0727 43.5005 27.0822 43.6651 27.0345 43.8296C26.958 44.0523 26.7669 44.2266 26.5472 44.2847C26.3179 44.3331 26.0694 44.2653 25.907 44.1007C25.7255 43.9168 25.6681 43.6457 25.6395 43.3843C25.563 42.6775 25.649 41.961 25.7732 41.264C25.9357 40.3345 26.165 39.4244 26.4421 38.524C26.3752 38.4756 26.2796 38.5046 26.2128 38.553C26.1459 38.6014 26.0981 38.6789 26.0312 38.737C25.8019 38.9306 25.4579 38.8338 25.1999 38.6886C24.9993 38.5724 24.7986 38.3981 24.8082 38.1658C24.8177 38.0012 24.9324 37.8656 25.0471 37.7494C25.5248 37.2363 26.0121 36.7232 26.4898 36.2197C26.9294 35.755 28.4008 33.9057 29.1365 34.7093C29.3276 34.9223 29.3754 35.2322 29.3181 35.5129C29.2607 35.7937 29.127 36.0454 29.0028 36.3068C27.9708 38.3207 27.1396 40.5281 27.0727 42.7937Z" />
      <path d="M33.5604 39.6955C33.3024 40.5572 32.9297 41.3801 32.4711 42.145C32.2322 42.542 31.9647 42.9292 31.5921 43.21C31.2194 43.4908 30.7417 43.6457 30.3022 43.5295C29.9582 43.4424 29.6715 43.2003 29.4518 42.9196C28.8498 42.145 28.7543 41.08 28.7925 40.0924C28.8307 39.1146 28.974 38.1367 29.2129 37.1879C29.3562 36.6263 29.5378 36.0745 29.834 35.5807C30.1397 35.0676 30.5697 34.9417 31.1143 34.8158C31.6589 34.69 32.2418 34.6416 32.7673 34.8933C33.2928 35.145 33.6655 35.6678 33.8279 36.2391C33.9999 36.8103 33.9808 37.4106 33.9139 38.0012C33.847 38.5821 33.7228 39.1436 33.5604 39.6955ZM30.8468 41.8642C30.8754 41.8449 30.9041 41.8158 30.9328 41.7965C31.4487 41.2059 31.7449 40.4604 32.0029 39.7149C32.3087 38.8532 32.5762 37.9528 32.4998 37.0427C32.4807 36.8781 32.4616 36.7038 32.3851 36.5586C32.3182 36.4037 32.194 36.2681 32.0411 36.21C31.5634 36.0261 31.057 36.4521 30.8563 36.849C30.713 37.1201 30.6461 37.4203 30.5792 37.7204C30.3308 38.9016 30.1493 40.1215 30.3022 41.3124C30.3213 41.4479 30.3499 41.5931 30.4455 41.6996C30.5124 41.7674 30.5984 41.8061 30.6939 41.8449C30.7321 41.8642 30.7799 41.8836 30.8277 41.8739C30.8277 41.8739 30.8372 41.8642 30.8468 41.8642Z" />
      <path d="M38.6436 36.7135C38.6245 36.8781 38.5481 37.033 38.4143 37.1201C38.1754 37.275 37.8315 37.1685 37.6021 37.3428C37.4397 37.4687 37.3919 37.6817 37.3537 37.885C37.2486 38.4465 37.1435 39.0178 37.048 39.5793C36.876 40.5862 36.7231 41.6222 36.9333 42.6194C36.9715 42.8227 37.0289 43.0357 36.9715 43.2294C36.8855 43.5295 36.5225 43.6941 36.2167 43.636C35.9109 43.5682 35.6721 43.3165 35.5383 43.0261C35.4045 42.7356 35.3759 42.4161 35.3663 42.0966C35.2994 40.625 35.5765 39.1533 35.8441 37.701C35.4619 37.7882 35.0797 37.9334 34.6879 37.914C34.2962 37.885 33.8758 37.6139 33.8662 37.2169C33.8567 36.8878 34.1337 36.6167 34.4299 36.4908C34.7261 36.3649 35.0606 36.3553 35.3854 36.2972C35.7007 36.2391 36.0352 36.1132 36.2167 35.8421C36.3027 35.7162 36.3409 35.571 36.3887 35.4258C36.7613 34.1865 37.1244 32.9376 37.497 31.6983C37.583 31.4078 37.6977 31.0786 37.9939 30.9431C38.2901 30.8075 38.6532 30.856 38.8729 31.1174C39.0736 31.3594 39.1118 31.708 39.064 32.0178C39.0067 32.3276 38.8729 32.6181 38.7487 32.9182C38.4334 33.6637 38.2137 34.4382 38.013 35.2225C37.9557 35.4355 37.9079 35.6582 37.9939 35.8615C38.0799 36.0648 38.4239 36.1229 38.5767 35.9777C38.8061 35.8615 38.6627 36.4133 38.6627 36.607C38.6532 36.636 38.6532 36.6748 38.6436 36.7135Z" />
      <path d="M41.5005 32.3663C41.6916 32.3663 41.854 32.5019 41.9782 32.8698C42.112 33.2861 41.9209 33.7315 41.7489 34.1284C41.147 35.5129 40.7457 36.9749 40.5355 38.4659C41.1278 37.8559 41.8445 37.3718 42.628 37.062C42.9433 36.9362 43.2968 36.8393 43.6121 36.9652C44.1854 37.1879 44.2809 37.9528 44.2809 38.5821C44.2905 39.3566 44.2905 40.1409 44.3001 40.9154C44.3001 41.1768 44.3001 41.4576 44.1376 41.6609C43.8414 42.0385 43.1917 41.8836 42.9337 41.477C42.6757 41.08 42.6948 40.5572 42.7522 40.0828C42.8095 39.6084 42.905 39.1146 42.7426 38.6595C41.8827 39.1824 41.1852 39.9569 40.7552 40.8767C40.5832 41.2349 40.4495 41.6222 40.1915 41.9223C39.9335 42.2225 39.494 42.4161 39.1404 42.2418C38.6627 42.0191 38.6245 41.3608 38.6723 40.8283C38.8825 38.4078 39.4175 36.0067 40.2584 33.7218C40.3826 33.4023 40.9941 32.376 41.5005 32.3663Z" />
    </g>
  </BaseIcon>
);
