import styles from './colors.variables.module.scss';

/**
 * @description
 * This file is used to export SCSS colors variables as a JS object.
 *
 * Check the [colors.variables.module.scss](colors.variables.module.scss)
 * file to see the list of available colors.
 */

/**
 * @deprecated
 * use colorTokens instead
 * frontend/src/components/ui/colorTokens/index.ts
 */

export const colorsDeprecated = styles;
