import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { IconKnowelyLogoSquare } from '@/components/ui/icons/IconKnowelyLogoSquare';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import styles from './KnowelyLogo.module.scss';

type Props = {
  className?: string;
  withHoverEffect?: boolean;
  size?: IconSize;
};

export const KnowelyLogoSquare: FC<Props> = memo(({
  className,
  withHoverEffect = false,
  ...rest
}) => (
  <IconKnowelyLogoSquare
    {...rest}
    className={cn(className, {
      [styles.withHoverEffect]: withHoverEffect,
    })}
  />
));
