import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from './IconLogoFull.module.scss';

export const IconLogoFullAnniversary: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="148"
    height="49"
    viewBox="0 0 148 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mate-logo-full-title">
      <path d="M63.1948 6.68173H60.858L57.5727 12.1464L54.2673 6.68173H51.9306V18.245H54.1974V10.7676L57.4329 16.1215H57.6925L60.9279 10.7576V18.2551H63.1748L63.1948 6.68173Z" />
      <path d="M71.1536 9.9927V10.9689C70.5644 10.2242 69.6757 9.76123 68.4773 9.76123C66.2904 9.76123 64.483 11.6633 64.483 14.1188C64.483 16.5744 66.2904 18.4765 68.4773 18.4765C69.6757 18.4765 70.5644 18.0135 71.1536 17.2688V18.245H73.2706V9.98263L71.1536 9.9927ZM68.8668 16.4436C67.5686 16.4436 66.6 15.4976 66.6 14.1188C66.6 12.7401 67.5686 11.7941 68.8668 11.7941C70.165 11.7941 71.1536 12.7401 71.1536 14.1188C71.1536 15.4976 70.1849 16.4436 68.8668 16.4436Z" />
      <path d="M79.5718 12.0356V9.98264H77.7144V7.66797L75.5973 8.31205V9.98264H74.1693V12.0356H75.5973V15.4674C75.5973 17.6915 76.5959 18.5771 79.5718 18.245V16.3127C78.3535 16.3832 77.7144 16.3631 77.7144 15.4674V12.0356H79.5718Z" />
      <path d="M82.268 14.9944H88.4593C88.5092 14.7126 88.5392 14.4308 88.5392 14.1188C88.5392 11.6935 86.8116 9.76123 84.385 9.76123C81.8086 9.76123 80.0411 11.6633 80.0411 14.1188C80.0411 16.5744 81.7886 18.4765 84.5448 18.4765C86.1226 18.4765 87.3608 17.8324 88.1297 16.7052L86.4221 15.7089C86.0627 16.192 85.4036 16.5341 84.5847 16.5341C83.4663 16.5442 82.5676 16.0813 82.268 14.9944ZM82.238 13.3439C82.4877 12.2872 83.2566 11.6733 84.395 11.6733C85.2837 11.6733 86.1725 12.1564 86.4321 13.3439H82.238Z" />
      <path d="M58.0819 25.984V26.9602C57.4928 26.2155 56.604 25.7526 55.4057 25.7526C53.2188 25.7526 51.4113 27.6546 51.4113 30.1102C51.4113 32.5657 53.2188 34.4678 55.4057 34.4678C56.604 34.4678 57.4928 34.0049 58.0819 33.2601V34.2363H60.199V25.984H58.0819ZM55.7952 32.445C54.497 32.445 53.5283 31.499 53.5283 30.1202C53.5283 28.7415 54.497 27.7955 55.7952 27.7955C57.0933 27.7955 58.0819 28.7415 58.0819 30.1202C58.0819 31.499 57.1133 32.445 55.7952 32.445Z" />
      <path d="M65.7012 34.4779C67.309 34.4779 68.707 33.6224 69.416 32.3343L67.5786 31.2776C67.2491 31.9518 66.5401 32.3645 65.6912 32.3645C64.423 32.3645 63.4943 31.4185 63.4943 30.1202C63.4943 28.822 64.433 27.8559 65.6912 27.8559C66.5301 27.8559 67.2391 28.2886 67.5686 28.9629L69.396 27.8861C68.717 26.618 67.329 25.7526 65.7112 25.7526C63.2147 25.7526 61.3673 27.6546 61.3673 30.1102C61.3673 32.5657 63.2047 34.4779 65.7012 34.4779Z" />
      <path d="M76.3263 25.984V26.9602C75.7371 26.2155 74.8484 25.7526 73.6501 25.7526C71.4631 25.7526 69.6557 27.6546 69.6557 30.1102C69.6557 32.5657 71.4631 34.4678 73.6501 34.4678C74.8484 34.4678 75.7371 34.0049 76.3263 33.2601V34.2363H78.4433V25.984H76.3263ZM74.0395 32.445C72.7414 32.445 71.7727 31.499 71.7727 30.1202C71.7727 28.7415 72.7414 27.7955 74.0395 27.7955C75.3377 27.7955 76.3263 28.7415 76.3263 30.1202C76.3263 31.499 75.3577 32.445 74.0395 32.445Z" />
      <path d="M86.2724 22.6831V26.9602C85.6832 26.2055 84.8044 25.7526 83.6061 25.7526C81.4092 25.7526 79.5917 27.6546 79.5917 30.1102C79.5917 32.5658 81.3992 34.4678 83.6061 34.4678C84.8044 34.4678 85.6732 34.025 86.2724 33.2602V34.2363H88.3894V22.6831H86.2724ZM84.0055 32.445C82.6874 32.445 81.7187 31.499 81.7187 30.1203C81.7187 28.7415 82.6874 27.7955 84.0055 27.7955C85.3237 27.7955 86.2724 28.7415 86.2724 30.1203C86.2724 31.499 85.3037 32.445 84.0055 32.445Z" />
      <path d="M91.7946 30.9958H97.9959C98.0458 30.714 98.0758 30.4322 98.0758 30.1202C98.0758 27.6949 96.3482 25.7626 93.9216 25.7626C91.3452 25.7626 89.5777 27.6647 89.5777 30.1202C89.5777 32.5758 91.3252 34.4779 94.0814 34.4779C95.6592 34.4779 96.8974 33.8338 97.6663 32.7066L95.9587 31.7103C95.5992 32.1934 94.9402 32.5356 94.1213 32.5356C92.9929 32.5456 92.0942 32.0827 91.7946 30.9958ZM91.7646 29.3353C92.0143 28.2786 92.7832 27.6647 93.9216 27.6647C94.8103 27.6647 95.6991 28.1477 95.9587 29.3353H91.7646Z" />
      <path d="M108.311 25.7526C107.193 25.7526 106.374 26.1954 105.865 26.9401C105.405 26.1853 104.647 25.7526 103.628 25.7526C102.609 25.7526 101.801 26.1652 101.341 26.8596V25.984H99.2242V34.2464H101.341V29.607C101.341 28.3691 101.98 27.7452 102.889 27.7452C103.798 27.7452 104.267 28.339 104.267 29.3151V34.2565H106.384V29.607C106.384 28.3691 106.973 27.7452 107.912 27.7452C108.801 27.7452 109.29 28.339 109.29 29.3151V34.2565H111.407V29.1742C111.417 27.091 110.189 25.7526 108.311 25.7526Z" />
      <path d="M118.078 25.984L116.25 31.5292L114.113 25.984H111.797L115.162 33.9747C114.772 35.0817 114.173 35.5748 113.005 35.5648V37.5473C115.092 37.658 116.49 36.6214 117.339 34.2464L120.335 25.9941L118.078 25.984Z" />
    </g>
    <g id="mate-logo-full-fill">
      <path d="M25.4578 23.2568C25.4578 24.5651 26.3366 25.3903 27.5349 25.3903C28.7332 25.3903 29.612 24.5651 29.612 23.2568C29.612 21.9485 28.7332 21.1233 27.5349 21.1233C26.3366 21.1233 25.4578 21.9485 25.4578 23.2568Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.6077 0.683716C37.4409 0.683716 41.3654 4.62872 41.3654 9.49958V33.0186C41.3654 37.8895 37.4509 41.8345 32.6077 41.8345H9.26054C4.42732 41.8345 0.502838 37.8895 0.502838 33.0186V9.49958C0.502838 4.62872 4.41734 0.683716 9.26054 0.683716H32.6077ZM19.0668 15.3869H21.8628V27.6345H19.0668V20.5093L15.9012 25.7023H15.5916L12.4261 20.5093V27.6345H9.63002V15.3869H12.4261L15.7514 20.8314L19.0668 15.3869ZM29.6219 19.7042V18.879H32.2482V27.6345H29.6219V26.8093C29.0427 27.4735 28.184 27.876 27.0156 27.876C24.7288 27.876 22.8315 25.8633 22.8315 23.2568C22.8315 20.6502 24.7188 18.6375 27.0156 18.6375C28.184 18.6375 29.0427 19.04 29.6219 19.7042Z" />
    </g>
    <g id="mate-logo-full-text">
      <path d="M21.8728 15.3869H19.0768L15.7514 20.8314L12.4261 15.3869H9.63002V27.6345H12.4261V20.5094L15.5916 25.7023H15.9012L19.0668 20.5094V27.6345H21.8628L21.8728 15.3869Z" />
      <path d="M29.6219 18.879V19.7043C29.0428 19.0401 28.184 18.6375 27.0156 18.6375C24.7288 18.6375 22.8315 20.6503 22.8315 23.2568C22.8315 25.8633 24.7188 27.8761 27.0156 27.8761C28.184 27.8761 29.0428 27.4735 29.6219 26.8093V27.6345H32.2483V18.879H29.6219ZM27.5349 25.3903C26.3266 25.3903 25.4578 24.5651 25.4578 23.2568C25.4578 21.9485 26.3366 21.1233 27.5349 21.1233C28.7332 21.1233 29.612 21.9485 29.612 23.2568C29.612 24.5651 28.7432 25.3903 27.5349 25.3903Z" />
    </g>
    <g id="mate-logo-full-fill">
      <path d="M137.341 38.5839C137.031 39.3991 136.851 40.2645 136.672 41.12C136.592 41.4923 136.502 41.8949 136.212 42.1465C136.022 42.3075 135.763 42.3779 135.523 42.3175C135.283 42.2572 135.074 42.0861 134.994 41.8446C134.934 41.6634 134.944 41.4722 134.964 41.281C135.134 39.3387 135.653 37.4366 135.823 35.4943C135.843 35.3031 135.853 35.1018 135.923 34.9307C136.092 34.5081 136.692 34.3873 137.091 34.5282C137.331 34.6188 137.51 34.9207 137.381 35.1421C137.75 35.0112 138.03 34.7093 138.359 34.4879C138.619 34.3068 138.928 34.176 139.248 34.1659C139.567 34.1558 139.897 34.2766 140.097 34.5282C140.396 34.9207 140.316 35.4842 140.536 35.9271C140.646 36.1485 140.836 36.3397 140.866 36.5913C140.916 36.9334 140.616 37.2655 140.276 37.3259C139.937 37.3863 139.587 37.2152 139.358 36.9536C139.108 36.6818 138.978 36.3296 138.769 36.0176C138.549 36.5208 138.209 36.9435 137.93 37.4064C137.67 37.7788 137.49 38.1713 137.341 38.5839Z" />
      <path d="M134.784 35.7862L134.834 35.8164C133.806 37.2253 133.396 39.077 133.736 40.7979C133.776 41.0193 133.836 41.2407 133.816 41.4621C133.796 41.6835 133.706 41.915 133.516 42.0358C133.266 42.1867 132.947 42.0961 132.707 41.9452C132.348 41.7137 132.098 41.3212 132.038 40.8986C131.978 40.5161 132.048 40.0935 131.858 39.7614C131.279 40.3249 130.87 41.0495 130.271 41.603C129.671 42.1565 128.773 42.4987 128.054 42.1163C127.534 41.8446 127.255 41.2609 127.135 40.6872C126.925 39.6909 127.095 38.6342 127.445 37.6782C127.894 36.4504 128.683 35.3031 129.781 34.5886C130.35 34.2263 130.86 34.0854 131.509 34.3571C132.098 34.5986 132.577 35.122 132.727 35.7459C133.047 35.7258 133.107 35.1823 133.416 35.1421C133.526 35.132 133.626 35.1823 133.726 35.2327C134.085 35.4037 134.435 35.5949 134.784 35.7862ZM129.082 40.345C129.172 40.3551 129.262 40.3249 129.332 40.2746C129.452 40.1941 129.532 40.0733 129.621 39.9526C130.121 39.2582 130.76 38.6644 131.139 37.8996C131.379 37.4165 131.509 36.8227 131.249 36.3497C131.129 36.1384 130.91 35.9572 130.67 35.9673C130.44 35.9874 130.261 36.1585 130.121 36.3397C129.661 36.8932 129.282 37.5171 129.042 38.2015C128.922 38.5436 128.843 38.8959 128.803 39.2582C128.773 39.5399 128.683 40.0532 128.942 40.2645C128.972 40.3149 129.032 40.335 129.082 40.345Z" />
      <path d="M51.9805 47.118C51.9705 47.2991 51.9705 47.4903 51.9805 47.6715C51.9805 47.8526 51.9905 48.0237 51.9406 48.1948C51.8607 48.4263 51.661 48.6074 51.4313 48.6678C51.2016 48.7181 50.932 48.6477 50.7722 48.4766C50.5825 48.2854 50.5226 48.0036 50.4926 47.7319C50.4127 46.9972 50.5026 46.2525 50.6324 45.5279C50.8022 44.5718 51.0319 43.6158 51.3314 42.6899C51.2615 42.6396 51.1617 42.6698 51.0918 42.7201C51.0219 42.7704 50.9719 42.8509 50.902 42.9013C50.6624 43.1025 50.3029 43.0019 50.0432 42.8509C49.8335 42.7302 49.6238 42.549 49.6338 42.3075C49.6438 42.1364 49.7636 42.0056 49.8835 41.8748C50.3828 41.3414 50.8921 40.8181 51.3914 40.2847C51.8507 39.8016 53.3886 37.8895 54.1575 38.7248C54.3572 38.9462 54.4071 39.2682 54.3472 39.5601C54.2873 39.8519 54.1475 40.1136 54.0177 40.3753C52.9192 42.4786 52.0504 44.7631 51.9805 47.118Z" />
      <path d="M58.741 43.9076C58.4714 44.7932 58.082 45.6487 57.6026 46.4437C57.353 46.8563 57.0734 47.2589 56.6939 47.5507C56.3144 47.8426 55.8151 48.0036 55.3458 47.8828C54.9863 47.7923 54.6867 47.5407 54.457 47.2488C53.8279 46.4437 53.7281 45.3468 53.768 44.3202C53.808 43.3038 53.9478 42.2874 54.2074 41.3112C54.3572 40.7275 54.5469 40.1538 54.8565 39.6507C55.176 39.1173 55.6154 38.9864 56.1946 38.8556C56.7638 38.7248 57.373 38.6745 57.9122 38.9361C58.4614 39.1978 58.8509 39.7412 59.0206 40.335C59.1904 40.9288 59.1804 41.5527 59.1105 42.1666C59.0406 42.7604 58.9108 43.3441 58.741 43.9076ZM55.9949 46.0713C56.0648 46.021 56.1147 45.9506 56.1746 45.8801C56.624 45.2763 56.8936 44.5718 57.1433 43.8674C57.4429 43.012 57.7125 42.1062 57.6426 41.2005C57.6326 41.0294 57.6026 40.8482 57.5327 40.6872C57.4628 40.5262 57.343 40.3853 57.1832 40.3048C57.0035 40.2243 56.7838 40.2444 56.594 40.3149C56.2845 40.4457 56.0448 40.7174 55.895 41.0294C55.7452 41.3313 55.6653 41.6735 55.6054 42.0056C55.3758 43.1428 55.206 44.3102 55.3358 45.4675C55.3558 45.6386 55.3857 45.8097 55.4756 45.9405C55.5655 46.0613 55.6953 46.1317 55.8451 46.1217C55.905 46.1217 55.9549 46.1015 55.9949 46.0713Z" />
      <path d="M64.0436 40.8181C64.0236 40.9891 63.9437 41.1502 63.8039 41.2407C63.5543 41.4018 63.1948 41.2911 62.9551 41.4722C62.7854 41.593 62.7354 41.8244 62.6955 42.0358C62.5856 42.6195 62.4758 43.2032 62.3759 43.7969C62.1962 44.8436 62.0364 45.9103 62.2561 46.9469C62.296 47.1582 62.356 47.3796 62.296 47.5809C62.1962 47.8929 61.8267 48.064 61.5072 47.9935C61.1876 47.9231 60.938 47.6614 60.8081 47.3595C60.6783 47.0576 60.6384 46.7255 60.6284 46.4035C60.5585 44.8738 60.8481 43.3541 61.1277 41.8446C60.7282 41.9351 60.3288 42.0861 59.9294 42.066C59.5199 42.0358 59.0905 41.754 59.0806 41.3414C59.0706 40.9992 59.3602 40.7174 59.6697 40.5866C59.9793 40.4558 60.3288 40.4457 60.6583 40.3853C60.9879 40.3249 61.3374 40.1941 61.5271 39.9224C61.617 39.7916 61.6569 39.6406 61.7069 39.4896C62.0963 38.2015 62.4758 36.9133 62.8652 35.6252C62.9551 35.3232 63.0749 34.9811 63.3745 34.8402C63.6841 34.6993 64.0636 34.7496 64.2833 35.0213C64.493 35.2729 64.5329 35.6352 64.473 35.9573C64.4131 36.2793 64.2733 36.5812 64.1434 36.8831C63.8139 37.648 63.5842 38.4631 63.3845 39.2682C63.3346 39.4896 63.2747 39.7211 63.3645 39.9224C63.4544 40.1237 63.8139 40.1941 63.9637 40.0431C64.1934 39.9224 64.0536 40.486 64.0536 40.6973C64.0436 40.7375 64.0436 40.7778 64.0436 40.8181Z" />
      <path d="M67.0094 36.3095C67.1991 36.3095 67.3789 36.4504 67.5087 36.8328C67.6485 37.2656 67.4488 37.7285 67.269 38.1411C66.6499 39.5702 66.2205 41.0898 66.0008 42.6396C66.61 42.0056 67.3589 41.5024 68.1778 41.1804C68.5073 41.0495 68.8768 40.9489 69.2063 41.0797C69.8055 41.3112 69.9053 42.1062 69.9053 42.7503C69.9053 43.5554 69.9153 44.3605 69.9253 45.1757C69.9253 45.4474 69.9253 45.7392 69.7556 45.9506C69.456 46.3431 68.7769 46.1821 68.4973 45.7694C68.2177 45.3568 68.2477 44.8134 68.3076 44.3203C68.3675 43.8271 68.4574 43.3139 68.2976 42.8409C67.3989 43.3843 66.6699 44.1894 66.2205 45.1354C66.0408 45.5078 65.901 45.9103 65.6313 46.2223C65.3617 46.5343 64.9024 46.7356 64.5329 46.5544C64.0336 46.3229 64.0036 45.6386 64.0436 45.0952C64.2633 42.5792 64.8225 40.1035 65.7013 37.7386C65.851 37.3763 66.4901 36.3196 67.0094 36.3095Z" />
      <path d="M81.4192 39.4091L81.4691 39.4393C80.4406 40.8483 80.0311 42.7 80.3707 44.4209C80.4106 44.6423 80.4705 44.8637 80.4505 45.0851C80.4306 45.3065 80.3407 45.538 80.151 45.6587C79.9013 45.8097 79.5818 45.7191 79.3421 45.5682C78.9826 45.3367 78.733 44.9442 78.673 44.5215C78.6131 44.1391 78.683 43.7164 78.4933 43.3843C77.9141 43.9479 77.5047 44.6725 76.9055 45.226C76.3064 45.7795 75.4076 46.1217 74.6886 45.7392C74.1694 45.4675 73.8898 44.8838 73.7699 44.3102C73.5602 43.3139 73.73 42.2572 74.0795 41.3011C74.5289 40.0733 75.3178 38.9261 76.4162 38.2115C76.9854 37.8492 77.4947 37.7084 78.1438 37.9801C78.733 38.2216 79.2123 38.7449 79.3621 39.3689C79.6816 39.3487 79.7415 38.8053 80.0511 38.765C80.1609 38.755 80.2608 38.8053 80.3607 38.8556C80.7202 39.0267 81.0697 39.2179 81.4192 39.4091ZM75.7172 43.968C75.8071 43.9781 75.8969 43.9479 75.9668 43.8976C76.0867 43.8171 76.1666 43.6963 76.2564 43.5755C76.7557 42.8811 77.3948 42.2874 77.7743 41.5225C78.014 41.0395 78.1438 40.4457 77.8841 39.9727C77.7643 39.7614 77.5446 39.5802 77.305 39.5903C77.0753 39.6104 76.8955 39.7815 76.7557 39.9626C76.2964 40.5161 75.9169 41.1401 75.6772 41.8244C75.5574 42.1666 75.4775 42.5188 75.4376 42.8811C75.4076 43.1629 75.3177 43.6762 75.5774 43.8875C75.6073 43.9479 75.6573 43.968 75.7172 43.968Z" />
      <path d="M86.2524 39.7412C84.6546 41.0797 83.3764 42.8006 82.5476 44.7228C82.4477 44.9442 82.3579 45.1757 82.2081 45.3669C82.0583 45.5581 81.8186 45.699 81.5789 45.6889C81.3193 45.6386 81.0697 45.5078 80.8699 45.3367C80.83 45.2964 80.7901 45.2562 80.7701 45.2059C80.7301 45.1153 80.7501 45.0147 80.7801 44.914C80.9898 44.0284 81.2095 43.1428 81.4192 42.2672C81.7287 40.9992 82.0283 39.7312 82.2081 38.4329C82.238 38.2317 82.258 38.0304 82.3678 37.8593C82.5576 37.5473 83.2566 37.4064 83.5162 37.6983C83.666 37.8694 83.7159 38.121 83.7359 38.3524C83.7858 38.9563 83.7159 39.5601 83.5362 40.1337C83.7159 40.184 83.8757 40.023 83.9955 39.8721C84.8843 38.7952 85.9828 37.8794 87.271 37.3561C87.5805 37.2354 87.91 37.1347 88.2396 37.1448C88.5691 37.1548 88.9186 37.3159 89.0884 37.5977C89.368 38.0707 89.1184 38.6644 88.8687 39.1475C88.7489 39.3789 88.629 39.6205 88.4992 39.8519C87.8501 41.0998 87.1911 42.388 86.9913 43.7869C86.9814 43.8774 86.9714 43.968 87.0113 44.0485C87.0513 44.129 87.1311 44.1693 87.1911 44.2297C87.4807 44.4813 87.4607 44.9744 87.201 45.2461C86.9414 45.5178 86.492 45.5581 86.1525 45.3971C85.813 45.2361 85.5733 44.8939 85.4635 44.5316C85.3536 44.1693 85.3636 43.7768 85.4136 43.3944C85.6233 41.8949 86.512 40.5967 86.9913 39.1676C86.7317 39.3588 86.492 39.55 86.2524 39.7412Z" />
      <path d="M94.3011 39.228C92.7033 40.5665 91.4251 42.2874 90.5963 44.2096C90.4964 44.431 90.4066 44.6624 90.2568 44.8536C90.107 45.0449 89.8673 45.1857 89.6276 45.1757C89.368 45.1254 89.1184 44.9945 88.9186 44.8234C88.8787 44.7832 88.8388 44.7429 88.8188 44.6926C88.7788 44.602 88.7988 44.5014 88.8288 44.4008C89.0385 43.5152 89.2582 42.6295 89.4679 41.754C89.7774 40.486 90.077 39.2179 90.2568 37.9197C90.2867 37.7184 90.3067 37.5171 90.4165 37.3461C90.6063 37.0341 91.3053 36.8932 91.5649 37.185C91.7147 37.3561 91.7646 37.6077 91.7846 37.8392C91.8345 38.443 91.7646 39.0468 91.5849 39.6205C91.7646 39.6708 91.9244 39.5098 92.0443 39.3588C92.933 38.282 94.0315 37.3662 95.3197 36.8429C95.6292 36.7221 95.9587 36.6215 96.2883 36.6315C96.6178 36.6416 96.9673 36.8026 97.1371 37.0844C97.4167 37.5574 97.1671 38.1512 96.9174 38.6342C96.7976 38.8657 96.6777 39.1072 96.5479 39.3387C95.8988 40.5866 95.2398 41.8748 95.04 43.2736C95.0301 43.3642 95.0201 43.4548 95.06 43.5353C95.1 43.6158 95.1798 43.656 95.2398 43.7164C95.5294 43.968 95.5094 44.4612 95.2498 44.7329C94.9801 45.0046 94.5408 45.0449 94.2012 44.8838C93.8617 44.7228 93.622 44.3806 93.5122 44.0183C93.4023 43.656 93.4123 43.2636 93.4623 42.8811C93.672 41.3816 94.5607 40.0834 95.04 38.6544C94.7804 38.8456 94.5408 39.0267 94.3011 39.228Z" />
      <path d="M99.9232 37.9298C100.033 38.0908 100.133 38.2619 100.163 38.443C100.203 38.6845 100.123 38.916 100.053 39.1475C99.5936 40.647 99.324 42.2069 99.2641 43.7667C99.2541 43.9278 99.2441 44.1089 99.1143 44.1894C99.0544 44.2297 98.9845 44.2397 98.9146 44.2498C98.7848 44.2699 98.655 44.28 98.5251 44.28C98.4153 44.28 98.3055 44.28 98.2156 44.2397C98.0858 44.1794 97.9959 44.0687 97.926 43.9378C97.6564 43.485 97.5865 42.9315 97.6164 42.4081C97.6464 41.8848 97.7762 41.3615 97.896 40.8483C98.1257 39.8419 98.2955 38.8154 98.7348 37.8794C98.8946 37.5473 99.2441 37.0039 99.6036 37.4467C99.7135 37.5876 99.8233 37.7386 99.9332 37.8895C99.9232 37.9197 99.9232 37.9197 99.9232 37.9298ZM99.0444 35.5748C99.0644 35.5145 99.0844 35.4541 99.1243 35.3937C99.344 34.9911 99.9731 34.8402 100.273 35.2427C100.403 35.4138 100.432 35.6453 100.403 35.8566C100.373 36.0881 100.283 36.3095 100.183 36.5108C100.163 36.5611 100.123 36.6114 100.063 36.6315C100.023 36.6416 99.9831 36.6315 99.9432 36.6315C99.6636 36.5812 99.3839 36.4705 99.1443 36.3196C99.0944 36.2894 99.0444 36.2592 99.0145 36.2089C98.9645 36.1384 98.9645 36.0478 98.9645 35.9673C98.9845 35.8466 99.0045 35.7057 99.0444 35.5748Z" />
      <path d="M105.795 34.3471C105.845 34.337 105.905 34.3269 105.965 34.337C106.284 34.3672 106.684 34.6288 106.953 34.7899C107.003 34.8201 107.063 34.8603 107.083 34.9207C107.103 34.971 107.093 35.0213 107.083 35.0616C107.013 35.3333 106.853 35.5748 106.694 35.8164C106.015 36.863 105.425 37.9801 104.936 39.1273C104.457 40.2746 104.077 41.4521 103.738 42.6497C103.628 43.022 103.528 43.4045 103.298 43.7164C103.069 44.0284 102.719 44.28 102.33 44.2498C101.97 44.2196 101.661 43.9479 101.481 43.6259C101.311 43.3038 101.251 42.9315 101.201 42.5692C100.952 40.7376 100.882 38.8758 101.002 37.0341C101.012 36.9334 101.012 36.8227 101.062 36.7322C101.112 36.6416 101.191 36.5812 101.281 36.5309C101.581 36.3699 101.98 36.4001 102.24 36.6215C102.569 36.9033 102.599 37.3964 102.599 37.8392C102.599 38.3323 102.609 38.8254 102.609 39.3085C102.609 39.5802 102.619 39.862 102.749 40.1035C103.059 39.3689 103.368 38.6342 103.668 37.8895C103.768 37.648 103.868 37.4165 103.977 37.175C104.357 36.3397 104.806 35.5446 105.336 34.81C105.475 34.6188 105.595 34.4074 105.795 34.3471Z" />
      <path d="M108.721 35.6453C108.95 35.5547 109.24 35.5648 109.49 35.5648C110.089 35.5748 110.678 35.7157 111.217 35.9673C111.657 36.1686 112.086 36.4504 112.336 36.8731C112.615 37.336 112.625 37.9398 112.426 38.443C112.226 38.9462 111.826 39.3588 111.357 39.6305C110.428 40.1639 109.14 40.1035 108.371 39.3588C108.281 39.2783 108.152 39.1777 108.052 39.2582C108.022 39.2783 108.012 39.3186 108.002 39.3487C107.702 40.1539 107.722 41.0697 108.042 41.8748C108.112 42.0559 108.222 42.2471 108.391 42.3176C108.521 42.3779 108.681 42.3679 108.831 42.3377C109.869 42.1767 110.808 41.6332 111.637 40.9791C111.876 40.7879 112.106 40.5967 112.376 40.4558C112.645 40.3149 112.965 40.2545 113.254 40.335C113.544 40.4155 113.794 40.6872 113.784 40.9891C113.784 41.281 113.574 41.5125 113.364 41.7137C112.545 42.5088 111.577 43.1428 110.528 43.5655C109.999 43.7768 109.45 43.9378 108.881 43.958C108.311 43.9781 107.732 43.8473 107.263 43.5151C106.724 43.1327 106.384 42.5088 106.244 41.8647C106.105 41.2206 106.134 40.5463 106.224 39.8821C106.344 39.0166 106.554 38.1512 106.963 37.3762C107.223 36.8932 107.552 36.4604 107.972 36.1082C108.182 35.9271 108.401 35.7761 108.641 35.6553C108.671 35.6654 108.691 35.6553 108.721 35.6453ZM109.03 37.9298C109.16 38.1713 109.41 38.3222 109.679 38.3726C109.949 38.4229 110.249 38.3524 110.498 38.2216C110.618 38.1612 110.728 38.0807 110.808 37.9599C110.888 37.8392 110.908 37.6983 110.858 37.5775C110.818 37.4769 110.728 37.4165 110.638 37.3561C110.239 37.1146 109.32 36.9133 109.02 37.4165C108.931 37.5574 108.951 37.7486 109.03 37.9096C109.02 37.8996 109.02 37.9096 109.03 37.9298Z" />
      <path d="M116.52 39.4796C116.21 40.2947 116.031 41.1602 115.851 42.0157C115.771 42.388 115.681 42.7906 115.391 43.0422C115.202 43.2032 114.942 43.2736 114.702 43.2132C114.463 43.1529 114.253 42.9818 114.173 42.7402C114.113 42.5591 114.123 42.3679 114.143 42.1767C114.313 40.2344 114.832 38.3323 115.002 36.39C115.022 36.1988 115.032 35.9975 115.102 35.8264C115.272 35.4038 115.871 35.283 116.27 35.4239C116.51 35.5145 116.69 35.8164 116.56 36.0378C116.929 35.9069 117.209 35.605 117.538 35.3836C117.798 35.2025 118.108 35.0716 118.427 35.0616C118.747 35.0515 119.076 35.1723 119.276 35.4239C119.576 35.8164 119.496 36.3799 119.715 36.8227C119.825 37.0441 120.015 37.2354 120.045 37.487C120.095 37.8291 119.795 38.1612 119.456 38.2216C119.116 38.282 118.767 38.1109 118.537 37.8492C118.287 37.5775 118.158 37.2253 117.948 36.9133C117.728 37.4165 117.389 37.8392 117.109 38.3021C116.849 38.6845 116.67 39.077 116.52 39.4796Z" />
      <path d="M126.276 35.9069C126.186 35.8365 126.126 35.7359 126.086 35.6252C126.066 35.5547 126.046 35.4641 125.977 35.4239C125.937 35.3937 125.877 35.3937 125.827 35.3937C125.407 35.4037 125.008 35.5849 124.628 35.7862C124.069 36.0881 123.53 36.4403 123.041 36.8429C122.891 36.9636 122.731 37.1347 122.791 37.3159C122.841 37.4568 123.001 37.5171 123.151 37.5675C123.89 37.7989 124.638 38.0606 125.228 38.5638C125.817 39.067 126.226 39.862 126.066 40.6268C125.927 41.3112 125.367 41.8244 124.778 42.2069C123.899 42.7704 122.901 43.1327 121.882 43.334C121.463 43.4145 121.014 43.4749 120.634 43.2837C120.255 43.0925 119.995 42.5893 120.215 42.227C120.424 41.8848 120.894 41.8546 121.293 41.8144C122.322 41.7238 123.32 41.3212 124.139 40.6772C124.269 40.5765 124.419 40.4356 124.419 40.2645C124.419 40.1438 124.349 40.023 124.269 39.9324C123.959 39.5601 123.51 39.3387 123.051 39.1676C122.601 38.9965 122.132 38.8556 121.723 38.594C121.313 38.3323 120.974 37.9096 120.944 37.4266C120.914 36.9032 121.253 36.4303 121.603 36.0478C122.771 34.7999 124.968 33.3507 126.745 33.9344C126.975 34.0149 127.215 34.1256 127.375 34.3068C127.644 34.6087 127.684 35.0515 127.694 35.4541C127.694 35.6453 127.684 35.8566 127.544 35.9874C127.455 36.068 127.325 36.0981 127.205 36.1183C126.895 36.1787 126.516 36.1183 126.276 35.9069Z" />
      <path d="M147.087 33.6124C147.237 33.713 147.367 33.8741 147.426 34.0451C147.576 34.4678 147.476 34.9308 147.377 35.3635C146.977 37.0341 146.578 38.7047 146.178 40.3853C145.799 41.9754 145.419 43.5755 144.72 45.0549C144.531 45.4675 144.301 45.8801 143.951 46.1619C143.602 46.4437 143.083 46.5645 142.683 46.3531C142.354 46.1821 142.174 45.8298 142.044 45.4776C141.824 44.8738 141.714 44.2397 141.615 43.6158C141.605 43.5353 141.585 43.4548 141.615 43.3743C141.645 43.2938 141.704 43.2434 141.774 43.1931C142.324 42.8107 143.212 43.1428 143.382 43.7969C143.582 43.7265 143.662 43.495 143.712 43.2837C143.981 42.1968 144.251 41.0998 144.531 40.013C144.001 40.4356 143.612 41.0294 143.093 41.4722C142.573 41.915 141.784 42.1666 141.215 41.7942C140.696 41.4621 140.566 40.7577 140.566 40.1438C140.556 38.8657 140.886 37.6178 141.135 36.3598C141.265 35.6855 141.405 34.9207 141.884 34.3974C142.374 33.864 143.332 34.1558 143.362 34.9308C143.372 35.2327 143.232 35.5145 143.113 35.7862C142.533 37.0945 142.374 38.5437 142.214 39.9727C143.802 38.282 145.09 36.2994 145.978 34.1558C146.068 33.9344 146.178 33.6829 146.388 33.5621C146.598 33.4011 146.877 33.4514 147.087 33.6124Z" />
    </g>
  </BaseIcon>
);
