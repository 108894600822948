import styles from './color.tokens.module.scss';

/**
 * @description
 * This file is used to export SCSS colors variables as a JS object.
 * keys are the name of the token and value is the css variable.
 * Example:
 * bg-page: "var(--c-bg-page)"
 *
 * Check the [color.tokens.module.scss](color.tokens.module.scss)
 * file and $color-tokens map to see the list of available tokens.
 */
export const colorTokens = styles;
