import {
  AssignmentFlow,
  ConfigInheritanceSource,
  CourseStatus,
  CourseTopicStatus,
  LanguageName,
  LearningFlow,
  LearnVideoVersionStatus,
  SupportProviderName,
  TestTaskConfigWithInheritance,
  TestTaskVersion,
} from '@/controllers/graphql/generated';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import { ROUTES } from '@/controllers/router/router.contants';
import { EditableGrantedPermission } from '@/components/platform/Permissions/permissions.typedefs';
import { VideoItemType } from '@/controllers/videos/videos.hooks/useVideosList';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { CreatableSelectOption } from '@/components/ui/FormElements/FormInputs/CreatableSelect';
import { TagColorMode } from '@/components/ui/platform/PlatformTag/typedefs';

interface LeftLabel {
  title: string;
  status?: LearnVideoVersionStatus;
}

export interface ListItem {
  id: number;
  slug?: string;
  title?: string;
  rightLabels?: string[];
  order?: number;
  icon?: JSX.Element;
  isAccent?: boolean;
  labels?: {
    id: number;
    icon?: FCIcon;
    color?: TagColorMode;
    values: string[];
  }[];
  isNotDeletable?: boolean;
}

export interface VideoListItem extends ListItem {
  type: VideoItemType;
  leftLabels?: LeftLabel[];
  courseTopicVideoId?: number;
}

export interface CourseLanguageFormField {
  label: string;
  value: number;
}

export interface PreparationModuleFormField {
  label: string;
  value: number;
}

export interface SupportProviderFormField {
  label: SupportProviderName;
  value: SupportProviderName;
}

export interface VacanciesTrackFormField {
  label: string;
  value: number;
}

export interface DomainFormField {
  label: string;
  value: number;
}

export type CourseAssignmentTestConfigRule = {
  complexity: number;
  count: number;
};

export interface AssignmentFlowField {
  label: AssignmentFlow;
  value: AssignmentFlow;
}
export interface CourseFormFields {
  name: string;
  slug: string;
  supportLink?: string;
  isActive?: SelectOptionInterface<boolean>;
  courseLanguage?: CourseLanguageFormField;
  preparationModule?: PreparationModuleFormField;
  supportProvider?: SupportProviderFormField;
  domains?: DomainFormField[];
  grantedPermissions?: EditableGrantedPermission[];
  assignmentTestConfigFirstTry?: CourseAssignmentTestConfigRule[];
  assignmentTestConfigRetry?: CourseAssignmentTestConfigRule[];
  id?: number;
  tag?: CreatableSelectOption<string>;
  vacanciesTracks?: VacanciesTrackFormField[];
  assignmentFlow: AssignmentFlowField;
  learningFlow: SelectOptionInterface<LearningFlow>;
}

export interface FilterMorningTasksFormFields {
  filter: string;
}

export interface SelectTopicFormFields {
  topic: SelectOptionInterface<number>;
}

export interface LmsContentText {
  [LanguageName.Uk]: string;
  [LanguageName.Ru]: string;
  [LanguageName.En]: string;
  [LanguageName.Pl]: string;
  [LanguageName.Pt]: string;
}

export interface TaskDescriptionFile {
  content: string;
  updatedAt: Date | null;
}

export interface TaskDescription {
  [LanguageName.Uk]: TaskDescriptionFile;
  [LanguageName.Ru]: TaskDescriptionFile;
  [LanguageName.En]: TaskDescriptionFile;
  [LanguageName.Pl]: TaskDescriptionFile;
  [LanguageName.Pt]: TaskDescriptionFile;
}

export interface TaskDescriptionFromLS {
  [LanguageName.Uk]: TaskDescriptionFile;
  [LanguageName.Ru]: TaskDescriptionFile;
  [LanguageName.En]: TaskDescriptionFile;
  [LanguageName.Pl]: TaskDescriptionFile;
  [LanguageName.Pt]?: TaskDescriptionFile;
}

export interface LmsContentValidity {
  [LanguageName.Uk]: boolean;
  [LanguageName.Ru]: boolean;
  [LanguageName.En]: boolean;
  [LanguageName.Pl]: boolean;
  [LanguageName.Pt]: boolean;
}

export enum FullLanguageName {
  uk = 'Ukrainian',
  ru = 'Russian',
  en = 'English',
  pl = 'Polish',
  pt = 'Portuguese',
}

export type TopicContent = Record<FullLanguageName, string>;

export type TopicFilesContent = Record<string, TopicContent>;

export type TopicContentUpdatedAt = Record<
  FullLanguageName,
  number | null | undefined
>;

export type TopicFilesContentUpdatedAt = Record<
  string,
  TopicContentUpdatedAt
>;

export const supportProviderNames = [
  SupportProviderName.Slack,
  SupportProviderName.Telegram,
  SupportProviderName.Whatsapp,
  SupportProviderName.Facebook,
  SupportProviderName.PlatformChat,
];

export const selectOptionsSupportProviders = [
  ...supportProviderNames.map((provider) => ({
    label: provider,
    value: provider,
  })),
];

export enum ConfigRowInputType {
  Select = 'select',
  Number = 'number',
}

interface TaskConfigRowBaseInterface<T> {
  title: string;
  configName: string;
  defaultConfigValue: T;
  source: ConfigInheritanceSource;
  isReadonly?: boolean;
}

export interface TaskConfigSelectRowInterface<
  T = string | number | boolean
> extends TaskConfigRowBaseInterface<T> {
  options: SelectOptionInterface<T>[];
  inputType: ConfigRowInputType.Select;
}

export interface TaskConfigNumberRowInterface<
  T = string
> extends TaskConfigRowBaseInterface<T> {
  inputType: ConfigRowInputType.Number;
}

export type TaskConfigRowInterface = TaskConfigSelectRowInterface
  | TaskConfigNumberRowInterface;

export enum TestTaskConfigKeys {
  isSampleTestsVisible = 'isSampleTestsVisible',
  isLinterEnabled = 'isLinterEnabled',
  isLinterBlocker = 'isLinterBlocker',
  hasTests = 'hasTests',
  isOptional = 'isOptional',
  isAutocompleteEnabled = 'isAutocompleteEnabled',
  isOpenExam = 'isOpenExam',
  hasChecklist = 'hasChecklist',
  isOpen = 'isOpen',
  notRequiresReview = 'notRequiresReview',
  isRefSolutionVisible = 'isRefSolutionVisible',
  isFileTreeVisible = 'isFileTreeVisible',
  tests = 'tests',
  async = 'async',
  testType = 'testType',
  maxReviewIterationsCount = 'maxReviewIterationsCount',
  onboardings = 'onboardings',
  onboardingName = 'onboardingName',
  onboardingOrder = 'onboardingOrder',
  isAIReviewEnabled = 'isAIReviewEnabled',
  isMentorReviewOptional = 'isMentorReviewOptional',
  peerReviewAvailable = 'peerReviewAvailable',
}

export const testTaskConfigKeys = [
  TestTaskConfigKeys.isFileTreeVisible,
  TestTaskConfigKeys.isLinterEnabled,
  TestTaskConfigKeys.isLinterBlocker,
  TestTaskConfigKeys.isRefSolutionVisible,
  TestTaskConfigKeys.isSampleTestsVisible,
  TestTaskConfigKeys.isAIReviewEnabled,
  TestTaskConfigKeys.isMentorReviewOptional,
  TestTaskConfigKeys.notRequiresReview,
  TestTaskConfigKeys.tests,
  TestTaskConfigKeys.async,
  TestTaskConfigKeys.testType,
  TestTaskConfigKeys.isAutocompleteEnabled,
  TestTaskConfigKeys.onboardings,
  TestTaskConfigKeys.onboardingName,
  TestTaskConfigKeys.onboardingOrder,
  TestTaskConfigKeys.peerReviewAvailable,
];

export const courseTopicConfigKeys = [
  ...testTaskConfigKeys,
  TestTaskConfigKeys.isOpenExam,
  TestTaskConfigKeys.maxReviewIterationsCount,
];

export const courseModuleConfigKeys = [
  ...testTaskConfigKeys,
  TestTaskConfigKeys.maxReviewIterationsCount,
];

export type ConfigFromServer = Omit<
  TestTaskConfigWithInheritance,
  '__typename' | 'shouldShowReportProblem'
>;

export type FormDataType = {
  [value in TestTaskConfigKeys]?: SelectOptionInterface;
};

export type ConfigToSubmit = {
  [key in keyof ConfigFromServer]?: any;
};

export enum EditTaskModules {
  FormFields = 'form-fields',
  Description = 'description',
  Files = 'files',
}

export enum EditQuizModules {
  FormFields = 'form-fields',
  Questions = 'questions',
}

export type InitialConfigFormValues = {
  isSampleTestsVisible?: SelectOptionInterface<boolean>;
  isLinterEnabled?: SelectOptionInterface<boolean>;
  isLinterBlocker?: SelectOptionInterface<boolean>;
  hasTests?: SelectOptionInterface<boolean>;
  isOptional?: SelectOptionInterface<boolean>;
  isAutocompleteEnabled?: SelectOptionInterface<boolean>;
  isOpenExam?: SelectOptionInterface<boolean>;
  hasChecklist?: SelectOptionInterface<boolean>;
  isOpen?: SelectOptionInterface<boolean>;
  notRequiresReview?: SelectOptionInterface<boolean>;
  isRefSolutionVisible?: SelectOptionInterface<boolean>;
  isFileTreeVisible?: SelectOptionInterface<boolean>;
  tests?: SelectOptionInterface<boolean>;
  async?: SelectOptionInterface<boolean>;
  testType?: SelectOptionInterface<string>;
  maxReviewIterationsCount?: string;
  onboardings?: SelectOptionInterface<boolean>;
  onboardingName?: SelectOptionInterface<string>;
  onboardingOrder?: SelectOptionInterface<number>;
  isAIReviewEnabled?: SelectOptionInterface<boolean>;
  isMentorReviewOptional?: SelectOptionInterface<boolean>;
  peerReviewAvailable?: SelectOptionInterface<boolean>;
};

export enum LmsEditorItemType {
  Video = 'videos',
  Task = 'tasks',
  Course = 'courses',
  Module = 'modules',
  Topic = 'topics',
  Item = 'items',
  LearnItem = 'learnItems',
}

export enum LmsEditorLists {
  Videos,
  Tasks,
  Courses,
  Modules,
  Topics,
  Topic,
  Versions,
}

export enum TaskComplexity { // TODO: Add to GraphQL Enum
  Easy = '1',
  Normal = '2',
  Hard = '3',
}

export type StatusOption = {
  label: CourseStatus;
  value: boolean;
};

export type TopicStatusOption = SelectOptionInterface<CourseTopicStatus>;

export interface StatusFormFields {
  status: StatusOption;
}

export interface TopicStatusFormFields {
  status: TopicStatusOption;
}

export const selectOptionsStatus = [
  {
    label: CourseStatus.Active,
    value: true,
  },
  {
    label: CourseStatus.Inactive,
    value: false,
  },
];

export const selectTopicOptionsStatus = [
  {
    label: CourseTopicStatus.InProgress,
    value: CourseTopicStatus.InProgress,
  },
  {
    label: CourseTopicStatus.OnReview,
    value: CourseTopicStatus.OnReview,
  },
  {
    label: CourseTopicStatus.Released,
    value: CourseTopicStatus.Released,
  },
];

export interface CreateTaskValues {
  testTaskId: number;
  complexity: number;
  professionSlug: string;
  courseTopicId?: number;
  courseModuleId?: number;
  isOptional: boolean;
}

export enum CodeEditorLanguage {
  JavaScript = 'javascript',
  TypeScript = 'typescript',
  Python = 'python',
  Java = 'java',
  Sql = 'sql',
  Markup = 'markup',
}

export enum LMSEditorTestTaskType {
  Morning = 'MorningTask',
  Assignment = 'AssignmentTask',
  LiveCoding = 'LiveCodingTask',
  CourseTopic = 'CourseTopicTask',
  DailyTask = 'DailyTask',
  Preview = 'PreviewTask',
}

export type TestTaskQuizVersion = Pick<TestTaskVersion, 'id' | 'versionNumber'>;

export enum LmsEditorAnalyticsTaskValues {
  Description = 'description',
  BaseInfo = 'base-info',
  TaskFiles = 'task-files',
}

export enum LmsEditorAnalyticsQuizTaskValues {
  BaseInfo = 'base-info',
  Questions = 'questions',
}

export enum LMSEditorFormTabs {
  General = 1,
  Settings = 2
}

export enum TestTaskCreationStatus {
  Draft = 'DRAFT',
  Created = 'CREATED',
}

export type LmsEditorLanguages = Extract<
  LanguageName,
  LanguageName.En
  | LanguageName.Uk
  | LanguageName.Ru
  | LanguageName.Pl
  | LanguageName.Pt
>;
const taskTypeRoute = ROUTES.lmsEditor.courseTasksType;

export const TaskTypeRoutes: {
  [key: string]: string;
} = {
  MorningTask: taskTypeRoute.morningTasks,
  AssignmentTask: taskTypeRoute.assignmentTasks,
  LiveCodingTask: taskTypeRoute.liveCodingTasks,
  PreviewTask: taskTypeRoute.testTaskPreviews,
};

export interface SelectedConfigResult {
  config: ConfigToSubmit;
  differenceCount: number;
}

export enum LearnVideoSubmitMode {
  Save = 'Save',
  SaveAsDraft = 'SaveAsDraft',
  Publish = 'Publish'
}

export enum TaskErrorCodes {
  NotFound = 'test_task_not_found',
  ReadAccessDenied = 'access_to_read_test_tasks_denied',
  GithubInfoNotFound = 'test_task_github_info_not_found',
}

export const selectOptionsFlowNamesProviders = [
  ...Object.values(AssignmentFlow).map((flow) => ({
    label: flow,
    value: flow,
  })),
];
