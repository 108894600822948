import React, { FC, useMemo } from 'react';
import { DefaultSeo } from 'next-seo';
import { useRouter } from '@/middleware/i18n';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import getClientLink from '@/lib/helpers/getClientLink';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';

export const DefaultSeoTags: FC = () => {
  const { asPath } = useRouter();
  const { subDomain, language, isKnowelyProduct } = useSubDomainContext();
  const features = useFeatures();

  const product = isKnowelyProduct
    ? `Knowely`
    : `Mate academy`;

  const currentPathWithoutParams = useMemo(() => {
    const path = removeQueryParams(asPath);

    if (path === '/') {
      return '';
    }

    return path;
  }, [asPath]);

  const currentUrl = getClientLink(
    currentPathWithoutParams,
    { domain: subDomain, language },
  );

  const landingPreviewLink = isKnowelyProduct
    ? '/static/knowely-landing-link-preview.jpg'
    : '/static/landing-link-preview.jpg';

  const logoLink = isKnowelyProduct
    ? '/static/knowely-logo.svg'
    : '/static/logo.svg';

  return (
    <DefaultSeo
      canonical={currentUrl}
      titleTemplate={`%s | ${product}`}
      dangerouslySetAllPagesToNoIndex={features.isEnabled(
        Features.DefaultNoindex,
      )}
      openGraph={{
        type: 'website',
        locale: language,
        url: currentUrl,
        site_name: product,
        images: [
          {
            url: landingPreviewLink,
          },
          {
            url: logoLink,
          },
        ],
      }}
    />
  );
};
