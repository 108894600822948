export const capitalize = (string: string) => {
  const words = string
    .split(' ')
    .filter(Boolean)
    .map((word) => word.toLowerCase());

  const [
    firstWord,
    ...restWords
  ] = words;

  if (!firstWord) {
    return '';
  }

  const rest = restWords.length
    ? ` ${restWords.join(' ')}`
    : '';

  return `${firstWord[0]?.toUpperCase()}${firstWord.slice(1)}${rest}`;
};
