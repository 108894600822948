export const validateNavigator = (regex: RegExp) => (
  navigator
  && Boolean(navigator.userAgent)
  && regex.test(navigator.userAgent)
);

const isMobileRegex = /Mobile|Android|iP(hone|od|ad)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i;
const isTabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;

export const isMobile = () => (
  validateNavigator(isMobileRegex)
);

export const isTablet = () => (
  validateNavigator(isTabletRegex)
);

export const getMobilePlatform = () => (
  Boolean(navigator)
  && Boolean(navigator.userAgent)
  && /iP(hone|od|ad)/i.test(navigator.userAgent)
    ? 'ios'
    : 'android'
);

export const isMobileTouchDevice = () => {
  const isPotentiallyMobile = isMobile();
  const isPotentiallyTablet = isTablet();

  const isTouchDevice = navigator.maxTouchPoints > 0;

  return (isPotentiallyMobile || isPotentiallyTablet) && isTouchDevice;
};
