import { useLeaderboardUpdatesSubscription } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/hooks/useLeaderboardUpdatesSubscription';
import { useXpHistoryUpdatesSubscription } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/hooks/useXpHistoryUpdatesSubscription';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

export const useLeaderboardSubscriptions = () => {
  const [user] = useAuthUser({ ssr: false });

  const authUserId = user?.id || 0;

  useXpHistoryUpdatesSubscription({
    authUserId,
  });
  useLeaderboardUpdatesSubscription({
    authUserId,
  });
};
