import { makeVar } from '@apollo/client';
import { PopUpTypeMode } from '@/components/ui/Modal';
import { IntRange } from '@/lib/helpers/utility-types';

export interface ConfirmStateInterface {
  body?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string | null;
  onCancel: () => void;
  onConfirm: () => void;
  onConfirmUnsecure?: () => void;
  isOpen: boolean;
  secure?: boolean;
  renderChildren?: () => void;
  reverseButtons?: boolean;
  renderCloseButton?: boolean;
  type?: PopUpTypeMode;
  renderTitleIcon?: () => JSX.Element;
  orderLevel?: IntRange<1, 5>;
}

export const confirmModalVar = makeVar<ConfirmStateInterface>({
  isOpen: false,
  onCancel: () => { /* empty */ },
  onConfirm: () => { /* empty */ },
});
