import { MateLogoFull } from '@/components/common/MateLogo/MateLogoFull';
import { MateLogoSquare } from '@/components/common/MateLogo/MateLogoSquare';
import { MateLogoCircle } from '@/components/common/MateLogo/MateLogoCircle';
import { KnowelyLogoFull } from '@/components/common/KnowelyLogo/KnowelyLogoFull';
import { KnowelyLogoSquare } from '@/components/common/KnowelyLogo/KnowelyLogoSquare';
import { KnowelyLogoCircle } from '@/components/common/KnowelyLogo/KnowelyLogoCircle';

import { Products } from '@/constants/products';

export const LogoSquareMap = {
  [Products.Knowely]: KnowelyLogoSquare,
  [Products.Mate]: MateLogoSquare,
};

export const LogoFullMap = {
  [Products.Knowely]: KnowelyLogoFull,
  [Products.Mate]: MateLogoFull,
};

export const LogoCircleMap = {
  [Products.Knowely]: KnowelyLogoCircle,
  [Products.Mate]: MateLogoCircle,
};
