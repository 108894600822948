import { cypressProviders } from '@/providers/cypress';
import { productionProviders } from '@/providers/production';
import { isCypress } from '@/lib/helpers/isCypress';

const isCypressEnv = isCypress();

const providers = isCypressEnv
  ? cypressProviders
  : productionProviders;

export { providers };
