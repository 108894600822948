import { makeVar } from '@apollo/client';
import { FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';

interface FlashMessageReactiveState {
  visibleMessages: FlashMessage[];
}

export const flashMessageVar = makeVar<FlashMessageReactiveState>({
  visibleMessages: [],
});
