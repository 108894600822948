import {
  GooglePlaceType,
} from '@/components/ui/FormElements/FormInputs/GoogleLocationSelector/GoogleLocationSelector';

interface CreateCountryMockData {
  name: string;
  shortName: string;
  googleId: string;
}

interface CreateCityMockData {
  name: string;
  googleId: string;
  countryName: string;
}

const countryMock: CreateCountryMockData[] = [
  { name: 'Ukraine', shortName: 'UA', googleId: 'ChIJjw5wVMHZ0UAREED2iIQGAQA' },
  { name: 'Poland', shortName: 'PL', googleId: 'ChIJuwtkpGSZAEcR6lXMScpzdQk' },
];

const citiesMock: CreateCityMockData[] = [
  {
    name: 'Lviv',
    googleId: 'ChIJV5oQCXzdOkcR4ngjARfFI0I',
    countryName: 'Ukraine',
  },
  {
    name: 'Kyiv',
    googleId: 'ChIJBUVa4U7P1EAR_kYBF9IxSXY',
    countryName: 'Ukraine',
  },
  {
    name: 'Warsaw',
    googleId: 'ChIJAZ-GmmbMHkcR_NPqiCq-8HI',
    countryName: 'Poland',
  },
  {
    name: 'Kraków',
    googleId: 'ChIJ0RhONcBEFkcRv4pHdrW2a7Q',
    countryName: 'Poland',
  },
];

const createCountryMockData = ({
  name, googleId, shortName,
}: CreateCountryMockData) => ({
  label: name,
  value: googleId,
  placeName: name,
  placeId: googleId,
  placeType: GooglePlaceType.Country,
  type: GooglePlaceType.Country,
  placeShortName: shortName,
});

const createCityMockData = ({
  name, googleId, countryName,
}: CreateCityMockData) => ({
  label: name,
  cityName: name,
  placeName: name,
  countryName,
  value: googleId,
  placeId: googleId,
  googleCityId: googleId,
  regionName: '',
  placeType: GooglePlaceType.Locality,
  type: GooglePlaceType.Locality,
});

export const googleCountriesMockData = countryMock.map(createCountryMockData);

export const googleCitiesMockData = citiesMock.map(createCityMockData);

export const googlePlaceMockData = [
  ...googleCitiesMockData,
  ...googleCountriesMockData,
];
