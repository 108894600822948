import React, { forwardRef, useCallback } from 'react';
import { ButtonElementProps, ButtonORAnchorCommonAttributes } from '@/components/ui/Button/typedefs';

type Props = ButtonORAnchorCommonAttributes & ButtonElementProps & {
  className: string;
  isLoading?: boolean;
  dataQa?: string;
};

type RefType = HTMLButtonElement;

export const ButtonElement = forwardRef<RefType, Props>((props, ref) => {
  const {
    children,
    type,
    disabled,
    isLoading,
    className,
    onClick,
    onClickNoArgs,
    dataQa,
    ...rest
  } = props;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (onClickNoArgs) {
        onClickNoArgs();
      }
    },
    [onClick, onClickNoArgs],
  );

  const shouldPasOnClick = Boolean(onClick || onClickNoArgs);

  return (
    <button
      data-qa={dataQa}
      ref={ref}
      type={type}
      disabled={disabled || isLoading}
      className={className}
      onClick={
        shouldPasOnClick
          ? handleClick
          : undefined
      }
      {...rest}
    >
      {children}
    </button>
  );
});
