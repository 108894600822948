import { useCallback } from 'react';
import { throttleAndDebounce } from '@/hooks/throttleAndDebounce';
import { AnyFunction } from '@/typedefs/common';

export function useThrottledAndDebouncedCallback<T extends AnyFunction>(
  func: T,
  delay: number,
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledAndDebounced = useCallback(
    throttleAndDebounce(func, delay),
    [func, delay],
  );

  return throttledAndDebounced as T;
}
