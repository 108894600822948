import React, { FC, memo } from 'react';
import { IconLogoCircle } from '@/components/ui/icons/IconLogoCircle';

type Props = {
  className?: string;
};

export const MateLogoCircle: FC<Props> = memo(({
  className,
}) => <IconLogoCircle className={className} />);
