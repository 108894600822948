import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  useOpenQuestionAnswerRatedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/openQuestionAnswerRatedWS.subscription.generated';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';

interface Params {
  skip?: boolean;
}

export const useOpenQuestionAnswerRatedWS = ({ skip }: Params = {}) => {
  useOpenQuestionAnswerRatedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const receivedData = data?.openQuestionAnswerRatedWS;

      if (!receivedData) {
        return;
      }

      const {
        messageIds, isCorrect,
      } = receivedData;

      messageIds.forEach((messageId: number) => {
        cache.modify({
          id: `${MessageType.OpenQuestionAnswer}:${messageId}`,
          fields: {
            isCorrect: () => isCorrect,
          },
        });
      });
    },
  });
};
