import { SidebarSchema } from '@/controllers/apollo/apollo.modules/sidebar/sidebar.schema';
import { VacancyEditorSchema } from '@/controllers/apollo/apollo.modules/vacancyEditor/vacancyEditor.schema';
import { EventsSidebarSchema } from '@/controllers/apollo/apollo.modules/eventsSidebar/eventsSidebar.schema';
import { UserVacancySidebarSchema } from '@/controllers/apollo/apollo.modules/userVacancySidebar/userVacancySidebar.schema';

export const schemas = [
  SidebarSchema,
  VacancyEditorSchema,
  EventsSidebarSchema,
  UserVacancySidebarSchema,
];
